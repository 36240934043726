import { useCallback, useEffect, useRef } from 'react';

import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import * as utils from './useTimeout';

export const useTimeout = () => {
  const timerRef = useRef();

  // On unmount, clear the timeout - prevents bugs in bfcache, memory leaks, etc.
  useEffect( () => {
    return () => global.clearTimeout( timerRef.current );
  }, [] );

  const exec = useCallback( utils.composeExecuteTimeout( { timerRef } ), [] );

  return [exec];
};

export const composeExecuteTimeout = ( data ) => ( callback, timeout ) => {
  const { timerRef = {} } = handleEmptyObjects( data );

  if( typeof timeout !== 'number' || typeof callback !== 'function' ){
    // eslint-disable-next-line no-console
    console.error( 'Invalid arguments passed to useTimeout, timeout should be a number and callback should be a function' );
  }

  timerRef.current = global.setTimeout( () => {
    global.clearTimeout( timerRef.current );
    callback();
  }, timeout );

};