/**
 * A modal window is a graphical control element subordinate to an application&#x27;s main window. It creates a mode that disables the main window but keeps it visible, with the dialog window as a child window in front of it
 *
 * @module views/Layouts/BottomSheet
 */
import './BottomSheet.scss';

import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { FocusScope } from '@react-aria/focus';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Text as PalText} from 'web-palette/dist/components/Text/Text';

import Button from '@ulta/core/components/Button/Button';
import * as overlayUtils from '@ulta/core/components/Overlay/Overlay';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import constants from '@ulta/core/utils/constants/constants';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { isFunction } from '@ulta/core/utils/types/types';

import * as utils from './BottomSheet';
/**
* Represents a BottomSheet component
* @type {React.FC<BottomSheetProps>}
*/
const BottomSheet = forwardRef( ( props, ref ) => {
  const {
    ariaLabel,
    children,
    closeAccessibilityLabel,
    customClassName,
    disableClose,
    subtitle,
    title
  } = props;

  const { closeOverlay } = useOverlay();
  const buttonRef = useRef();
  const [isOpen, overlayBackdrop, isClosing] = utils.useCustomOverlay( props, ref );
  const showCloseButton = !disableClose;

  // Auto Focus the cross button for Keyboard users.
  useEffect( ()=>{
    if( buttonRef.current ){
      buttonRef.current?.focus();
    }
  }, [] );

  if( isServer() ){
    return null;
  }

  const component = (
    <>
      <div className={ classNames( 'BottomSheet', {
        'BottomSheet--isClosing': isClosing
      } ) }
      ref={ ref }
      >
        <div
          className={ classNames( 'BottomSheet__wrapper', {
            [ `${customClassName}` ]: customClassName
          } ) }
          role='dialog'
          aria-modal={ true }
          { ...( { 'aria-label': ariaLabel } ) }
          { ...( isOpen && { 'data-open': true } ) }
        >
          <FocusScope contain={ true }>
            <div className='BottomSheet__header'>
              { showCloseButton && (
                <Button className='BottomSheet__closeButton'
                  tertiary
                  tiny
                  ariaLabel={ closeAccessibilityLabel }
                  onClick={ () => closeOverlay( { userCancelled: true } ) }
                  ref={ buttonRef }
                  iconImage={ 'X' }
                  iconSize={ 'lg' }
                  ariaHiddenIcon={ true }
                />
              ) }
              { title?.text && (
                <div className='BottomSheet__titleContainer'>
                  <PalText
                    htmlTag={ title.htmlTag }
                    textStyle={ title.textStyle }
                  >
                    { title.text }
                  </PalText>
                  { subtitle?.text && (
                    <PalText
                      htmlTag={ subtitle.htmlTag }
                      textColor={ subtitle.textColor }
                      textStyle={ subtitle.textStyle }
                    >
                      { subtitle.text }
                    </PalText>
                  ) }
                </div>
              ) }
            </div>
            { children && (
              <div className='BottomSheet__content'>
                { children }
              </div>
            ) }
          </FocusScope>
        </div>
      </div>
      { /* Manages Modal backdrop */ }
      { isOpen &&
        <div className={ classNames( 'BottomSheet__backdrop', {
          ['BottomSheet__backdrop--closing']: isClosing
        }, {
          ...( customClassName && { [ `BottomSheet--${customClassName}` ]: customClassName } )
        } ) }
        ref={ overlayBackdrop }
        />
      }
    </>
  );

  return createPortal( component, document.body );
} );

/**
 * Manages state for a custom Overlay and polyfills the div to mimic an HTML dialog
 * @param {object} props - Component props
 * @param {object} ref - Flyout ref
 * @returns {array} Returns array of [isOpen, overlayBackdrop]
 */
export const useCustomOverlay = ( props, ref = {} ) => {
  const { disableClose, onClose } = props;

  const [isOpen, setIsOpen] = useState( false );
  const [isClosing, setIsClosing] = useState( false );
  const overlayBackdrop = useRef();

  useEffect( () => {
    // Allow time for DOM to update so the initial element can render off screen, then
    // when we flip this to true it will animate in
    let timeOut = setTimeout( () => {
      setIsOpen( true );
    }, 0 );

    return () => clearTimeout( timeOut );
  }, [] );

  const onOverlayClose = useCallback( ( e, data ) => {
    // if disableClose is true do not close.
    if( disableClose ){
      return;
    }
    setIsClosing( true );
    const timeOut = setTimeout( () => {
      onClose( e, data );
      setIsOpen( false );
    }, 0 );

    return () => clearTimeout( timeOut );
  }, [onClose, setIsOpen, disableClose] );

  useEffect( () => {
    if( !ref?.current ){
      return;
    }

    // Polyfill the .close function on the element
    // we only set this once because if overlay content gets replaced we will have lost the og reference
    if( !isFunction( ref?.current?.close ) ){
      // eslint-disable-next-line no-param-reassign
      ref.current.close = onOverlayClose;
    }
  }, [onOverlayClose, disableClose] );

  // Handle ESC key
  UseKeyPress( constants.ESC_KEY, { current: document }, ( e ) => {
    if( disableClose ){
      return;
    }
    return onOverlayClose( e, { userCancelled: true } );
  } );

  // Handle backdrop click
  const closeOnBackDropClick = useCallback(
    overlayUtils.composeCloseOnBackDropClick( { isOpen, overlayBackdrop }, { onOverlayClose } ),
    [isOpen, overlayBackdrop, onOverlayClose]
  );

  // Handle backdrop click
  useEffect( ( )=> {
    if( !isOpen ){
      return;
    }
    document.addEventListener( 'click', closeOnBackDropClick );
    return () => {
      document.removeEventListener( 'click', closeOnBackDropClick );
    };
  }, [isOpen, closeOnBackDropClick] );

  return [isOpen, overlayBackdrop, isClosing];
};

/**
* Default values for passed properties
* @type {object}
* @property {string} closeAccessibilityLabel - Sets close accessibility label
*/

export const defaultProps = {
  closeAccessibilityLabel: 'Close'
};

/**
  * Property type definitions
  * @typedef BottomSheetProps
  * @type {object}
  * @property {string} closeAccessibilityLabel - Sets close accessibility label
  * @property {number} offset - Sets a top offset
  * @property {function} onClose - Sets the onClose event on the dialog element
  * @property {function} subtitle - Sets the subtitle for bottom sheet
  * @property {function} title - Sets the title and htmlTag for bottom sheet title
  */

/**
* @type {BottomSheetProps}
*/
export const propTypes = {
  closeAccessibilityLabel: PropTypes.string,
  offset: PropTypes.number,
  onClose: PropTypes.func,
  subtitle: PropTypes.object,
  title: PropTypes.object
};

BottomSheet.propTypes = propTypes;
BottomSheet.defaultProps = defaultProps;
BottomSheet.displayName = 'BottomSheet';

export default BottomSheet;
