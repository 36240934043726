/**
 * The UserContextProvider component is used to provide the user details to any component which needs it.
 *
 * @module views/__core/UserContextProvider/UserContextProvider
 */
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import deepmerge from 'deepmerge';

import { useRetailerVisitorId } from '@ulta/core/hooks/useRetailerVisitorId/useRetailerVisitorId';
import { useSessionAction } from '@ulta/core/hooks/useSessionAction/useSessionAction';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useLayerHostContext } from '@ulta/core/providers/LayerHostProvider/LayerHostProvider';
import { PROTECTED_PAGE_FLOW, usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import * as utils from '@ulta/core/providers/UserContextProvider/UserContextProvider' ;
import { hasItems } from '@ulta/core/utils/array/array';
import { constants } from '@ulta/core/utils/constants/constants';
import { devLogger, LOG_TOPIC } from '@ulta/core/utils/devMode/devMode';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleReload } from '@ulta/core/utils/handleLocation/handleLocation';
import { getStorage, removeStorage, setStorage } from '@ulta/core/utils/storage/storage';
import { isFunction } from '@ulta/core/utils/types/types';

import { STORAGE_KEY } from '../../utils/storageKeys/storageKeys';

/**
 * Represents a UserContextProvider component
 *
 * The UserContextProvider is responsible for maintaining a user object based on
 * multiple data sources.
 *
 * User object acquisition flow:
 * 1. ATG profile, comes from an API request for session status
 * 2. retailerVisitorId is a cookie that is set by a third party
 * 3. Combine initial results
 * 4. Setup listener for any updates
 *    4a. Merge any incoming session data w/ current DXL user
 * 5. Incoming session action handler
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns UserContextProvider
 */
export const UserContextProvider = function( { children } ){
  const { refetchRef, protectedPageFlow, data:pageData } = usePageDataContext();
  const { adoptSessionExternally } = useAppConfigContext();
  const { incrementPageRefreshes, sessionAttempts } = useLayerHostContext();

  // Not currently used
  const isProcessingAuthAction = useRef( false );

  // DXL sends a sessionAction on any Page model and we only want to process it
  // on page load and when we need to re-authenticate during a protected page refetch
  const isInitialSessionPing = useRef( true );

  // Sometimes we need to refetch the page after the user has been resolved
  const refetchPageAfterResolved = useRef( false );

  // We use this flag to block multiple session requests from being sent and track the session flow
  const sessionUpdateRequested = useRef( false );

  // If a sign out has been requested, we need to perform some cleanup actions
  const signOutRequested = useRef( false );

  // Houses any callbacks that need to be executed after a successful login
  const postLoginCallbacks = useRef( [] );

  // 1. Listen for DXL profile
  const [dxlUser, setDxlUser] = useState( { ...utils.getUserFromStorage(), resolved: false } );
  const [cart, setCart] = useState( { itemCount: Number( dxlUser?.cartItemCount ) } );

  // 2. Listen for retailer visitor id
  const [retailerVisitorId] = useRetailerVisitorId();

  // 3. Create initial user/cart objects
  const [user, setUser] = useState( {
    ...dxlUser,
    retailerVisitorId
  } );

  // 4. Listen to and process updates
  const userResolver = useCallback(
    utils.composeUserResolver(
      {
        adoptSessionExternally,
        dxlUser,
        isInitialSessionPing,
        protectedPageFlow,
        pageData,
        postLoginCallbacks,
        refetchRef,
        retailerVisitorId,
        sessionAttempts,
        user
      },
      { setUser, setCart, incrementPageRefreshes, shouldUpdateSession }
    ),
    [
      adoptSessionExternally,
      dxlUser,
      user,
      retailerVisitorId,
      setUser,
      setCart,
      incrementPageRefreshes
    ]
  );
  useEffect( userResolver, [userResolver] );

  // 4a. Merge any incoming session data w/ current DXL user
  const updateSession = useCallback( utils.composeUpdateSession( { setDxlUser, updateSessionDataInStorage } ), [setDxlUser] );

  // 5. Process session action
  const [processSessionAction] = useSessionAction(
    { user, refetchPageAfterResolved, sessionUpdateRequested, isInitialSessionPing, isProcessingAuthAction },
    { updateSession, setDxlUser, setUser }
  );

  // Session reset handler
  const resetSession = useCallback( utils.composeResetSession( { user }, { setUser } ), [
    user,
    setUser
  ] );

  // Post login callbacks
  const addPostLoginCallback = useCallback( utils.composeAddPostLoginCallback( { postLoginCallbacks } ), [
    postLoginCallbacks
  ] );

  const [store, setStore] = useState( () => utils.getPreferredStore() );

  useEffect( () => {
    utils.setPreferredStore( store );
  }, [store] );

  utils.useExternalSession( { setDxlUser } );

  return (
    <UserContext.Provider
      value={ {
        addPostLoginCallback,
        cart,
        isInitialSessionPing,
        isProcessingAuthAction,
        processSessionAction,
        resetSession,
        sessionUpdateRequested,
        setCart,
        setStore,
        signOutRequested,
        store,
        updateSession,
        user
      } }
    >
      { children }
    </UserContext.Provider>
  );
};

/**
 * Handles consuming user from local storage for header/footer when they
 * run on 3rd party hosted pages (either internal or external vendors)
 *
 * There are issues trying to manage the state of the user when there are
 * multiple instances of the app running on the same page. This hook will make
 * it so an app that has been bootstrapped w/ `adoptSessionExternally = true`
 * will listen for changes to the user object in local storage and update the
 * user object in the app.
 *
 * We also disable calling sessionAction for an app's bootstrapped with
 * `adoptSessionExternally = true`
 *
 * @param {object} methods - Methods
 * @param {function} methods.setDxlUser - DXL user setter
 */
export const useExternalSession = ( methods ) => {
  const { adoptSessionExternally } = useAppConfigContext();
  const { setDxlUser } = methods || {};
  const prevUser = useRef( {} );

  useEffect( () => {
    if( !adoptSessionExternally || !setDxlUser ){
      return;
    }

    const id = setInterval( () => {
      const userCheck = utils.getUserFromStorage();
      if( utils.isUserSame( { prevUser: prevUser.current, updatedUser: userCheck } ) ){
        return;
      }

      devLogger( '[User] External session update', 0, LOG_TOPIC.Session );

      prevUser.current = userCheck;
      setDxlUser( userCheck );
    }, 1000 );

    return () => {
      clearInterval( id );
    };
  }, [] );
};

/**
 * Sets the preferred store in local storage.
 * @param {any} data - The data to be stored as the preferred store.
 */
export const setPreferredStore =  ( data ) => {
  if( !data ){
    return null;
  }
  setStorage( {
    secure: false,
    key: STORAGE_KEY.storePersist,
    value: data
  } );
};

/**
 * Retrieves the preferred store from local storage.
 * @returns  The data stored as the preferred store, or `null` if not found.
 */
export const getPreferredStore = () => {
  return getStorage( { secure: false, key: STORAGE_KEY.storePersist } );
};

/**
 * @callback addPostLoginCallback
 * @param {function} callback - Callback to execute after login
 * @returns {void}
 */

/**
 * Adds a callback to the postLoginCallbacks array
 *
 * @param {object} data - Arguments
 * @param {object} data.postLoginCallbacks - Array ref of callbacks to execute after login
 * @returns {addPostLoginCallback}
 */
export const composeAddPostLoginCallback = ( data ) => ( callback ) => {
  const { postLoginCallbacks = {} } = handleEmptyObjects( data );
  postLoginCallbacks.current = postLoginCallbacks.current || [];
  postLoginCallbacks.current.push( callback );
};

/**
 * @callback updateSession
 * @param {object} data - Incoming session data
 * @param {object} data.sessionData - Session data
 * @returns {void}
 */

/**
 * Merges incoming sessionData
 * @param {object} methods - Methods
 * @returns {updateSession} updateSession
 */
export const composeUpdateSession = ( methods ) => ( data ) => {
  const { setDxlUser, updateSessionDataInStorage } = methods || {};
  const { sessionData } = data || {};

  if( !sessionData ){
    return;
  }

  setDxlUser( ( current ) => ( { ...current, ...sessionData } ) );

  if( sessionData.stk ){
    updateSessionDataInStorage( sessionData );
  }
};

/**
 * Reconciles user object and updates storage
 * @param {object} data - Arguments
 * @param {object} data.dxlUser - DXL session data
 * @param {object} data.user - Current user object
 * @param {object} data.retailerVisitorId - retailerVisitorId
 * @param {object} methods - Methods
 * @param {function} methods.setUser - setUser setter
 * @returns {function} - Callback that will handle reconciling the user object
 */
export const composeUserResolver = ( data, methods ) => () => {
  const {
    adoptSessionExternally,
    dxlUser = {},
    isInitialSessionPing = {},
    protectedPageFlow = {},
    postLoginCallbacks = {},
    refetchRef = {},
    retailerVisitorId,
    sessionAttempts = {},
    user = {}
  } = handleEmptyObjects( data );

  const { incrementPageRefreshes, setUser, setCart, shouldUpdateSession } = methods || {};

  if( !setUser || !setCart || !shouldUpdateSession( user, dxlUser ) ){
    return;
  }

  const updatedUser = { ...dxlUser, retailerVisitorId };

  // Transform loginType/loginStatus
  // DXL's `loginStatus` is our `loginType`
  if( typeof dxlUser.loginStatus === 'string' ){
    updatedUser.loginType = dxlUser.loginStatus;
    updatedUser.loginStatus = updatedUser.loginType !== constants.LOGIN_STATUS.ANONYMOUS;
  }

  // Tracks user state changes
  const { shouldRefresh, shouldReload, isPostLogin } = utils.getShouldRefresh( {
    adoptSessionExternally,
    user,
    updatedUser,
    sessionAttempts,
    isInitialSessionPing,
    protectedPageFlow
  } );

  // Do nothing if the user has not changed
  if( utils.isUserSame( { prevUser: user, updatedUser } ) ){
    return;
  }

  const loginStatusDebug = `loginStatus: ${user.loginType} > ${updatedUser.loginType}`;
  const gtiDebug = `gti: ${user.gti?.slice( -5 )} > ${updatedUser.gti?.slice( -5 )}`;
  devLogger( {
    topic: LOG_TOPIC.Session,
    title: `[User Resolver] ${loginStatusDebug} | ${gtiDebug} | ${sessionAttempts.current}`,
    value: { user, updatedUser },
    collapsed: true
  } );

  // Determine if we need to refresh the page
  if( shouldRefresh ){
    refetchRef.current?.();
    incrementPageRefreshes();
  }

  // Update storage if the user has changed
  if( !adoptSessionExternally ){
    utils.updateUserInStorage( { updatedUser } );
  }

  // When on a protected page, hard reload on user state change
  if( shouldReload ){
    handleReload();
  }

  // Determine if we need to run post-login callbacks
  if( isPostLogin && hasItems( postLoginCallbacks.current ) ){
    postLoginCallbacks.current.forEach( ( callback ) => isFunction( callback ) && callback( { user: updatedUser } ) );
    postLoginCallbacks.current = [];
  }

  // Update user, cart
  setUser( updatedUser );
  populateGlobalPageData( { updatedUser, cartItemCount: dxlUser.cartItemCount } );
  setCart( { itemCount: Number( dxlUser.cartItemCount ) } );
};

/**
  * Object with flags to determine if we should refresh or reload the page
  * @typedef {object} UserComparisonTransitionState
  * @property {boolean} shouldRefresh - Flag to determine if we should bg refresh the page
  * @property {boolean} shouldReload - Flag to determine if we should hard reload the page
  * @property {boolean} isPostLogin - Flag to determine if we just logged in
  * @property {boolean} isPostLogout - Flag to determine if we just logged out
  * @property {boolean} loginTypeChanged - Flag to determine if the login type has changed (login to logout and logout to login)
  */

/**
 * Tracks transition states for session changes
 * @param {object} data - Arguments
 * @param {object} data.adoptSessionExternally - Flag to determine if we're adopting a session externally
 * @param {object} data.user - User object
 * @param {object} data.updatedUser - Updated user object
 * @param {object} data.sessionAttempts - Session attempts (number of times we've tried to establish a session)
 * @param {object} data.isInitialSessionPing - Flag to determine if this is the first session ping (first time we're establishing a session)
 * @param {object} data.protectedPageFlow - Protected page flow state (idle, resolving, resolved)
 * @returns {UserComparisonTransitionState} - Object with flags to determine if we should refresh or reload the page
 */
export const getShouldRefresh = ( data ) => {
  const {
    adoptSessionExternally,
    user = {},
    updatedUser = {},
    sessionAttempts = {},
    isInitialSessionPing = {},
    protectedPageFlow = {}
  } = handleEmptyObjects( data );

  const { ANONYMOUS, HARD_LOGIN } = constants.LOGIN_STATUS;

  // Set a flag if the user has changed and we're logging in
  const isPostLogin = user.loginType === ANONYMOUS && updatedUser.loginType === HARD_LOGIN;

  // Set a flag if the user loginType has changed
  const loginTypeChanged = !isInitialSessionPing.current && user.loginType !== updatedUser.loginType;

  // Tracks if we just logged out
  const isPostLogout = user.loginType === HARD_LOGIN && updatedUser.loginType === ANONYMOUS;

  // We only want gti-based side effects when anonymous and in some sort of user session recovery flow
  const gtiChanged = !user.loginStatus && user.gti !== updatedUser.gti && sessionAttempts.current > 0;

  // Hard reload on loginType change:
  // 1. when we're on a protected page and the user status has changed
  // 2. we're logging out on a 3rd party integration of hfn
  const shouldReload =
    ( loginTypeChanged && protectedPageFlow.current === PROTECTED_PAGE_FLOW.Resolved ) ||
    ( isPostLogout && adoptSessionExternally );

  // Only refresh on an idle page, when gti changed or we logout
  const shouldRefresh =
    !shouldReload &&
    protectedPageFlow.current === PROTECTED_PAGE_FLOW.Idle &&
    ( ( !isPostLogin && gtiChanged ) || isPostLogout );


  return { shouldRefresh, shouldReload, isPostLogin };
};

/**
 * Compares session timestamps
 * @param {object} storedUser - stored user object
 * @param {object} updatedUser - incoming user object
 * @returns {boolean} - True when updated user has a newer session timestamp
 */
export const shouldUpdateSession = ( storedUser, updatedUser ) => {
  const storedDate = new Date( storedUser.lastSessionRefreshTime ).valueOf();
  const newDate = new Date( updatedUser.lastSessionRefreshTime ).valueOf();
  if( !storedDate || !newDate ){
    return true;
  }
  return storedDate <= newDate;
};

/**
 * Global page data model - used to populate global page data which is accessed by data capture and vendors
 * @const {object}
 */
export const GLOBAL_PAGE_DATA_MODEL = {
  'order': {
    'itemCount': ''
  },
  'profile': {
    'email': '',
    'firstName': '',
    'lastName': '',
    'GTI': ''
  },
  'rewards': {
    'isCardHolder': false,
    'loyaltyId': '',
    'programId': '',
    'memberSince': '',
    'platinumMember': '',
    'platinumMemberType': '',
    'userType': '',
    'clubPoints': null
  },
  'cart': {
    'autoRemovedItems': ''
  },
  'errorPageData': {
    'error': ''
  },
  'info': {
    'saga': ''
  },
  'retailerVisitorId':''
};

/**
 * To update GlobalPageData
 * @param {object} updated User object
 */
export const populateGlobalPageData = ( data )=>{
  const { updatedUser = {}, cartItemCount = 0 } = handleEmptyObjects( data );
  const userDetails = deepmerge( GLOBAL_PAGE_DATA_MODEL, { ...global.globalPageData } );
  userDetails.order.itemCount = cartItemCount;
  userDetails.profile.email = updatedUser.email;
  userDetails.profile.firstName = updatedUser.firstName;
  userDetails.profile.lastName = updatedUser.lastName;
  userDetails.profile.GTI = updatedUser.gti;
  userDetails.rewards.loyaltyId = updatedUser.loyaltyId;
  userDetails.retailerVisitorId = updatedUser.retailerVisitorId;
  global.globalPageData = userDetails;
};
/**
 * Is user same as before
 * @param {object} data
 * @returns {boolean}
 */
export const isUserSame = ( data ) => {
  const { prevUser = {}, updatedUser = {} } = handleEmptyObjects( data );

  let isSame = true;
  const keys = Object.keys( updatedUser );

  for ( const key of keys ){
    // We need to ignore some keys/values:
    // - non strings, like basketskus which is an array
    // - session update timestamps, they have no relevance in determining the "sameness" of a user object
    // - session_* keys, these are duplicated on the session object and used by Native Apps, no need to waste CPU on re-comparing
    const valueType = typeof updatedUser[key];
    const validType = !updatedUser[key] || valueType === 'string' || valueType === 'number' || valueType === 'boolean';
    if(
      !validType ||
      key.slice( 0, 8 ) === 'session_' ||
      key === USER_PROFILE_KEYS.LastSessionRefreshTime
    ){
      continue;
    }

    if( prevUser[key] !== updatedUser[key] ){
      isSame = false;
      break;
    }
  }

  return isSame;
};

/**
 * Reset user session
 * @param {object} data - Arguments
 * @param {object} data.user - User object
 * @param {object} methods - Methods
 * @param {function} methods.setUser - User setter
 * @returns {function} - Reset callback
 */
export const composeResetSession = ( data, methods ) => () => {
  const { user = {} } = handleEmptyObjects( data );
  const { setUser } = methods || {};

  // Don't clear user if unresolved already
  if( !setUser || !user.resolved ){
    return;
  }

  devLogger( '[User] Reset user session', 0, LOG_TOPIC.Session );

  setUser( { resolved: false } );

  utils.resetUserStorage();
};

/**
 * Returns user profile from storage
 *
 * @returns {object} User profile object
 */
export const getUserFromStorage = () => {
  const user = getStorage( {
    secure: false,
    key: STORAGE_KEY.user
  } ) || {};

  return { resolved: false, cartItemCount: 0, ...user };
};

/**
 * Store user in localStorage
 * @param {object} data args
 * @param {object} data.user user object
 */
export const updateUserInStorage = ( data ) => {
  const { updatedUser = { resolved: false } } = handleEmptyObjects( data );

  setStorage( {
    secure: false,
    key: STORAGE_KEY.user,
    value: updatedUser
  } );
};

/**
 * Removes user from local storage
 */
export const resetUserStorage = () => {
  devLogger( '[User] Clearing local storage entry', 0, LOG_TOPIC.Session );
  removeStorage( { secure: false, key: STORAGE_KEY.user } );
  removeStorage( { secure: false, key: STORAGE_KEY.userLegacySessionData } );
};


/**
 * Store sessionData in localStorage
 * @param {object} data args
 * @param {object} data.sessionData user object
 */
export const updateSessionDataInStorage = ( data ) => {
  const { stk = { } } = handleEmptyObjects( data );
  const sessionData = getStorage( { secure: false, key: STORAGE_KEY.userLegacySessionData } ) || {};
  sessionData.secureToken = stk;
  setStorage( {
    secure: false,
    key: STORAGE_KEY.userLegacySessionData,
    value: sessionData
  } );
};

/**
 * @const {object} USER_PROFILE_KEYS - User profile keys
 */
export const USER_PROFILE_KEYS = {
  // system
  CreatdOn: 'createdOn',
  Gti: 'gti',
  LoginStatus: 'loginStatus',
  LoginType: 'loginType',
  LastSessionRefreshTime: 'lastSessionRefreshTime',
  Resolved: 'resolved',
  RetailerVisitorId: 'retailerVisitorId',
  // cart
  BasketSkus: 'basketskus',
  CartItemCount: 'cartItemCount',
  // profile
  DateOfBirth: 'dateOfBirth',
  Email: 'email',
  EmailOptIn: 'emailOptIn',
  HashedEmail: 'hashedEmail',
  FirstName: 'firstName',
  LastName: 'lastName',
  MemberSince: 'memberSince',
  // rewards
  IsRewardsMember: 'isRewardsMember',
  LoyaltyId: 'loyaltyId',
  PointsBalance: 'pointsBalance',
  PointsRedeemed: 'pointsRedeemed',
  PointsRedeemedValue: 'pointsRedeemedValue',
  RewardsMemberType: 'rewardsMemberType'
};

/**
 * @typedef {object} UserObject
 * // system
 * @property {string} createdOn - created timestamp
 * @property {string} gti - gti
 * @property {string} loginType - loginType, hardLogin|anonymous
 * @property {boolean} loginStatus - loginStatus, true|false
 * @property {string} lastSessionRefreshTime - session last updated timestamp
 * @property {string} resolved - resolved
 * @property {string} retailerVisitorId - retailerVisitorId
 * // cart
 * @property {string[]} basketskus - sku ids
 * @property {string} cartItemCount - cart item count
 * // profile
 * @property {string} dateOfBirth - dateOfBirth
 * @property {string} email - email
 * @property {string} emailOptIn - emailOptIn
 * @property {string} hashedEmail - hashedEmail
 * @property {string} firstName - firstName
 * @property {string} lastName - lastName
 * @property {string} memberSince - Formatted date string of sign up date
 * // rewards
 * @property {string} isRewardsMember - isRewardsMember
 * @property {string} loyaltyId - loyaltyId
 * @property {string} pointsBalance - pointsBalance
 * @property {string} pointsRedeemed - pointsRedeemed
 * @property {string} pointsRedeemedValue - pointsRedeemedValue
 * @property {string} rewardsMemberType - rewardsMemberType
 */


/**
 * @callback processSessionAction
 * @param {object} fnData - arguments
 * @param {object} fnData.sessionAction - session action
 * @returns {void}
 */

/**
 * @typedef {object} CartObject
 * @property {number} itemCount - cart item count
 */

/**
 * @callback setCart
 * @param {CartObject} data - Cart object
 */

/**
 * @typedef {object} StoreObject
 * @property {string} bopisStoreId - store id
 * @property {string} bopisStoreName - store name
 * @property {number} createdOn - timestamps
 */

/**
 * @callback setStore
 * @param {StoreObject} data - Store object
 */

/**
 * Context provider for react reuse
 * @typedef {object} UserContextObject
 * @property {addPostLoginCallback} addPostLoginCallback - Adds a callback to the postLoginCallbacks array
 * @property {CartObject} cart - Cart object
 * @property {{current:boolean}} isInitialSessionPing - isInitialSessionPing ref
 * @property {{current:boolean}} isProcessingAuthAction - isProcessingAuthAction ref
 * @property {processSessionAction} processSessionAction - Handles incoming session actions
 * @property {function} resetSession - Resets user session, clears local storage
 * @property {{current:boolean}} sessionUpdateRequested - sessionUpdateRequested ref
 * @property {setCart} setCart - Cart object setter
 * @property {setStore} setStore - Store object setter
 * @property {{current:boolean}} signOutRequested - sessionUpdateRequested ref
 * @property {null|StoreObject} store - Store object
 * @property {updateSession} updateSession - Merges incoming session data w/ current DXL user
 * @property {UserObject} user - User object
 */

export const UserContext = createContext( {
  addPostLoginCallback: () => {},
  cart: {
    itemCount: 0
  },
  isInitialSessionPing: {},
  isProcessingAuthAction: {},
  processSessionAction: () => {},
  resetSession: () => {},
  sessionUpdateRequested: {},
  setCart: () => {},
  setStore: () => {},
  signOutRequested: {},
  store: null,
  updateSession: () => {},
  user: {}
} );

/**
 * @method
 * @returns {UserContextObject}
 */
export const useUserContext = () => useContext( UserContext );

UserContextProvider.displayName = 'UserContextProvider';

export default UserContextProvider;
