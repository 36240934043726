/**
 * overlay container for store availability
 *
 * @module views/components/OverLayContainer
 * @memberof -Common
 */
import React, { useCallback, useEffect } from 'react';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { isOverlay, useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { isFunction } from '@ulta/core/utils/types/types';

import Modules from '../Modules/Modules';
import * as utils from './OverLayContainer';

/**
  * Represents a OverLayContainer component
  *
  * @method
  * @param {OverLayContainerProps} props - React properties passed from composition
  * @returns OverLayContainer
  */
export const OverLayContainer = function( props ){
  const { isClosingRef } = useOverlay();
  const { data } = usePageDataContext();
  const { breakpoint } = useDeviceInflection();
  const isMobileDevice = breakpoint.CURRENT_BREAKPOINT === 'SM';
  const contentRef = useCallback( node => {
    if( node !== null ){
      contentRef.current = node;
    }
  }, [] );

  const {
    accessibilityTitle,
    backAction,
    closeAction,
    disableClose,
    id,
    invokeAction,
    invokeMutation,
    loading,
    modules
  } = props;

  const {
    closeAccessibilityLabel,
    crossButtonVisibility,
    offsetElement,
    onDialogClose,
    showDynamicFlyoutHeader,
    updateBackAction,
    updateCloseAction,
    updateOverlay
  } = useOverlay();

  /*
   * For android devices, we need to look at the window height instead of the viewport height
  */
  useEffect( () => {
    if( isMobileDevice && contentRef ){
      utils.calculateOverlayHeight( { contentRef } );
    }
    if( !isMobileDevice && contentRef && offsetElement ){
      utils.calculateOverlayHeight( { contentRef, offsetElement, showOverlayHeader } );
    }
  }, [isMobileDevice, contentRef, offsetElement] );

  // If DXL sends a backAction down, push it into the provider
  useEffect( () => {
    if( !backAction?.graphql ){
      updateBackAction();
      return;
    }

    const action = {
      ...backAction,
      variables: {
        contentId: id
      }
    };
    updateBackAction( { backAction: action } );
  }, [backAction] );

  // If DXL sends a accessibilityTitle down, push it into the provider
  useEffect( () => {
    if( !accessibilityTitle ){
      updateOverlay();
      return;
    }
    updateOverlay( { ariaLabel: accessibilityTitle } );
  }, [accessibilityTitle] );

  // If DXL sends a disableClose down, push it into the provider
  useEffect( ( ) => {
    // disableClose defaults to false in OverlayProvider's state.
    if( !disableClose ){
      return;
    }

    updateOverlay( { disableClose } );
  }, [disableClose] );

  // If DXL sends a closeAction down, push it into the provider
  // Disable close here prevents closing from clicking outside overlay.
  useEffect( () => {
    updateCloseAction(
      { action: utils.getCloseAction( { closeAction } ), loading, disableClose },
      { invokeAction, invokeMutation }
    );
  }, [closeAction, loading, disableClose] );

  // If DXL sends an page-level inintAction that is another overlay, we need to handle re-opening it
  useEffect( () => {
    const reOpenAction = isOverlay( data?.Page?.meta?.initAction?.navigationType ) && data.Page.meta.initAction;
    if( !isClosingRef.current ){
      return;
    }

    updateCloseAction(
      { reOpenAction },
      {}
    );
  }, [data] );

  if( !hasItems( modules ) ){
    return null;
  }

  const showOverlayHeader = ( crossButtonVisibility || backAction ) && showDynamicFlyoutHeader;

  return (
    <div className='OverLayContainer'>

      { showOverlayHeader && (
        <AsyncComponent
          moduleName='OverlayFlyoutHeader'
          key='OverlayFlyoutHeader'
          invokeAction={ invokeAction }
          onClose={ onDialogClose }
          closeAccessibilityLabel={ closeAccessibilityLabel }
        />
      ) }

      <div className='OverLayContainer__body'
        ref={ contentRef }
      >
        <Modules
          modules={ modules }
          containerLayerHostContentId={ id }
          invokeAction={ invokeAction }
          invokeMutation={ invokeMutation }
          loading={ loading }
        />
      </div>

    </div>
  );
};

/**
 * Updates overlay height
 * @param {object} data - Arguments
 * @param {object} data.contentRef - content ref
 * @param {object} methods - Methods
 */
export const calculateOverlayHeight = ( data ) => {
  const { contentRef = {}, offsetElement, showOverlayHeader } = data || {};
  const headerHeight = 4.0625;

  if( offsetElement && contentRef.current && isFunction( offsetElement?.current?.getBoundingClientRect ) ){
    if( showOverlayHeader ){
      contentRef.current.style.height = `calc(100% - ${headerHeight }rem)`;
    }
    else {
      contentRef.current.style.height = `100%`;
    }

  }

  if( !offsetElement && contentRef.current ){
    contentRef.current.style.height = `calc(100% - ${headerHeight }rem)`;
  }
};

/**
 * Updates close action
 * @param {object} data - Arguments
 * @param {object} data.closeAction - DXL action
 * @param {boolean} data.loading - Loading state
 * @param {object} methods - Methods
 */
export const getCloseAction = ( data ) => {
  const { closeAction } = data || {};

  if( !closeAction?.graphql ){
    return closeAction;
  }

  return {
    ...closeAction,
    variables: {
      url: { path: global.location.pathname }
    }
  };
};

/**
  * @const {string} OVERLAY_CONTAINER_MODULE_NAME - moduleName for the overlay container
  */
export const OVERLAY_CONTAINER_MODULE_NAME = 'OverLayContainer';

OverLayContainer.displayName = OVERLAY_CONTAINER_MODULE_NAME;


export default OverLayContainer;


