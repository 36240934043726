/**
 * The AppConfigProvider component is used to provide the App Config
 *
 * @module views/__core/AppConfigProvider/AppConfigProvider
 */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import constants from '@ulta/core/utils/constants/constants';

import { handleReplace } from '../../utils/handleLocation/handleLocation';
import * as utils from './AppConfigProvider';


/**
 * Represents a AppConfigProvider component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns AppConfigProvider
 */
export const AppConfigProvider = React.forwardRef( ( props, _ )=>{
  const { context, children } = props;

  const [config, setConfig] = useState( context || {} );

  const updateAppConfig = useCallback( utils.composeUpdateAppConfig( { setConfig } ), [setConfig] );

  const value = { ...config, updateAppConfig };

  useEffect( () => {
    const updateConfig = ( e ) => {
      updateAppConfig( e.detail.config );
    };

    document.addEventListener( constants.EVENTS.UPDATE_APP_CONFIG, updateConfig );

    return () => {
      document.removeEventListener( constants.EVENTS.UPDATE_APP_CONFIG, updateConfig );
    };
  }, [] );

  useEffect( () => {
    // We need to wait for children to render
    setTimeout( () => {
      utils.handleStagingUrls( { previewDate: config.previewDate } );
    }, 0 );
  }, [config.previewDate, config.stagingHost] );

  return (
    <AppConfigContext.Provider value={ value }>
      { children }
    </AppConfigContext.Provider>
  );
} );

/**
 * Handles staging urls
 * 1. Removes retry param from url - when staging is overloaded we refresh the
 *    page a few times to see if it will load, this param is used to track that.
 *    If the page does successfully load we check for StaticError and then remove
 *   the param.
 * 2. Sanitize timestamps for marketing team to easily modify
 * @param {object} data - Data object
 * @param {string} data.previewDate - Preview date
 * @param {string} data.stagingHost - Staging host
 */
export const handleStagingUrls = ( data ) => {
  const { previewDate } = data || {};

  // This lives in a setTimeout to give the Staging error page time to render
  setTimeout( () => {
    const url = new URL( global.location.href );

    // Removes retry param from url
    const retryParam = url.searchParams.get( 'stagingRetries' );
    if( !!retryParam && !document.querySelector( '.StaticError' ) ){
      url.searchParams.delete( 'stagingRetries' );
      handleReplace( { url: url.href, preserveParams: true } );
    }
  }, 500 );

  // handleReplace will sanitize `%3A` to `:`, so as long as we have a previewDateParam
  // we want to run it through the sanitizer inside handleReplace
  const url = new URL( global.location.href );
  if( !!url.searchParams.get( 'previewDate' ) ){
    handleReplace( { url: url.href, previewDate } );
  }
};

/**
 * Context provider for react reuse
 * @type object
 */
export const AppConfigContext = createContext( {} );

/**
 * Method composeUpdateAppConfig to handle setConfig
 * @param {object} methods methods
 * @param {function} methods.setConfig setConfig
 * @param {object} newConfig newConfig
 */
export const composeUpdateAppConfig = ( methods ) => ( newConfig ) => {
  const { setConfig } = methods || {};

  if( !setConfig ){
    return;
  }

  setConfig( cfg => ( { ...cfg, ...newConfig } ) );
};

/**
 * contxt provider
 * @type object
 */
export const useAppConfigContext = ( ) => useContext( AppConfigContext );

AppConfigProvider.displayName = 'AppConfigProvider';

export default AppConfigProvider;