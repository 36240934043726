/**
 * This is the new Text component. It accepts parameters for HTML tag, formatting 'textStyle', text alignment, font style, decoration and color override.
 *
 * @module views/Atoms/Text
 * @memberof -Common
 */
import './Text.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Represents a Huge Text component
 *
 * @method
 * @param {TextProps} props - React properties passed from composition
 * @returns Text
 */
export const Text = React.forwardRef( ( props, ref ) => {
  const {
    ariaCurrent,
    ariaHidden,
    children,
    color,
    fontStyle,
    fontWeight,
    htmlTag,
    style,
    text,
    textAlign,
    textDecoration,
    textStyle,
    noSnippet
  } = props;

  const Tag = htmlTag.toLowerCase();
  // TODO: remove this when schema is update.
  const resolvedTextStyle = style || textStyle;

  const textBody = text || children;

  // TODO: remove this when DXL starts sanitizing \\n chars
  const sanitizedBody = typeof textBody === 'string' ? textBody.replaceAll( '\\n', '\n' ) : textBody;

  return (
    <>
      { !!sanitizedBody &&
      <Tag
        ref={ ref }
        className={ classNames( `Text-ds`, {
          ...( resolvedTextStyle && { [`Text-ds--${resolvedTextStyle}`]: resolvedTextStyle } ),
          ...( textAlign && { [`Text-ds--${textAlign}`]: textAlign } ),
          ...( color && { [`Text-ds--${color}`]: color } ),
          ...( fontStyle && { [`Text-ds--${fontStyle}`]: fontStyle } ),
          ...( textDecoration && { [`Text-ds--${textDecoration}`]: textDecoration } )
        } ) }
        { ...( ariaHidden && { [`aria-hidden`]: 'true' } ) }
        { ...( ariaCurrent && { [`aria-current`]: ariaCurrent } ) }
        { ...( noSnippet && { [`data-nosnippet`]: noSnippet } ) }
      >
        { fontWeight === 'bold' ? <strong>{ sanitizedBody }</strong> : sanitizedBody }
      </Tag>
      }
    </>
  );
} );

/**
 * Property type definitions
 * @type {object}
 * @property {string} textStyle - Sets the palette design display configuration
 * @property {string} htmlTag - Sets the HTML tag for the container
 * @property {string} textAlign - Sets the text alignment
 * @property {string} color - Sets the text color from Ulta palette
 * @property {string} fontStyle - Sets the text style (italic)
 * @property {string} textDecoration - Sets the text decoration (underline / strike through)
 * @property {boolean} ariaHidden - Sets the aria hidden if true
 * @property {string} ariaCurrent - Sets the aria current if present
 * @property {string} fontWeight - Sets the fontWeight bold to current if present
 * @property {boolean} noSnippet - Sets the data-nosnippet for SEO if true
 */
export const propTypes = {
  /** Type name that defines the tag (optional) and global className to apply */
  textStyle: PropTypes.oneOf( [
    'body-1',
    'body-2',
    'body-3',
    'body-lg',
    'body-sm',
    'body-xlg',
    'body',
    'button-compact',
    'button',
    'display-sm',
    'display-xlg',
    'displayBody-sm',
    'displayBody',
    'eyebrow',
    'headline-lg',
    'headline-sm',
    'headline',
    'label-sm',
    'label',
    'subtitle-1',
    'subtitle-2',
    'title-1',
    'title-2',
    'title-3',
    'title-4',
    'title-5',
    'title-6',
    'title-lg',
    'title-sm',
    'title-sm',
    'title-xlg',
    'title'
  ] ),
  /** Sets the HTML tag for the container */
  htmlTag: PropTypes.oneOf( [
    'p',
    'P',
    'span',
    'SPAN',
    'h1',
    'H1',
    'h2',
    'H2',
    'h3',
    'H3',
    'h4',
    'H4',
    'h5',
    'H5',
    'h6',
    'H6'
  ] ),
  /** Sets the text alignment */
  textAlign: PropTypes.oneOf( ['left', 'center', 'right'] ),
  /** Optional color overrides */
  color: PropTypes.oneOf( [
    'white',
    'neutral-25',
    'neutral-50',
    'neutral-100',
    'neutral-200',
    'neutral-300',
    'neutral-400',
    'neutral-500',
    'neutral-600',
    'neutral-700',
    'neutral-800',
    'neutral-900',
    'black',
    'orange-300',
    'orange-200',
    'orange-100',
    'magenta-500',
    'magenta-400',
    'magenta-200',
    'magenta-100',
    'fire-400',
    'fire-200',
    'fire-100',
    'plum-700',
    'plum-200',
    'plum-100',
    'validate-600',
    'alert-600',
    'alert-400',
    'alert-300',
    'alert-200',
    'alert-100'
  ] ),
  /** Optional font styles */
  fontStyle: PropTypes.oneOf( ['italic'] ),
  /** Optional text decorations */
  textDecoration: PropTypes.oneOf( ['underline', 'line-through'] ),
  ariaHidden: PropTypes.bool,
  /** Sets the aria current if present */
  ariaCurrent: PropTypes.string,
  fontWeight: PropTypes.string,
  /** Check to add data-nosnippet for SEO */
  noSnippet: PropTypes.bool
};

/**
 * Default values for passed properties
 * @typedef TextProps
 * @type {object}
 * @property {string} textStyle - The default class of the palette design display configuration
 * @property {string} htmlTag - The default HTML tag for the container
 * @property {string} textAlign - The default value for text alignment
 */
export const defaultProps = {
  color: 'black',
  textStyle: 'body-1',
  htmlTag: 'p',
  textAlign: 'left'
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;
Text.displayName = 'Text';

export default Text;
