import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { asyncMap } from '@apollo/client/utilities';
import { onError } from 'apollo-link-error';
import fetch from 'node-fetch';

import { ApplicationLocale } from '@ulta/core/providers/I18nProvider/I18nProvider';
import { fetchHeaderFooter } from '@ulta/core/utils/apolloMiddleware/fetchHeaderFooter/fetchHeaderFooter';
import { pageMiddleware } from '@ulta/core/utils/apolloMiddleware/pageMiddleware/pageMiddleware';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';

import { graphQlAgent } from '../agent/agent';
import constants from '../constants/constants';
import { isServer } from '../device_detection/device_detection';
import { devLogger, LOG_TOPIC } from '../devMode/devMode';
import { getUrlParams } from '../domain/domain';
import { handleEmptyObjects } from '../handleEmptyObjects/handleEmptyObjects';
import { isInValidPath } from '../isInvalidPath/isInvalidPath';
import { updatedHeaderWithPageConfiguration } from '../updatedHeaderWithPageConfiguration/updatedHeaderWithPageConfiguration';
import { updatedFooterWithPageConfiguration } from '../updateFooterWithPageConfiguration/updatedFooterWithPageConfiguration';
import { updatePageResponse } from '../updatePageResponse/updatePageResponse';
import * as utils from './apollo_client';

const { HFN_CACHE_TIMEOUT = 300000 } = process?.env || {};

export let Apollo_Client_GET;
export let Apollo_Client_POST;

// cached storage for header/footer
let header, footer, footerSimple;
let HFN_TIMEOUT = 0;

const isExcluded = isInValidPath( ['/header', '/footer'] );

/**
 * A method that return an Apollo Error object to handle apollo graphql errors that may be encuntered
 *
 * @method
 * @param { Object } config - configuration object which contains the graphqlURI, ggraphqlDomain, and the resolvers object
 */
export const getApolloClient = ( {
  graphqlGetUri,
  graphqlDomain,
  previewDate,
  stagingHost,
  resolvers,
  isStaging,
  origin,
  isNative
} ) => {
  // only create apolloclient if one doesn't exist
  Apollo_Client_GET = createApolloClient( {
    cache: new InMemoryCache().restore( window.__APOLLO_STATE__ ),
    connectToDevTools: true,
    ssrMode: true,
    domain: graphqlDomain,
    uri: graphqlGetUri,
    origin,
    isNative,
    name: 'ulta-graph',
    isStaging,
    stagingHost,
    previewDate,
    ...( resolvers && { resolvers } ),
    window
  } );
  return Apollo_Client_GET;
};

/**
 * Creates an ApolloClient instance which is used for conntectivity between the client
 * and the apollo graphql server.
 *
 * @method
 * @param { String } config - configuration object
 */
export const createApolloClient = ( config ) => {
  const { stagingHost, previewDate, isStaging, origin, isNative } = config;

  return new ApolloClient( {
    ssrMode: config.ssrMode,
    link: from( [
      onError( onErrorHandler( console ) ),
      getAuthMiddleWare( { stagingHost, previewDate, isNative } ),
      new ApolloLink( appendHeaderFooter( { isStaging }, { getHFN } ) ),
      new ApolloLink(
        hfnMiddleware(
          { isStaging, origin, config, locale: ApplicationLocale },
          {
            getCacheTimeout,
            updateCacheTimeout,
            setHFN,
            getHFN
          }
        )
      ),
      new ApolloLink( pageMiddleware( { previewDate, isStaging } ) ),
      new ApolloLink( determineContentReplace ),
      getHttpLink( config )
    ] ),
    credentials: 'include',
    name: config.name,
    cache: config.cache,
    resolvers: config.resolvers
  } );
};

/**
 * Determines whether to replace the content
 * based on initAction content.
 *
 * @method
 * @param { method } operation
 * @param { method } forward
 * @returns ApolloLink
 */
export const determineContentReplace = ( operation, forward ) => {
  return forward( operation ).map( ( response ) => {
    const { meta, content } = response?.data?.Page || {};
    const initAction = meta?.initAction || {};

    const isContentReplace =
      content === null && !!initAction.content && initAction.navigationType === DXL_NAVIGATION_TYPE.Push;

    operation.setContext( { response, isContentReplace } );

    return response;
  } );
};

/**
 * Error handler method for the apollo-link-error middleware which inspects
 * the existance of a graphql error or a network error  on all requests and outputs them all
 * to the console
 *
 * @method
 * @param { Object } logger - Instance of a logger object used to log messages
 */
export const onErrorHandler = ( logger = console ) => {
  return ( { graphQLErrors, networkError, operation, forward, response } ) => {
    if( graphQLErrors ){
      graphQLErrors.forEach( ( { message, locations, path } ) => {
        logger.log( `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` );
      } );
    }
    if( networkError ){
      logger.log( response );
      logger.log( `[Network error]: ${networkError}` );
    }
    forward( operation );
  };
};

/**
 * returns a curried Method which is responsible for setting global request auth headers
 * for the apollo client instance
 *
 * @method
 * @param { object } data -  object which contains locale value,previewDate, and stagingHost which should be passed to the header configuration
 * @param data.locale - locale value passed to header config
 * @param data.stagingHost - staging host value
 * @param data.previewDate - preview date value
 * @param data.isNative - is native value
 */
export const setAuthHeaders = ( data ) => {
  const { previewDate, stagingHost, locale, isNative } = data;
  return ( { headers = {} } ) => {
    return {
      headers: {
        ...headers,
        'X-ULTA-CLIENT-COUNTRY': 'US',
        'X-ULTA-CLIENT-LOCALE': locale,
        'X-ULTA-CLIENT-CHANNEL': 'web',
        ...( !!previewDate && { 'X-ULTA-CLIENT-PREVIEWDATETIME': previewDate } ),
        ...( !!stagingHost && { 'X-ULTA-CLIENT-STAGINGHOST': stagingHost } ),
        ...( !!isNative && { 'X-ULTA-CLIENT-ISNATIVE': isNative } ),
        'X-FORWARDED-PROTO': 'https'
      }
    };
  };
};

/**
 * Error handler method for the apollo-link-error which inspects
 * the existance of a graphql error or a network error and outputs
 * it to the console for viewing
 *
 * @method
 * @param { String } data - the locale value which should be passed to the header configuration
 * @param { String } data.stagingHost - the staging host Value
 * @param { String } data.previewDate - the previewDate value
 * @param { String } data.isNative - the is native value
 */
export const getAuthMiddleWare = ( data ) => {
  const { stagingHost, previewDate, isNative } = data || {};
  return new ApolloLink( getAuthMiddlewareLinkCallBack( { stagingHost, previewDate, isNative } ) );
};

/**
 * Error handler method for the apollo-link-error which inspects
 * the existance of a graphql error or a network error and outputs
 * it to the console for viewing
 *
 * @method
 * @param { String } data - object containg data  to be passed into the method
 * @param { String } data.stagingHost - the staging host Value
 * @param { String } data.previewDate - the previewDate
 * @param { String } data.isNative - the is native value
 */
export const getAuthMiddlewareLinkCallBack = ( data ) => {
  const { stagingHost: serverStagingHost, previewDate: serverPreviewDate, isNative: serverIsNative } = data;

  return ( operation, forward ) => {
    let locale = ApplicationLocale;
    const operationVariables = operation.variables || {};

    const {
      stagingHost: variablesStagingHost,
      previewDate: variablesPreviewDate,
      moduleParams,
      isNative: variablesIsNative
    } = operationVariables || {};

    const stagingHost = ( variablesStagingHost || serverStagingHost ) ?? undefined;
    const previewDate = ( variablesPreviewDate || serverPreviewDate ) ?? undefined;
    const isNative = ( variablesIsNative || serverIsNative ) ?? undefined;
    operation.setContext( setAuthHeaders( { locale, stagingHost, previewDate, isNative } ) );
    operation.setContext( setUserData( moduleParams ) );
    operation.setContext( { isNative: isNative } );

    return forward( operation );
  };
};

/**
 * Error handler method for the apollo-link-error which inspects
 * the existance of a graphql error or a network error and outputs
 * it to the console for viewing
 *
 * @method
 * @param { String } locale - the locale value which should be passed to the header configuration
 */
export const getHttpLink = ( config ) => {
  return createHttpLink( {
    ...( isServer() && config.fetch && { fetch: config.fetch } ),
    ...( isServer() && config.fetchOptions && { fetchOptions: config.fetchOptions } ),
    uri: `${config.domain}/${config.uri}`,
    credentials: 'include'
  } );
};

/**
 * setUserData accepts a data object and pulls out the login status and gti
 * @param { object } data
 * @returns object - user
 */
// TODO - move to utilites file
export const setUserData = ( data ) => {
  const { loginStatus = 'anonymous', gti = '' } = data || {};

  return {
    user: {
      loginStatus,
      gti
    }
  };
};

/**
 * getClient is a helper method to get a cached version or create a new version of ApolloClient
 * @method
 * @returns ApolloClient
 */
export const getClient = function( data ){
  const { isStaging, previewDate, stagingHost, graphqlURI, graphqlDomain, graphqlServerUri, isNative, method } =
    data || {};

  const origin = global.location.origin;

  if( Apollo_Client_GET && !isServer() && method === 'GET' ){
    return Apollo_Client_GET;
  }

  if( Apollo_Client_POST && !isServer() && method === 'POST' ){
    return Apollo_Client_POST;
  }

  Apollo_Client_POST = createApolloClient( {
    cache: new InMemoryCache(),
    ssrMode: true,
    domain: graphqlDomain,
    uri: isServer() ? graphqlServerUri : graphqlURI,
    origin,
    name: 'ulta-graph',
    isStaging,
    previewDate,
    stagingHost,
    isNative,
    fetch,
    ...( isServer() && { fetchOptions: { agent: graphQlAgent } } )
  } );
  return Apollo_Client_POST;
};

/*
 * TODO: Middleware to move out of this file
 */

/**
 * appendHeaderFooter will update a Page Response with Header and Footer data
 * @method
 * @param { method } operation
 * @param { method } forward
 * @returns ApolloLink
 */
export const appendHeaderFooter = ( data, methods ) => {
  const { isStaging } = data;
  const { getHFN } = methods || {};

  return function( operation, forward ){
    return forward( operation ).map( ( response ) => {
      const {
        web = {},
        isPage = false,
        isNative = false,
        enableGlobalHFNForDSOTF,
        isContentReplace
      } = operation.getContext() || {};
      let { header, footer, footerSimple } = getHFN( { isStaging } );
      const isValidPage = isValidPath( operation.variables?.url );

      const pageContent = response?.data?.Page?.content || {};
      const isPageRequest = isPage && isValidPage;
      const shouldAppend = isContentReplace || isPageRequest;

      if( shouldAppend && !isNative && enableGlobalHFNForDSOTF && header && footer ){
        /*
         * if header and footer are cached and the operation is a page operation, update the page response with a new HFN
         */
        operation.setContext( { isPage: false } );
        return updatePageResponse( {
          response,
          header: updatedHeaderWithPageConfiguration( {
            header,
            configuration: web
          } ),
          footer: updatedFooterWithPageConfiguration( {
            footer: web.displaySimplifiedFooter ? footerSimple : footer,
            configuration: web
          } ),
          pageModules: pageContent.modules
        } );
      }

      return response;
    } );
  };
};

/**
 * HFN Middleware is a wrapper for ease of testing
 * This method uses the operation and forward from ApolloLink and sends it to asyncMap
 * So that we can make addition async calls to fetch the header and footer from dxl
 * @param {*} methods - curried methods from ApolloClient
 * @returns fetchHeaderFooter
 */
export const hfnMiddleware = ( data, methods ) => {
  return ( operation, forward ) => {
    return asyncMap( forward( operation ), async( response ) => fetchHeaderFooter( response, methods, data, operation ) );
  };
};

/**
 * Helper getter and setter methods for curried ApolloLink functions
 */
export const getHFN = () => {
  return { header, footer, footerSimple };
};

export const setHFN = ( data = {} ) => {
  if( data.header ){
    devLogger( 'HFN cache updated for header', 0, LOG_TOPIC.Cache );
    header = data.header;
  }
  if( data.footer ){
    devLogger( 'HFN cache updated for footer', 0, LOG_TOPIC.Cache );
    footer = data.footer;
  }
  if( data.footerSimple ){
    devLogger( 'HFN cache updated for footerSimple', 0, LOG_TOPIC.Cache );
    footerSimple = data.footerSimple;
  }
  return { header, footer, footerSimple };
};

export const getCacheTimeout = () => {
  return HFN_TIMEOUT;
};

export const updateCacheTimeout = ( timeout = HFN_CACHE_TIMEOUT ) => {
  HFN_TIMEOUT = new Date( Date.now() + Number( timeout ) ).getTime();
  return HFN_TIMEOUT;
};

export const isValidPath = ( data, methods ) => {
  const { path } = data || {};
  const { excluded = isExcluded } = methods || {};

  if( path && excluded( path ) ){
    return false;
  }
  else {
    return true;
  }
};

export const isApolloStateMismatch = () => {
  if( isServer() ){
    return false;
  }

  const { ROOT_QUERY } = handleEmptyObjects( global.__APOLLO_STATE__ );
  const keys = ( ROOT_QUERY && Object.keys( ROOT_QUERY ) ) || [];

  const params = getUrlParams( global.location.search );

  let cacheKey = '';
  for ( const key of keys ){
    if( key.includes( 'Page(' ) ){
      cacheKey = key;
      break;
    }
  }

  let moduleParamMismatch = false;
  Object.entries( params ).forEach( ( [k, v] ) => {
    if( !cacheKey.includes( `"${k}"` ) || !cacheKey.includes( `"${v}"` ) ){
      moduleParamMismatch = true;
    }
  } );

  return moduleParamMismatch;
};

export const getApolloState = () => {
  const { ROOT_QUERY } = handleEmptyObjects( global.__APOLLO_STATE__ );
  const rootQueryList = ( ROOT_QUERY && Object.entries( ROOT_QUERY ) ) || [];

  let initialPageData = null;
  for ( const query of rootQueryList ){
    const [key, Page] = query;
    if( key.includes( 'Page(' ) ){
      if( utils.pageDataIgnoreList( Page.meta?.url ) ){
        continue;
      }
      initialPageData = { Page };
      break;
    }
  }

  return JSON.parse( JSON.stringify( initialPageData ) );
};

/**
 * Skip these endpoints
 * @param {string} url
 * @returns {boolean}
 */
export const pageDataIgnoreList = ( url ) =>
  url && [constants.HFN.header, constants.HFN.footer].some( ( p ) => url.includes( p ) );
