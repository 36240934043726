/**
 * This component renders an overlay dynamically
 *
 * @module views/__core/DynamicOverlayComponent
 * @memberof -Common
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import deepmerge from 'deepmerge';
import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import * as utils from '@ulta/core/components/DynamicOverlayComponent/DynamicOverlayComponent';
import ResponseMessages from '@ulta/core/components/ResponseMessages/ResponseMessages';
import { useDXLQuery } from '@ulta/core/hooks/useDXLQuery/useDXLQuery';
import { getRequiredClientParams } from '@ulta/core/hooks/useLayerHostAction/useLayerHostAction';
import { useLayerHostMeta } from '@ulta/core/hooks/useLayerHostMeta/useLayerHostMeta';
import useLoader from '@ulta/core/hooks/useLoader/useLoader';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { decorateModules, PROTECTED_PAGE_FLOW, usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import NonCachedPageQuery from '@ulta/core/utils/graphql/queries/cms/noncachedcms';
import { queryProcessor } from '@ulta/core/utils/queryProcessor/queryProcessor';

/**
 * Represents a DynamicOverlayComponent component
 *
 * @method
 * @param {DynamicOverlayComponentProps} props - React properties passed from composition
 * @returns DynamicOverlayComponent
 */
export const DynamicOverlayComponent = function( props ){
  const [content, loader, error] = utils.useDynamicOverlay( props );

  return (
    <div className='DynamicOverlayComponent'>
      { loader }

      { error &&
        <ResponseMessages
          messageType='error'
          showCloseButton
          message={ error.message }
        />
      }

      { content &&
        <AsyncComponent key={ content.componentLastUpdated }
          { ...content }
        />
      }
    </div>
  );
};

/**
 * Handles prepping variables for dynamic overlay
 *
 * There are 2 entry points to a dynamic overlay:
 * 1. DXL sends and init action
 * 2. User/developer invoked via a button or other scenario
 *
 * In scenario #1, the action is run through clientActionProcessor and will have params and
 * all it needs to invoke the associated queries to render the overlay.
 *
 * In scenario #2, if a user clicked an action passed in to a Button, we need to decorate the
 * action with user/gti.
 *
 * There are limitations to overlays currently invoked by a button (#2), we will never have "moduleParams"
 * from a caller component unless that component decorates the action prior to passing it into a button.
 *
 * This should be fine but it's a limtiation we should be aware of. DynamicOverlay can only decorate
 * actions with breakpoint and user client side params, if there is a need for anything else the calling
 * component would have to decorate the aciton prior to passing it in.
 *
 * @param {DynamicOverlayComponentProps} props
 * @returns {array} [content, loader, error]
 */
export const useDynamicOverlay = ( props ) => {
  const { protectedPageFlow = {} } = usePageDataContext();
  const { user } = useUserContext();
  const { breakpoint: { CURRENT_BREAKPOINT } } = useDeviceInflection();

  const action = useMemo( () => {
    let action = { ...( props?.action || {} ) };

    action.params = action.params || [];
    action.customHeaders = action.customHeaders || [];

    if( !hasItems( action.params ) ){
      action.params = ['gti', 'loginStatus'];
    }

    if( !hasItems( action.customHeaders ) ){
      action.customHeaders = [
        { key: 'X-ULTA-GRAPH-TYPE', value: 'query' },
        { key: 'X-ULTA-GRAPH-SUB-TYPE', value: 'noncachePage' }
      ];
    }

    if( !action.customHeaders.some( header => header.key === 'X-ULTA-GRAPH-MODULE-NAME' ) ){
      action.customHeaders.push( { key: 'X-ULTA-GRAPH-MODULE-NAME', value: 'DynamicOverlayComponent' } );
    }

    // Get required params
    action = getRequiredClientParams( {
      user,
      props,
      action,
      breakpoint: CURRENT_BREAKPOINT
    } );

    action = queryProcessor( { action } );
    action.graphql = action?.graphql || NonCachedPageQuery;

    return action;
  }, [props?.action] );

  // Invoke query wrapper
  const [invoke, { data, error, loading: dxlLoading }] = useDXLQuery( action.graphql, action.config, true, 'DynamicOverlayComponent' );

  useEffect( () => {
    const resolvedOrIdle = [
      PROTECTED_PAGE_FLOW.Idle,
      PROTECTED_PAGE_FLOW.Resolved,
      PROTECTED_PAGE_FLOW.SignInOverlay
    ].includes( protectedPageFlow.current );

    if( !user.resolved || !resolvedOrIdle ){
      return;
    }

    // Debouncing is necessary because creating a portal and rendering the overlay causes an unmount
    // then a remount of the overlay. This causes the query to be invoked twice and we don't want that.
    clearTimeout( debounceInvokation );
    debounceInvokation = setTimeout( () => {
      invoke( action );
    }, 0 );
  }, [invoke, user.resolved, action] );

  // If we're loading from DXL or waiting for a user resolution, or if there's no data it usually
  // means there's a subsequent query that will be invoked.
  const hasData = data?.Page?.content || data?.Page?.meta;
  const loading = !error && ( !user.resolved || dxlLoading || !hasData );
  const [loader] = useLoader( { loading, delay: 350 } );

  // Decorate modules with timestamps for LayerHost. We have to do this
  // for DynamicOverlays because they exist outside of the normal page context
  const [content, setContent] = useState( null );
  const responseHandler = useCallback( utils.composeContentHandler( { pageData:data }, { setContent } ), [
    data
  ] );
  useEffect( responseHandler, [data] );

  // We want to respond to any potential meta data from the DXL query
  useLayerHostMeta( {
    meta: data?.Page?.meta,
    // We need to pass the dxlLoading status (not the derived value) because we want to know
    // when DXL has finished loading, not if there's a potential subsequent query
    loading: dxlLoading,
    props: content || {}
  } );

  return [content, loader, error];
};

/**
 * debounceInvokation - used to debounce the invoke function
 */
let debounceInvokation;

/**
 * composeContentHandler - is used to handle the content for DynamicOverlay
 * @param {object} data object contains pageData for the Overlay content
 * @param {object} methods object contains methods like setContent which are passed as args to the function
 * @param {function} methods.setContent setContent
 */
export const composeContentHandler = ( data, methods ) => () => {
  const { setContent } = methods || {};
  const { pageData } = data || {};
  if( !setContent ){
    return;
  }
  if( !pageData?.Page?.content ){
    setContent( null );
    return;
  }

  const componentLastUpdated = Date.now();
  const newContent = deepmerge( {}, { ...pageData.Page.content, componentLastUpdated } );

  // Invalidate children timestamps so that they accept new props from this parent
  const childrenLastUpdated = componentLastUpdated - 1;

  newContent.modules = decorateModules( { modules: newContent.modules, componentLastUpdated: childrenLastUpdated } );
  setContent( newContent );
  setTimeout( () => {
    document.body.dispatchEvent( new Event( DYNAMIC_OVERLAY_CONTAINER_LOADED_EVENT ) );
  } );
};

export const DYNAMIC_OVERLAY_CONTAINER_LOADED_EVENT = 'DynamicOverlayContainerLoaded';

/**
 * Property type definitions
 *
 * @typedef {object} DynamicOverlayComponentProps
 * @property {object} action - DXL action
 */
export const propTypes =  {
  action: PropTypes.object
};


DynamicOverlayComponent.propTypes = propTypes;

export default DynamicOverlayComponent;