/**
 * This is a RecentSearchItem Component, which is used to display a single recent search in the search list. This component incorporates an icon and the Link, Text and Button components.
 *
 * @module views/Molecules/RecentSearchItem
 * @memberof -Common
 */
import './RecentSearchItem.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';

import { TYPEAHEAD_SEARCH_TYPE } from '@ulta/modules/SearchHeader/SearchHeader';

/**
 * Represents a RecentSearchItem component
 *
 * @method
 * @param {RecentSearchItemProps} props - React properties passed from composition
 * @returns RecentSearchItem
 */

export const RecentSearchItem = function( props ){
  const {
    clearRecentLabel,
    label,
    url,
    dataCaptureHandler,
    handleAddRecentSearch,
    handleRemoveRecentSearch,
    handleSaveSearchTerm
  } = props;
  const searchType = TYPEAHEAD_SEARCH_TYPE.Recents;

  if( !url || !label ){
    return null;
  }

  const removeButtonAriaLabel = `${clearRecentLabel} ${label}`;

  return (
    <div className='RecentSearchItem'>
      <div className='RecentSearchItem__recentSearch'>
        <Link_Huge secondary
          url={ url }
          onClick={ () => {
            dataCaptureHandler( { searchTerm: label, searchType, targetUrl: url } );
            handleAddRecentSearch( label );
            handleSaveSearchTerm( label );
            handleRedirect( { url } );
          } }
        >
          <div className='RecentSearchItem__recentSearch--icon'>
            <Icon name='Clock'
              size='s'
              aria-hidden={ true }
            />
          </div>
          <Text htmlTag='span'
            textStyle='body-2'
            color='neutral-600'
            textColor='xsubtle'
          >
            { label }
          </Text>
        </Link_Huge>
      </div>
      <Button
        variant='unstyled'
        icon
        iconImage='X'
        iconSize='lg'
        ariaLabel={ removeButtonAriaLabel }
        className='RecentSearchItem__remove'
        onClick={ () => {
          handleRemoveRecentSearch( label );
        } }
        ariaHiddenIcon={ true }
      />
    </div>
  );
};

/**
 * Property type definitions
 * @typedef RecentSearchItemProps
 * @type {object}
 * @property {string} clearRecentLabel - This is recent searches clear all label
 * @property {string} label - Sets the label of the Suggestion
 * @property {string} url - Sets the url of the Suggestion
 * @property {function} dataCaptureHandler - Function will handle/capture datacapture events and store in local storage
 * @property {function} handleRemoveRecentSearch - Function will remove recent search from local storage and update state
 * @property { function } handleAddRecentSearch - Function will handle adding recent search to local storage
 * @property { function } handleSaveSearchTerm - Function will handle saving search term to local storage
 */
export const propTypes = {
  /** Sets the aria label of the clear button */
  clearRecentLabel: PropTypes.string,
  /** Sets the label of the RecentSearchItem */
  label: PropTypes.string,
  /** Sets the url of the RecentSearchItem */
  url: PropTypes.string,
  /** capture datacapture event and store in local storage */
  dataCaptureHandler: PropTypes.func,
  /** remove recent search from local storage and update state */
  handleRemoveRecentSearch: PropTypes.func,
  /** Handle adding recent search to local storage */
  handleAddRecentSearch: PropTypes.func,
  /** Handle saving search term to local storage */
  handleSaveSearchTerm: PropTypes.func
};

RecentSearchItem.propTypes = propTypes;

export default RecentSearchItem;
