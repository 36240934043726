/**
 * Toggle Switch Button control is a custom HTML5 input-type checkbox control that allows you to perform a toggle (on/off) action between checked and unchecked states. It supports different sizes, labels, label positions, and UI customization.
 *
 * @module views/Atoms/CheckboxToggle
 * @memberof -Common
 */
import './CheckboxToggle.scss';

import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import ResponseMessages from '@ulta/core/components/ResponseMessages/ResponseMessages';
import { useLocalRef } from '@ulta/core/hooks/useLocalRef/useLocalRef';
import { triggerDatacaptureEvent } from '@ulta/core/utils/datacapture/datacapture';


/**
 * Represents a CheckboxToggle component
 *
 * @method
 * @param {CheckboxToggleProps} props - React properties passed from composition
 * @returns CheckboxToggle
 */
const CheckboxToggle = React.forwardRef( ( props, ref ) => {
  const {
    onChange,
    disabled,
    toggleButton,
    error,
    tabIndex,
    checked,
    id,
    name,
    labelPositionLeft,
    children,
    dataCaptureData,
    defaultChecked,
    ariaDescribedBy
  } = props;

  // Provides a fallback argument so Formik's useField doesn't complain
  const fieldArgs = { ...props, name: props.name || 'fallback' };
  const [field] = useField( fieldArgs );
  const localRef = useLocalRef( { ref } );
  if( !name && !id ){
    return null;
  }

  // Error or ariaDescribedBy We set that Id for aria-describedby
  let ariaId = null;
  if( error && name ){
    ariaId = `${name}-error`;
  }
  else if( error && id ){
    ariaId = `${id}-error`;
  }
  else if( ariaDescribedBy ){
    ariaId = ariaDescribedBy;
  }

  return (
    <div className='CheckboxToggle'>
      <input
        { ...field }
        id={ id }
        tabIndex={ tabIndex }
        type='checkbox'
        className='CheckboxToggle__checkbox'
        onMouseDown={ ( e ) => e.preventDefault() }
        { ...( onChange && {
          onChange: ( e ) => {
            field.onChange( e );
            triggerDatacaptureEvent( dataCaptureData );
            onChange( checked );
          }
        } ) }
        name={ name }
        checked={ checked }
        defaultChecked={ defaultChecked }
        { ...( disabled && { disabled: disabled } ) }
        aria-checked={ checked }
        { ...( ariaId && { 'aria-describedby': ariaId } ) }
      />
      <label
        htmlFor={ id }
        className='CheckboxToggle__label'
        ref={ localRef }
      >
        { labelPositionLeft && children }
        <span className={
          classNames( 'CheckboxToggle__input', {
            'CheckboxToggle__input--toggleButton': toggleButton,
            'CheckboxToggle__input--labelOnLeft': labelPositionLeft,
            'CheckboxToggle__input--checkbox': !toggleButton,
            'CheckboxToggle__input--labelOnRight': !labelPositionLeft
          } )
        }
        >
          <span className={
            classNames( {
              'CheckboxToggle__slider CheckboxToggle__slider--round': toggleButton,
              'CheckboxToggle__normalCheckBox': !toggleButton,
              'CheckboxToggle__slider CheckboxToggle__slider--disabled': toggleButton && disabled,
              'CheckboxToggle__normalCheckBox CheckboxToggle__normalCheckBox--disabled': !toggleButton && disabled
            } )
          }
          >
            { toggleButton &&
              <span
                className={
                  classNames( 'CheckboxToggle__sliderSwitch', {
                    'CheckboxToggle__sliderSwitch--enabled': !disabled,
                    'CheckboxToggle__sliderSwitch--disabled': disabled
                  } )
                }
              >
              </span> }
          </span>
        </span>
        { !labelPositionLeft && children }
      </label>

      {
        error &&
        <ResponseMessages
          id={ ariaId }
          message={ error }
          messageType={ 'error' }
        />
      }

    </div>
  );
} );
/**
 * Property type definitions
 * @typedef CheckboxToggleProps
 * @type {object}
 * @property {boolean} disabled - Sets the user behavior of toggle button
 * @property {string} name - Sets the name attribute which is required field
 * @property {string} id - Sets id attribute for toggle button
 * @property {number} tabIndex - Sets the tabindex attribute of toggle button
 * @property {boolean} labelPositionLeft - Sets the position of the label
 * @property {boolean} checked - Sets the state of toggle button
 * @property {boolean} toggleButton - Sets checkbox or toggle button display
 * @property {string} error - Sets the exclamationcircle svg when there is error
 * @property {string} ariaDescribedBy - Sets the aria-describedby ID on the input for an external reference
 */
export const propTypes = {
  /** Sets the checkboxToggle to disabled mode if true */
  disabled: PropTypes.bool,
  /** Sets the checkboxToggle's name */
  name: PropTypes.string.isRequired,
  /** Sets the checkboxToggle's id */
  id: PropTypes.oneOfType( [PropTypes.string, PropTypes.number] ),
  /** Sets the checkboxToggle's tabIndex */
  tabIndex: PropTypes.number,
  /** Sets the label to left side if true */
  labelPositionLeft: PropTypes.bool,
  /** Sets the checkboxToggle as selected when true */
  checked: PropTypes.bool,
  /** Sets the checkboxToggle as toggle button if true and as checkbox when false*/
  toggleButton: PropTypes.bool,
  /** Sets the error message when present*/
  error: PropTypes.string,
  /** Sets the aria-describedby ID on the input for an external reference */
  ariaDescribedBy: PropTypes.string,
  /** Sets the text and count content */
  children: PropTypes.node
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {number} tabIndex=0 - Sets the tab index for element
 * @property {boolean} labelPositionLeft=false - Sets the position of label to left side.
 * @property {boolean} toggleButton=false - Sets the component behavior if true to toggleButton else to checkbox
 */
export const defaultProps = {
  tabIndex: 0,
  labelPositionLeft: false,
  toggleButton: false
};

CheckboxToggle.displayName = 'CheckboxToggle';
CheckboxToggle.propTypes = propTypes;
CheckboxToggle.defaultProps = defaultProps;

export default CheckboxToggle;

