/**
 * UtilityLinks is a component that calls different links
 *
 * @module views/components/UtilityLinks
 * @memberof -Common
 */
import './UtilityLinks.scss';

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';

import { isAuthButton, isButton } from '@ulta/utils/actionUtils/actionUtils';

import NavigationLink from '../NavigationLink/NavigationLink';
import { useTopBar } from '../TopBar/TopBar';

/**
   * Represents a UtilityLinks component
   *
   * @method
   * @param {UtilityLinksProps} props - React properties passed from composition
   * @returns UtilityLinks
   */
export const UtilityLinks = React.forwardRef( ( props, _ ) =>{
  const {
    modules,
    show,
    setShow,
    textStyle,
    htmlTag,
    color,
    utilityLinkAccessibility
  } = props;

  const { inflection } = useDeviceInflection();
  const isMobile = inflection.MOBILE;
  const [activeModule, setActiveModule] = useState( null );
  const { displayUtilityNavigation } = useTopBar();
  const { displayOverlay } = useOverlay();
  const ref = useRef();

  // Reset active class
  useEffect( () => {
    if( !displayOverlay ){
      setActiveModule( null );
    }
  }, [displayOverlay] );


  if( !hasItems( modules ) || !displayUtilityNavigation ){
    return null;
  }

  return (
    <div className={ UTILITY_LINKS_CLASS }>
      <nav aria-label={ utilityLinkAccessibility }>
        <ul className={
          classNames( {
            'UtilityLinks__mobileNav' : isMobile
          } )
        }
        ref={ ref }
        >
          { modules.map( ( module, index ) =>{

            const displayButton = isButton( module.action ) || isAuthButton( module.action );

            return (
              <li
                key={ index }
                className={ classNames( {
                  'UtilityLinks--active': !isMobile && activeModule === module.action.label,
                  UtilityLinks__mobileNavMenuItem: isMobile
                } ) }
              >
                { displayButton && (
                  <>
                    <Button
                      likeLink
                      tertiary
                      ariaLabel={ module.description }
                      onClick={ () => {
                        setActiveModule( module.action.label );
                      } }
                      unstyled
                      className='UtilityLinks__button Link_Huge'
                      action={ module.action }
                      crossButtonVisibility={ true }
                      { ...( !isMobile && { offsetElement:  ref } ) }
                      { ...( { overlayCustomClass: 'UtilityLinks__Flyout' } ) }
                    >
                      { isMobile && module.icon &&
                      <Icon size='l'
                        name={ module.icon }
                      /> }
                      <Text textStyle={ textStyle }
                        htmlTag={ htmlTag }
                        color={ color }
                      >
                        { module.action.label }
                      </Text>
                      { module.action?.navigationType === DXL_NAVIGATION_TYPE.Flyout && isMobile && (
                        <Icon size='m'
                          name={ 'CaretForward' }
                        />
                      ) }
                    </Button>
                  </>
                ) }

                { !displayButton && (
                  <NavigationLink
                    action={ module.action }
                    description={ module.description }
                    textStyle={ textStyle }
                    htmlTag={ htmlTag }
                    color={ color }
                    show={ show }
                    setShow={ setShow }
                    { ...( isMobile && module.icon && { icon: module.icon } ) }
                    iconSize='l'
                  />
                ) }
              </li>
            );
          } )
          }
        </ul>
      </nav>
    </div>
  );
} );

export const UTILITY_LINKS_CLASS = 'UtilityLinks';

/**
   * Property type definitions
   * @typedef UtilityLinksProps
   * @type {object}
   * @property {array} modules - modules
   * @property {bool} show - show
   * @property {function} setShow - setShow
   * @property {string} textStyle - text style
   * @property {string} htmlTag - html tag
   * @property {string} color - color
   * @property {string} utilityLinkAccessibility - utilityLinkAccessibility
   */
export const propTypes =  {
  modules: PropTypes.array,
  show: PropTypes.bool,
  textStyle: PropTypes.string,
  htmlTag: PropTypes.string,
  color: PropTypes.string,
  setShow: PropTypes.func,
  utilityLinkAccessibility: PropTypes.string
};

/**
   * Default values for passed properties
   *
   * @type {object}
   * @property {string} textStyle='body-3' - The default text style
   * @property {string} htmlTag='span' - The default html tag
   * @property {string} color='neutral-600' - The default color
   * @property {string} utilityLinkAccessibility='utility link' - The default aria-label
   */
export const defaultProps =  {
  textStyle: 'body-3',
  htmlTag: 'span',
  color: 'neutral-600',
  utilityLinkAccessibility: 'utility link'
};

UtilityLinks.propTypes = propTypes;
UtilityLinks.defaultProps = defaultProps;
UtilityLinks.displayName = 'UtilityLinks';

export default UtilityLinks;
