/**
 * This is a temporary view of the PDP page
 *
 * @module views/__core/Page/Page
 * @memberof -Common
 */

import React from 'react';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Modules from '@ulta/core/components/Modules/Modules';
import { usePageScroll } from '@ulta/core/hooks/usePageScroll/usePageScroll';
import { useStorePersistAction } from '@ulta/core/hooks/useStorePersistAction/useStorePersistAction';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useServerRequestContext } from '@ulta/core/providers/ServerRequestContextProvider/ServerRequestContextProvider';
import { processPageModules } from '@ulta/core/utils/apolloMiddleware/fetchHeaderFooter/fetchHeaderFooter';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

/**
 * Represents a CMS Page
 *
 * @method * @returns CMSPage
 */
export const Page = React.forwardRef( ( props, _ ) => {
  const {
    dataCapture,
    id: pageId,
    modules,
    notificationConfig = {},
    seo = {},
    web: { sdkAction, headerScripts = [], backgroundColor } = {},
    meta = {},
    invokeAction
  } = handleEmptyObjects( props );

  const { inflection } = useDeviceInflection();
  const isMobile = inflection.MOBILE;
  const { storePersistAction } = meta;

  // Used for Birthday notifications, if configured we will have an ID and will want to render the Notification component
  const isNotification = !!notificationConfig.id;

  usePageScroll();

  const serverRequestContext = useServerRequestContext();

  const pageModules = isServer() ?
    processPageModules( { serverRequestContext, props, isMobile } ) :
    modules;

  useStorePersistAction( {
    props,
    storePersistAction,
    invokeAction
  } );

  return (
    <div
      className='Page'
      style={ {
        backgroundColor
      } }
    >
      { sdkAction && !isServer() && (
        <AsyncComponent
          moduleName='Sdk'
          id='SDK'
          meta={ {
            initAction: sdkAction
          } }
          initAction={ props.web.sdkAction }
        />
      ) }
      { headerScripts.map( ( i, index ) => {
        const id = i._meta.deliveryId;
        return (
          <AsyncComponent key={ `${id}:${index}` }
            { ...i }
            id={ id }
          />
        );
      } ) }
      <Modules
        modules={ pageModules }
      />
      { dataCapture && (
        <AsyncComponent
          { ...dataCapture }
          id={ pageId }
        />
      ) }
      { seo.WebpageMetaData && <AsyncComponent { ...seo.WebpageMetaData } /> }
      { isNotification && (
        <AsyncComponent
          moduleName='Notifications'
          notificationConfig={ notificationConfig }
          id={ notificationConfig.id }
        />
      ) }
    </div>
  );
} );



/**
 * @const {string} PAGE_MODULE_NAME - Page module name
 */
export const PAGE_MODULE_NAME = 'Page';

Page.displayName = 'Page';

export default Page;
