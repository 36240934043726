/**
 * rendering the modles elements for any module's modules
 *
 * @module views/components/Modules
 * @memberof -Common
 */

import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { hasItems } from '@ulta/core/utils/array/array';


/**
 * Represents a modules component
 *
 * @method
 * @param { ModulesProps} props - React properties passed from composition
 * @returns Modules
 */
export const Modules = function( props ){
  if( !hasItems( props.modules ) ){
    return null;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  let { modules, BeforeEach, AfterEach, ...rest } = props;

  return props.modules?.map( ( component, index ) => (
    <Fragment key={ `${component.id}:${index}` }>
      { BeforeEach && <BeforeEach index={ index } /> }
      <AsyncComponent
        /* this rest is being spread because the navigation link does weird stuff */
        { ...rest }
        { ...component }
      />
      { AfterEach && <AfterEach index={ index } /> }
    </ Fragment>
  ) );
};

/**
 * Property type definitions
 * @typedef ModulesProps
 * @type {object}
 * @property {array} modules - Array of modules (components) in this slot
 */
export const propTypes =  {
  modules: PropTypes.array,
  BeforeEach: PropTypes.func,
  AfterEach:PropTypes.func
};


Modules.propTypes = propTypes;

export default Modules;

