
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

/**
 * Builds the URL that clients use to redirect to the auth handler endpoint. We
 * redirect to our own server first so we can set cookies and prevent various attacks
 * by setting a nonce and state value.
 *
 * This also allows us to be in control of the redirect URL and the screen that the user
 * without linking to Auth0 first. If we were to link straight to the tenant a lot of features
 * would be impossible to support like tracking where links come from because old implementations
 * would be hard to follow.
 *
 * For these reasons we link to our auth endpoint at /auth/login and have it redirect to the tenant.
 *
 * All vendors like DoorDash can then link to /auth/login and we can control the experience.
 * @param {object} data - Arguments
 * @param {string} data.auth0login - Auth0 tenant URL
 * @param {string} data.location - Location object
 * @param {string} data.redirect - Redirect after login
 * @param {string} data.screen - Screen to display on the Auth0 login page
 * @returns string - URL for web-site /auth/login handler
 */
export const getAuthUrlForClient = ( data ) => {
  const {
    auth0login,
    location = global.location,
    redirect,
    screen = AUTH0_SCREEN.Login
  } = handleEmptyObjects( data );

  const url = new URL( location.href );
  url.pathname = auth0login;

  const redirectUrl = new URL( redirect || location.origin );
  url.searchParams.append( 'redirect', `${redirectUrl.pathname}${redirectUrl.search}` );

  if( screen ){
    url.searchParams.append( 'screen', screen );
  }

  return url.toString();
};

/**
 * Handles building the Auth0 tenant URL that redirects to the universal login page
 * @param {object} data - Arguments
 * @param {object} data.additionalParams - Additional parameters to pass to the Auth
 * @param {string[]} data.allowedParams - Allowed parameters to pass to the Auth
 * @param {string} data.auth0callback - Auth0 callback URL
 * @param {string} data.auth0clientId - Auth0 client ID
 * @param {string} data.auth0scope - Auth0 scope
 * @param {string} data.auth0tenant - Auth0 tenant URL
 * @param {string} data.auth0apiAudience - Auth0 API audience
 * @param {object} data.location - Location object
 * @param {string} data.nonce - Nonce value
 * @param {string} data.redirect - Redirect after login
 * @param {string} data.responseType - Response type, hard coded as 'code'
 * @param {string} data.screen - Screen to display on the Auth0 login page
 * @param {string} data.state - State value
 * @returns string - Auth0 redirect URL
 */
export const getAuthUrlForServer = ( data ) => {
  const {
    additionalParams = {},
    allowedParams = [],
    auth0callback,
    auth0clientId,
    auth0scope = DEFAULT_SCOPE,
    auth0tenant,
    auth0apiAudience,
    env,
    location,
    nonce,
    redirect,
    responseType = DEFAULT_RESPONSE_TYPE,
    screen,
    state
  } = handleEmptyObjects( data );

  // Prepare a callback URL
  const callback = new URL( location.href );
  callback.pathname = auth0callback;
  if( redirect ){
    callback.search = `?redirect=${redirect}`;
  }

  const url = new URL( auth0tenant );
  url.searchParams.append( 'response_type', responseType );
  url.searchParams.append( 'client_id', auth0clientId );
  url.searchParams.append( 'redirect_uri', callback.toString() );
  url.searchParams.append( 'scope', auth0scope );
  url.searchParams.append( 'audience', auth0apiAudience );

  if( state ){
    url.searchParams.append( 'state', state );
  }

  if( nonce ){
    url.searchParams.append( 'nonce', nonce );
  }

  if( screen ){
    // The prompt should always be defined as 'login', this forces a new session and
    url.searchParams.append( 'prompt', AUTH0_SCREEN.Login );

    // The ext-template binds a `template` var on the Auth0 Universal Login template side
    // so we can conditionally show copy or UI elements. It should always match the current
    // "screen" we have defined.
    url.searchParams.append( 'ext-template', screen );

    if( screen === AUTH0_SCREEN.Signup ){
      url.searchParams.append( 'screen_hint', AUTH0_SCREEN.Signup );
    }
  }

  // supports lower envs of auth0 to enable pointing to different BE services,
  // this surfaces the eph env into the auth0 tenant so we can point webhooks at
  // the right place with the X-ULTA-API-ENVIRONMENT header
  if( env ){
    url.searchParams.append( 'ext-env', env );
  }

  // White list params to pass to Auth0 tenant
  for ( const p of allowedParams ){
    if( !additionalParams[ p ] ){
      continue;
    }

    url.searchParams.append( p, additionalParams[ p ] );
  }

  return url.toString();
};

// Maps client action type to exp-* params for conditional rendering on the Auth0 template
// These values should not change unless the Auth0 template changes as well as their prompt/screen_hint values
export const AUTH0_SCREEN = {
  Login: 'login',
  Signup: 'signup',
  Checkout: 'checkout'
};

export const DEFAULT_SCOPE = 'openid profile email';
export const DEFAULT_RESPONSE_TYPE = 'code';