/**
 * The ProductRailHorizontalProvider component acts as setter/getter for filters functionality and displaying results to views
 *
 * @module ui/providers/ProductRailHorizontalProvider/ProductRailHorizontalProvider
 */
import React, { createContext, useContext, useState } from 'react';

/**
 * Represents a ProductRailHorizontalProvider component
 *
 * @method
 * @param { Object } props - React properties passed from composition
 * @returns ProductRailHorizontalProvider
 */
export const ProductRailHorizontalProvider = function( props ){
  const {
    children
  } = props;

  const [visibleSlidesIndexes, setVisibleSlidesIndexes] = useState( null );

  return (
    <ProductRailHorizontalContext.Provider
      value={ {
        visibleSlidesIndexes,
        setVisibleSlidesIndexes
      } }
    >
      { children }
    </ProductRailHorizontalContext.Provider>
  );
};

/**
 * Context object for react reuse
 * @type object
 */
export const ProductRailHorizontalContext = createContext();

/**
 * ProductRailHorizontalContext provider
 * @type object
 */
export const useProductRailHorizontalContext = () => useContext( ProductRailHorizontalContext );

export default ProductRailHorizontalProvider;
