import React from 'react';

import { ApolloProvider } from '@apollo/client';

import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import AuthProvider from '@ulta/core/providers/AuthProvider/AuthProvider';
import { EventProvider } from '@ulta/core/providers/EventProvider/EventProvider';
import I18nProvider from '@ulta/core/providers/I18nProvider/I18nProvider';
import InflectionProvider from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import LayerHostProvider from '@ulta/core/providers/LayerHostProvider/LayerHostProvider';
import OverlayProvider from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import PageDataProvider from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import PreviewProvider from '@ulta/core/providers/PreviewProvider/PreviewProvider';
import ResourceLoaderProvider from '@ulta/core/providers/ResourceLoaderProvider/ResourceLoaderProvider';
import ServerRequestContextProvider from '@ulta/core/providers/ServerRequestContextProvider/ServerRequestContextProvider';
import SnackBarProvider from '@ulta/core/providers/SnackBarProvider/SnackBarProvider';
import StyleProvider from '@ulta/core/providers/StyleContextProvider/StyleContextProvider';
import UserContextProvider from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { getUrlParams } from '@ulta/core/utils/domain/domain';

export const ContextContainer = function( props ){
  const { context, locale, baseInflection, client } = props;
  const { stagingHost, auth0login } = useAppConfigContext();
  const { timestamp } = getUrlParams( global?.location?.search );

  return (
    <ServerRequestContextProvider context={ context }>
      <PreviewProvider
        isSitePreview={ !!( stagingHost && timestamp ) }
        stagingHost={ stagingHost }
        timestamp={ timestamp }
      >
        <AuthProvider auth0login={ auth0login }>
          <I18nProvider locale={ locale }>
            <ResourceLoaderProvider>
              <InflectionProvider baseInflection={ baseInflection }>
                <PageDataProvider>
                  <EventProvider>
                    <ApolloProvider client={ client }>
                      <LayerHostProvider>
                        <UserContextProvider>
                          <SnackBarProvider>
                            <OverlayProvider>
                              <StyleProvider>
                                { props.children }
                              </StyleProvider>
                            </OverlayProvider>
                          </SnackBarProvider>
                        </UserContextProvider>
                      </LayerHostProvider>
                    </ApolloProvider>
                  </EventProvider>
                </PageDataProvider>
              </InflectionProvider>
            </ResourceLoaderProvider>
          </I18nProvider>
        </AuthProvider>
      </PreviewProvider>
    </ServerRequestContextProvider>
  );
};

export default ContextContainer;
