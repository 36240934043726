/**
 * This is a Search Header Component.This Component is used to render the SearchMobile and the SearchDesktop Component.
 *
 * @module views/components/SearchHeader
 * @memberof -Common
 */
import './SearchHeader.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import * as datacapture from '@ulta/core/utils/datacapture/datacapture';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';
import { getStorage, removeStorage, setStorage } from '@ulta/core/utils/storage/storage';
import { STORAGE_KEY } from '@ulta/core/utils/storageKeys/storageKeys';

import { SearchHeaderContext } from '@ulta/providers/SearchHeaderProvider/SearchHeaderProvider';

import SearchDesktop from '@ulta/components/SearchDesktop/SearchDesktop';
import SearchMobile from '@ulta/components/SearchMobile/SearchMobile';

import constants from '@ulta/utils/constants/constants';

import * as utils from './SearchHeader';

/**
 * Represents a SearchHeader component
 *
 * @method
 * @param {SearchHeaderProps} props - React properties passed from composition
 * @returns SearchHeader
 */

export const SearchHeader = React.forwardRef( ( props, _ ) => {
  const { typeaheadMinInputLength, previewDate, enablePersistentSearch } = useAppConfigContext();
  const {
    searchBar,
    invokeAction,
    loading,
    ariaLabel,
    screenReaderText,
    searchEvent: searchEventProp,
    clearRecentLabel,
    recentLabel,
    recentlyViewedLabel,
    clearAllRecentlyViewedLabel,
    resultsCountText,
    recentSearchLimit: maxRecentSearchesToDisplay = MAX_RECENT_SEARCHES_TO_DISPLAY,
    totalTrendingAndRecentSearchLimit: maxTrendingAndRecentSearchesToDisplay = MAX_TRENDING_AND_RECENT_TO_DISPLAY,
    componentKey,
    mediumDeviceSearchAction,
    smallDeviceSearchAction,
    recentlyViewedAction,
    recentlyViewedItems
  } = props;

  const { placeholderLabel: searchInputLabel, clearAccessibilityLabel, defaultSearchAction, clearAction } = searchBar || {};
  const { label: closePanel } = clearAction || {};
  const { suggestionsLabel, trendingLabel, suggestions = [], search: searchString } = { ...props.suggestions };
  const { topResultsLabel, popularProductsLabel, products = [], viewAllAction } = { ...props.firstTopResult };
  const typeAheadAction = props?.typeAheadAction;
  const { inflection } = useDeviceInflection();
  const isMobile = inflection && inflection.MOBILE;
  const [localSearchTerm, setLocalSearchTerm] = useState( searchString );
  const [savedSearchTerm, setSavedSearchTerm] = useState( searchString );
  const [recentSearches, setRecentSearches] = useState( [] );
  const [maxTrendingToDisplay, setMaxTrendingToDisplay] = useState( maxTrendingAndRecentSearchesToDisplay );

  const defaultSearchActionRef = useRef();
  const searchActionRef = useRef();

  // Keep searchEvent ref in sync
  const searchEvent = useRef( searchEventProp );
  useEffect( () => {
    searchEvent.current = searchEventProp;
  }, [searchEventProp] );

  // get recent searches and saved search term from local storage and update state
  // store our default search action in a ref in case we get no results from typeahead
  useEffect( () => {
    if( !defaultSearchActionRef.current ){
      defaultSearchActionRef.current = defaultSearchAction;
    }

    initRecentSearches();
    handleRedirectFromSearch();
    if( enablePersistentSearch ){
      handleRestoreSavedSearchTerm();
    }
  }, [enablePersistentSearch] );

  // ADA state
  const [isKeyBoardUser, setKeyBoardUser] = useState( false );

  // Compose action.after for typeahead action
  // -> handles setting searchActionRef.current if non null response from typeahead
  const handleTypeAheadActionAfter = useCallback(
    utils.composeTypeAheadActionAfter( { searchActionRef } ),
    []
  );

  // Compose invoke action handler
  // -> dispatches requests to DXL
  const debounceInput = useRef();
  const invokeTypeAheadAction = useCallback(
    utils.composeInvokeTypeAhead(
      { debounceInput, typeAheadAction, typeaheadMinInputLength, searchActionRef },
      { invokeAction, handleTypeAheadActionAfter }
    ),
    [handleTypeAheadActionAfter]
  );

  // Compose build recent searches with url
  // -> handles building recent search object with label and url
  const buildRecentSearchesWithUrl = useCallback(
    utils.composeBuildRecentSearchesWithUrl( { defaultSearchActionRef, searchParam: constants.SEARCH_PARAM } ),
    []
  );

  // Compose calculate max trending results to display
  // -> handles calculating max number of trending results to display based on (max trending and recent searches) - (recent searches) and updating state
  const updateMaxTrendingSuggestions = useCallback(
    utils.composeUpdateMaxTrendingSuggestions(
      { maxTrendingAndRecentSearchesToDisplay }, { setMaxTrendingToDisplay }
    ),
    []
  );

  // Compose handleCheckForRedirect
  // -> handles check for redirect and dispatches event to app config for search term
  const handleRedirectFromSearch = useCallback(
    utils.composeHandleRedirectFromSearch(
      {
        savedSearchTermKey: STORAGE_KEY.typeAheadSavedSearchTerm,
        redirectKey: STORAGE_KEY.typeAheadRedirect
      },
      { getStorage, removeStorage }
    ),
    []
  );

  // Compose init recent searches
  // -> handles getting recent searches from local storage and updating state
  const initRecentSearches = useCallback(
    utils.composeInitRecentSearches(
      {
        maxRecentSearchesToDisplay,
        maxTrendingAndRecentSearchesToDisplay,
        ultaRecentSearchesKey: STORAGE_KEY.typeAheadRecentSearches,
        searchParam: constants.SEARCH_PARAM
      },
      {
        getStorage,
        setStorage,
        setRecentSearches,
        buildRecentSearchesWithUrl,
        updateMaxTrendingSuggestions
      }
    ),
    []
  );

  // Compose add recent search handler
  // -> handles adding recent search term to local storage
  const handleAddRecentSearch = useCallback(
    utils.composeAddRecentSearch(
      { ultaRecentSearchesKey: STORAGE_KEY.typeAheadRecentSearches },
      { getStorage, setStorage }
    ),
    []
  );

  // Compose remove recent search handler
  // -> handles removing recent search term from local storage and updating state
  const handleRemoveRecentSearch = useCallback(
    utils.composeRemoveRecentSearch(
      { ultaRecentSearchesKey: STORAGE_KEY.typeAheadRecentSearches },
      {
        getStorage,
        setStorage,
        setRecentSearches,
        buildRecentSearchesWithUrl,
        updateMaxTrendingSuggestions
      }
    ),
    []
  );

  // Compose clear all recent search handler
  // -> handles clearing all recent search term from local storage and updating state
  const handleClearAllRecentSearches = useCallback(
    utils.composeClearAllRecentSearches(
      { ultaRecentSearchesKey: STORAGE_KEY.typeAheadRecentSearches },
      { setStorage, setRecentSearches, updateMaxTrendingSuggestions }
    ),
    []
  );

  // Compose clear all recently viewed handler
  // -> handles clearing all recently viewed items from local storage and updating state
  const handleClearAllRecentlyViewed = useCallback(
    utils.composeClearAllRecentlyViewed(
      { ultaRecentlyViewedKey: STORAGE_KEY.recentlyViewedSKUsForAutoSuggestion },
      { removeStorage }
    ),
    []
  );

  // Compose event handler
  // -> handles datacapture events onclick and onsubmit and store data into local storage
  const dataCaptureHandler = useCallback( utils.composeDataCaptureHandler( { searchEvent } ), [] );

  // Compose clear saved search term handler
  // -> handles clearing saved search term from local storage and updating state
  const handleClearSavedSearchTerm = useCallback(
    utils.composeClearSavedSearchTerm(
      { ultaSavedSearchTermKey: STORAGE_KEY.typeAheadSavedSearchTerm },
      { removeStorage, setSavedSearchTerm }
    ),
    []
  );

  // Compose input change handler
  // -> handles string sanitization and invoking when chars > 3
  const inputChangeHandler = useCallback(
    utils.composeInputChangeHandler(
      { typeaheadMinInputLength },
      { invokeTypeAheadAction, setSearchTerm: setLocalSearchTerm, handleClearSavedSearchTerm }
    ),
    []
  );

  // Compose save search term handler
  // -> handles saving search term in local storage
  const handleSaveSearchTerm = useCallback(
    utils.composeSaveSearchTerm(
      { ultaSavedSearchTermKey: STORAGE_KEY.typeAheadSavedSearchTerm },
      { setStorage }
    ),
    []
  );

  // Compose restore saved search term handler
  // -> handles restoring saved search term from local storage and updating state
  const handleRestoreSavedSearchTerm = useCallback(
    utils.composeRestoreSavedSearchTerm(
      { ultaSavedSearchTermKey: STORAGE_KEY.typeAheadSavedSearchTerm },
      { getStorage, setSavedSearchTerm, removeStorage }
    ),
    []
  );

  // Compose move cursor to end of input
  // -> handles moving cursor position to end of search input and scrolling cursor into view
  const moveCursorToEndOfInput = useCallback(
    utils.composeMoveCursorToEndOfInput(),
    []
  );

  // Compose submit handler
  // -> handles a keyword search, which is a forward to the PLP
  const handleSubmit = useCallback(
    utils.composeSubmit(
      { previewDate, defaultSearchActionRef, searchActionRef, redirectKey: STORAGE_KEY.typeAheadRedirect },
      { dataCaptureHandler, handleAddRecentSearch, handleSaveSearchTerm, setStorage }
    ),
    []
  );

  // Compose reset handler
  // -> handles 'x' button when user clears a search
  const resetAction = useCallback(
    utils.composeHandleResetAction( { invokeTypeAheadAction, setSearchTerm: setLocalSearchTerm, handleClearSavedSearchTerm } ),
    []
  );

  /**
   * We use a SearchHeader context to share callbacks and data between
   * different breakpoint based type ahead components.
   * - SearchXl
   * - SearchL
   * - SearchMobile
   */
  const context = {
    loading,
    viewAllAction,
    searchInputLabel,
    products,
    suggestions,
    suggestionsLabel,
    topResultsLabel,
    resultsCountText,
    popularProductsLabel,
    trendingLabel,
    isKeyBoardUser,
    searchString,
    localSearchTerm,
    savedSearchTerm,
    clearAccessibilityLabel,
    submitSearchLabel: defaultSearchActionRef?.current?.label,
    closePanel,
    ariaLabel,
    screenReaderText,
    clearRecentLabel,
    mediumDeviceSearchAction,
    smallDeviceSearchAction,
    recentLabel,
    recentSearches,
    recentlyViewedLabel,
    clearAllRecentlyViewedLabel,
    maxTrendingToDisplay,
    componentKey,
    handleAddRecentSearch,
    handleRemoveRecentSearch,
    handleClearAllRecentSearches,
    handleClearAllRecentlyViewed,
    handleSaveSearchTerm,
    handleRestoreSavedSearchTerm,
    moveCursorToEndOfInput,
    setKeyBoardUser,
    invokeTypeAheadAction,
    inputChangeHandler,
    resetAction,
    handleSubmit,
    dataCaptureHandler,
    recentlyViewedAction,
    recentlyViewedItems
  };

  if( !searchBar ){
    return null;
  }

  return (
    <SearchHeaderContext.Provider value={ context }>
      <li className='SearchHeader'>
        { isMobile ? <SearchMobile /> : <SearchDesktop /> }
      </li>
    </SearchHeaderContext.Provider>
  );
} );

/**
 * Method to handle on submit
 * @param {object} data args
 * @param {string} data.searchActionRef ref for defaultSearchAction returned from initial DXL response
 * @param {string} data.defaultSearchActionRef ref for searchAction returned from typeAhead
 * @param {string} data.previewDate from appConfig
 * @param {string} values input text
 */
export const composeSubmit = ( data, methods ) => async( values ) => {
  const { previewDate, searchActionRef, defaultSearchActionRef, redirectKey } = data || {};
  const { dataCaptureHandler, handleAddRecentSearch, handleSaveSearchTerm, setStorage } = methods || {};

  if( !defaultSearchActionRef?.current || !values?.search || !values.search.replace( /\s/g, '' ) ){
    return;
  }

  // Data capture call
  const searchTerm = values.search;
  const searchType = TYPEAHEAD_SEARCH_TYPE.Keyword;
  dataCaptureHandler( { searchTerm, searchType } );

  // set search term in local storage as recent search
  handleAddRecentSearch( searchTerm );

  // save search term in local storage
  handleSaveSearchTerm( searchTerm );

  const action = searchActionRef.current ? searchActionRef.current : defaultSearchActionRef.current;

  // Forward to search results page
  const url = new URL( action.url );

  if( action?.params?.includes( constants.SEARCH_PARAM ) ){
    url.searchParams.append( constants.SEARCH_PARAM, values.search );
  }
  else {
    // set search term for single hop redirect when we get back a redirect URL from typeahead
    setStorage( {
      key: redirectKey, secure: false, value: { isRedirect: true }
    } );
  }

  handleRedirect( { url: url.toString(), previewDate } );
};

/**
 * Method to handle typeAhead action.after callback
 * @param {object} data args
 * @param {string} data.searchActionRef ref for searchAction returned from typeAhead
 * @param {string} inputData args
 * @param {string} inputData.content data from Layerhost passed to action.after callback
 */
export const composeTypeAheadActionAfter = ( data ) => ( inputData ) => {
  const { searchActionRef } = data || {};
  const { content } = inputData || {};
  const searchAction = content?.searchBar?.searchAction;

  if( !searchActionRef || !searchAction ){
    return;
  }

  searchActionRef.current = searchAction;
};

/**
 * Method to handle updating app config redirect
 * @param {object} data args
 * @param {string} data.searchActionRef ref for searchAction returned from typeAhead
 * @param {string} inputData args
 * @param {string} inputData.content data from Layerhost passed to action.after callback
 */
export const composeHandleRedirectFromSearch = ( data, methods ) => () => {
  const { savedSearchTermKey, redirectKey } = data || {};
  const { getStorage, removeStorage } = methods || {};

  if( !getStorage || !savedSearchTermKey || !redirectKey || !removeStorage ){
    return;
  }

  const isRedirect = getStorage( { key: redirectKey, secure: false } )?.isRedirect;

  if( isRedirect ){
    const searchTerm = getStorage( { secure: false, key: savedSearchTermKey } )?.searchTerm;

    removeStorage( { key: redirectKey, secure: false } );

    if( searchTerm ){
      global.document.dispatchEvent(
        new CustomEvent( constants.EVENTS.UPDATE_APP_CONFIG, {
          detail: {
            config: {
              redirectedSearchTerm: searchTerm,
              isRedirect: true
            }
          }
        } )
      );
    }
  }
};

/**
 * Method to handle DXL calls based on Input/searchTerm
 * @param {object} data args
 * @param {action} data.typeAheadAction DXL action to get data
 * @param {action} data.searchActionRef ref for searchAction returned from typeAhead
 * @param {object} methods methods
 * @param {methods} methods.invokeAction invokeAction
 * @param {methods} methods.handleTypeAheadActionAfter handles typeAhead action.after callback
 * @param {string} searchTerm searchTerm/input
 */
export const composeInvokeTypeAhead = ( data, methods ) => ( searchTerm ) => {
  const { typeAheadAction, typeaheadMinInputLength, debounceInput = {}, searchActionRef } = handleEmptyObjects( data );
  const { invokeAction, handleTypeAheadActionAfter } = methods || {};
  const { graphql, customHeaders, params, method } = typeAheadAction || {};

  if( !invokeAction || !handleTypeAheadActionAfter || !graphql || !searchActionRef ){
    return;
  }

  searchActionRef.current = null;

  clearTimeout( debounceInput.current );
  debounceInput.current = setTimeout( () => {
    const searchAction = {
      after: handleTypeAheadActionAfter,
      cachePath: constants.SEARCH_HEADER_PATH,
      graphql,
      method,
      customHeaders,
      params,
      variables: {
        moduleParams: {}
      }
    };

    if( searchTerm?.trim().length >= typeaheadMinInputLength ){
      searchAction.variables.moduleParams.search = searchTerm;
    }

    invokeAction( searchAction );
  }, 150 );
};

/**
 * Method to handle building recent searches with label and url
 * @param {object} data args
 * @param {action} data.searchActionUrl page url to redirect
 * @param {action} data.searchParam constants.SEARCH_PARAM
 * @param {array} localRecentSearches recent searches from local storage
 */
export const composeBuildRecentSearchesWithUrl = ( data ) => ( localRecentSearches ) => {
  const { defaultSearchActionRef, searchParam } = data || {};
  const searchActionUrl = defaultSearchActionRef?.current?.url;

  if( !searchActionUrl || !searchParam || !localRecentSearches ){
    return;
  }

  const recentSearchesWithUrl = localRecentSearches.map( recentSearch => {
    const url = new URL( searchActionUrl );
    url.searchParams.append( searchParam, recentSearch );

    return {
      label: recentSearch,
      url
    };
  } );

  return recentSearchesWithUrl;
};

/**
 * Method to handle calculating max trending suggestions to display and updating state
 * @param {object} data args
 * @param {string} data.maxTrendingAndRecentSearchesToDisplay max number of trending and recent searches to display
 * @param {object} methods methods
 * @param {action} methods.setMaxTrendingToDisplay set max number of trending suggestions in state
 * @param {number} numOfRecentSearches number of recent searches currently displayed
 */
export const composeUpdateMaxTrendingSuggestions = ( data, methods ) => ( numOfRecentSearches = 0 ) => {
  const { maxTrendingAndRecentSearchesToDisplay } = data || {};
  const { setMaxTrendingToDisplay } = methods || {};

  if( maxTrendingAndRecentSearchesToDisplay <= 0 || !setMaxTrendingToDisplay ){
    return;
  }

  const maxTrending = maxTrendingAndRecentSearchesToDisplay - numOfRecentSearches;

  setMaxTrendingToDisplay( maxTrending );
};

/**
 * Method to handle getting recent searches from local storage, updating local storage and setting in state
 * @param {object} data args
 * @param {string} data.maxRecentSearchesToDisplay max number of recent searches to display
 * @param {string} data.maxTrendingAndRecentSearchesToDisplay max number of trending and recent searches to display
 * @param {string} data.ultaRecentSearchesKey recent searches key for local storage
 * @param {object} methods methods
 * @param {action} methods.getStorage get key from local storage
 * @param {action} methods.setStorage set data for key in local storage
 * @param {action} methods.setRecentSearches set recent searches in state
 * @param {action} methods.buildRecentSearchesWithUrl build list of recent searches with label and url
 * @param {action} methods.updateMaxTrendingSuggestions calculate max number of trending suggestions to display based on current num of recent searches and set in state
 */
export const composeInitRecentSearches = ( data, methods ) => () => {
  const { maxRecentSearchesToDisplay, maxTrendingAndRecentSearchesToDisplay, ultaRecentSearchesKey } = data || {};
  const { getStorage, setStorage, setRecentSearches, buildRecentSearchesWithUrl, updateMaxTrendingSuggestions } = methods || {};

  if(
    !getStorage ||
    !setStorage ||
    !setRecentSearches ||
    !buildRecentSearchesWithUrl ||
    !updateMaxTrendingSuggestions ||
    maxRecentSearchesToDisplay <= 0 ||
    maxTrendingAndRecentSearchesToDisplay <= 0 ||
    !ultaRecentSearchesKey
  ){
    return;
  }

  let recentSearches = getStorage(
    { secure: false, key: ultaRecentSearchesKey }
  )?.recentSearches || [];
  const numOfRecents = recentSearches?.length;
  const maxToDisplay = maxRecentSearchesToDisplay > maxTrendingAndRecentSearchesToDisplay ?
    maxTrendingAndRecentSearchesToDisplay : maxRecentSearchesToDisplay;

  if( numOfRecents > maxToDisplay ){
    recentSearches = recentSearches.slice( 0, maxToDisplay );
    setStorage( { secure: false, key: ultaRecentSearchesKey, value: { recentSearches } } );
  }

  const recentSearchesWithUrl = buildRecentSearchesWithUrl( recentSearches );

  updateMaxTrendingSuggestions( recentSearchesWithUrl?.length );
  setRecentSearches( recentSearchesWithUrl );
};

/**
 * Method to handle adding recent search to local storage
 * @param {object} data args
 * @param {string} data.ultaRecentSearchesKey recent searches key for local storage
 * @param {object} methods methods
 * @param {action} methods.getStorage get key from local storage
 * @param {action} methods.setStorage set data for key in local storage
 * @param {string} searchTerm searchTerm / input value
 */
export const composeAddRecentSearch = ( data, methods ) => ( searchTerm ) => {
  const { ultaRecentSearchesKey } = data || {};
  const { getStorage, setStorage } = methods || {};

  if( !getStorage || !setStorage || !ultaRecentSearchesKey || !searchTerm || !searchTerm.replace( /\s/g, '' ) ){
    return;
  }

  let recentSearches = getStorage(
    { secure: false, key: ultaRecentSearchesKey }
  )?.recentSearches || [];
  const trimmedSearchTerm = searchTerm.trim();

  recentSearches = recentSearches.filter( s => s.toLowerCase() !== trimmedSearchTerm.toLowerCase() );
  recentSearches.unshift( trimmedSearchTerm );
  setStorage( { secure: false, key: ultaRecentSearchesKey, value: { recentSearches } } );
};

/**
 * Method to handle removing recent search from local storage
 * @param {object} data args
 * @param {string} data.ultaRecentSearchesKey recent searches key for local storage
 * @param {object} methods methods
 * @param {action} methods.getStorage get key from local storage
 * @param {action} methods.setStorage set data for key in local storage
 * @param {action} methods.setRecentSearches set recent searches in state
 * @param {action} methods.buildRecentSearchesWithUrl build list of recent searches with label and url
 * @param {action} methods.updateMaxTrendingSuggestions calculate max number of trending suggestions to display based on current num of recent searches and set in state
 * @param {string} searchTerm searchTerm / input value
 */
export const composeRemoveRecentSearch = ( data, methods ) => ( searchTerm ) => {
  const { ultaRecentSearchesKey } = data || {};
  const {
    getStorage,
    setStorage,
    setRecentSearches,
    buildRecentSearchesWithUrl,
    updateMaxTrendingSuggestions
  } = methods || {};

  if( !getStorage ||
      !setStorage ||
      !buildRecentSearchesWithUrl ||
      !updateMaxTrendingSuggestions ||
      !ultaRecentSearchesKey ||
      !searchTerm ||
      !searchTerm.replace( /\s/g, '' ) ){
    return;
  }

  let recentSearches = getStorage(
    { secure: false, key: ultaRecentSearchesKey }
  )?.recentSearches || [];
  const trimmedSearchTerm = searchTerm.trim();

  recentSearches = recentSearches.filter( s => s.toLowerCase() !== trimmedSearchTerm.toLowerCase() );

  const recentSearchesWithUrl = buildRecentSearchesWithUrl( recentSearches );

  setStorage(
    { secure: false, key: ultaRecentSearchesKey, value: { recentSearches } }
  );
  updateMaxTrendingSuggestions( recentSearchesWithUrl?.length );
  setRecentSearches( recentSearchesWithUrl );
};

/**
 * Method to handle clearing all recent searches from local storage
 * @param {object} data args
 * @param {string} data.ultaRecentSearchesKey recent searches key for local storage
 * @param {object} methods methods
 * @param {action} methods.setStorage set data for key in local storage
 * @param {action} methods.setRecentSearches set recent searches in state
 * @param {action} methods.updateMaxTrendingSuggestions calculate max number of trending suggestions to display based on current num of recent searches and set in state
 * @param {string} input input value
 */
export const composeClearAllRecentSearches = ( data, methods ) => () => {
  const { ultaRecentSearchesKey } = data || {};
  const { setStorage, setRecentSearches, updateMaxTrendingSuggestions } = methods || {};

  if( !setStorage || !setRecentSearches || !updateMaxTrendingSuggestions || !ultaRecentSearchesKey ){
    return;
  }

  setStorage(
    { secure: false, key: ultaRecentSearchesKey, value: { recentSearches: [] } }
  );
  updateMaxTrendingSuggestions( 0 );
  setRecentSearches( [] );
};

/**
 * Method to handle clearing all recently viewed from local storage
 * @param {object} data args
 * @param {string} data.ultaRecentlyViewedKey recently viewed key for local storage
 * @param {object} methods methods
 * @param {action} methods.setStorage set data for key in local storage
 * @param {action} methods.setRecentlyViewed set recently viewed in state
 * @param {string} input input value
 */
export const composeClearAllRecentlyViewed = ( data, methods ) => () => {
  const { ultaRecentlyViewedKey } = data || {};
  const { removeStorage } = methods || {};

  if( !removeStorage || !ultaRecentlyViewedKey ){
    return;
  }

  removeStorage(
    { secure: false, key: ultaRecentlyViewedKey }
  );
};

/**
 * Method to handle moving cursor position to end of input and scrolling cursor into view
 * @param {object} inputRef search input ref
 */
export const composeMoveCursorToEndOfInput = () => ( inputRef ) => {
  if( !inputRef?.current ){
    return;
  }

  const ref = inputRef;
  const inputValue = ref.current.value;
  const cursorPosition = inputValue.length;

  ref.current.value = '';
  setTimeout( () => {
    if( ref?.current ){
      ref.current.value = inputValue;
      ref.current.setSelectionRange( cursorPosition, cursorPosition );
      ref.current.scrollLeft = ref.current.scrollWidth;
    }
  }, 100 );
};

/**
 * Method to handle saving search term to local storage
 * @param {object} data args
 * @param {string} data.ultaSavedSearchTermKey saved search term key for local storage
 * @param {object} methods methods
 * @param {action} methods.setStorage get key from local storage
 * @param {string} searchTerm searchTerm / input value
 */
export const composeSaveSearchTerm = ( data, methods ) => ( searchTerm ) => {
  const { ultaSavedSearchTermKey } = data || {};
  const { setStorage } = methods || {};

  if( !setStorage || !ultaSavedSearchTermKey || !searchTerm || !searchTerm.replace( /\s/g, '' ) ){
    return;
  }

  setStorage( { secure: false, key: ultaSavedSearchTermKey, value: { searchTerm } } );
};

/**
 * Method to handle restoring saved search term from local storage
 * @param {object} data args
 * @param {string} data.ultaSavedSearchTermKey saved search term key for local storage
 * @param {object} methods methods
 * @param {action} methods.getStorage get key from local storage
 * @param {action} methods.setSavedSearchTerm handle update state for savedSearchTerm
 * @param {string} searchTerm searchTerm / input value
 */
export const composeRestoreSavedSearchTerm = ( data, methods ) => () => {
  const { ultaSavedSearchTermKey } = data || {};
  const { getStorage, setSavedSearchTerm, removeStorage } = methods || {};

  if( !getStorage || !ultaSavedSearchTermKey || !setSavedSearchTerm || !removeStorage ){
    return;
  }

  const savedSearchTerm = getStorage( { secure: false, key: ultaSavedSearchTermKey } );

  if( savedSearchTerm?.searchTerm ){
    const searchTerm = savedSearchTerm.searchTerm;

    setSavedSearchTerm( searchTerm );
    removeStorage( { secure: false, key: ultaSavedSearchTermKey } );
  }
};

/**
 * Method to handle clearing saved search term from local storage
 * @param {object} data args
 * @param {string} data.ultaSavedSearchTermKey saved search term key for local storage
 * @param {object} methods methods
 * @param {action} methods.removeStorage remove key from local storage
 * @param {string} searchTerm searchTerm / input value
 */
export const composeClearSavedSearchTerm = ( data, methods ) => () => {
  const { ultaSavedSearchTermKey } = data || {};
  const { removeStorage, setSavedSearchTerm } = methods || {};

  if( !removeStorage || !ultaSavedSearchTermKey || !setSavedSearchTerm ){
    return;
  }

  removeStorage( { secure: false, key: ultaSavedSearchTermKey } );
  setSavedSearchTerm( '' );
};

/**
 * Method to handle DXL calls based on Inputchange/searchTermchange
 * @param {object} methods methods
 * @param {action} methods.invokeTypeAheadAction Execute DXL query
 * @param {string} input input value
 */
export const composeInputChangeHandler = ( data, methods ) => ( input ) => {
  const { typeaheadMinInputLength } = data || {};
  const { invokeTypeAheadAction, setSearchTerm, handleClearSavedSearchTerm } = methods || {};

  if( !invokeTypeAheadAction || !setSearchTerm || !handleClearSavedSearchTerm ){
    return;
  }

  setSearchTerm( input );

  if( input?.length >= typeaheadMinInputLength ){
    invokeTypeAheadAction( input );
  }
  else {
    if( input?.length === 0 ){
      handleClearSavedSearchTerm();
    }

    invokeTypeAheadAction();
  }
};

/**
 * Method to capture Datacapture events
 * @param {object} data args
 * @param {action} data.searchEvent search action
 * @param {object} eventData datacapture data for click and submit events
 */
export const composeDataCaptureHandler = ( data ) => ( eventData ) => {
  const { searchEvent } = data || {};
  const { searchTerm, searchType, searchVisualIndex, targetUrl } = eventData || {};

  const typeAheadData = { searchTerm, searchType };

  if( searchVisualIndex >= 0 ){
    typeAheadData.searchVisualIndex = String( searchVisualIndex );
  }

  removeStorage( { secure: false, key: STORAGE_KEY.typeAheadDatacapture } );

  datacapture.emitTypeAheadData( {
    targetUrl,
    typeAheadData,
    searchEvent: searchEvent?.current
  } );
};

/**
 * Method to handle reset action
 * @param {object} methods methods
 * @param {action} methods.invokeTypeAheadAction Execute DXL query
 */
export const composeHandleResetAction = ( methods ) => () => {
  const { invokeTypeAheadAction, setSearchTerm, handleClearSavedSearchTerm } = methods || {};

  if( !invokeTypeAheadAction || !setSearchTerm || !handleClearSavedSearchTerm ){
    return;
  }

  invokeTypeAheadAction();
  handleClearSavedSearchTerm();
  setSearchTerm( '' );
};

/**
 * @const {number} MAX_RECENT_SEARCHES_TO_DISPLAY - default for maxRecentSearchesToDisplay
 */
export const MAX_RECENT_SEARCHES_TO_DISPLAY = 4;

/**
 * @const {number} MAX_TRENDING_AND_RECENT_TO_DISPLAY - default for maxTrendingAndRecentSearchesToDisplay
 */
export const MAX_TRENDING_AND_RECENT_TO_DISPLAY = 10;

/**
 * @const {object} TYPEAHEAD_SEARCH_TYPE - Search type from DXL
 */
export const TYPEAHEAD_SEARCH_TYPE = {
  PopularProduct: 'popular products',
  Trending: 'trending',
  Suggestions: 'suggestions',
  Keyword: 'keyword',
  Recents: 'recent searches',
  RecentlyViewed: 'recently viewed'
};

/**
 * property type definitions
 * @typedef SearchHeaderProps
 * @type object
 * @property {object} firstTopResult - Object contains product details and labels
 * @property {array} products - This is array of products
 * @property {object} viewAllAction - This holds the detaila about view all link like text and url
 * @property {string} popularProductsLabel - This is popularProductsLabel for current products
 * @property {string} topResultsLabel - This is topResultsLabel for current top products
 * @property {boolean} loading - sets true or false
 * @property {string} searchInputLabel - sets the placeholder for search
 * @property {object} suggestions - Object contains trending and suggestion items
 * @property {array} suggestions - This is array of suggestions
 * @property {string} searchString - This is searchString value i.e input
 * @property {string} suggestionsLabel - This is suggestion label for current suggestions
 * @property {string} trendingLabel - This is trending label for current trending
 * @property {object} searchBar - object contains keyword actions
 * @property {object} searchEvent - object contains data capture event
 * @property {string} ariaLabel - sets ariaLabel value for product search
 * @property {string} screenReaderText - sets sr-only text for keyboard user
 * @property {string} clearRecentLabel - This is recent searches clear all label
 * @property {string} recentLabel - This is recent searches label for current recent searches
 * @property {string} resultsCountText - This is ada label for product list results count
 * @property {number} recentSearchLimit - This is the max number of recent searches to display
 * @property {number} totalTrendingAndRecentSearchLimit - This is the max number of trending and recent searches to display
 * @property {string} componentKey - provided by LayerHost, timestamp of the last time the component was updated
 */
export const propTypes = {
  suggestions: PropTypes.shape( {
    searchString: PropTypes.string,
    suggestions: PropTypes.arrayOf(
      PropTypes.shape( {
        label: PropTypes.string,
        hoverAction: PropTypes.object,
        linkAction: PropTypes.object,
        topResults: PropTypes.shape( {
          products: PropTypes.arrayOf(
            PropTypes.shape( {
              brandName: PropTypes.string,
              productName: PropTypes.string,
              variantImage: PropTypes.shape( {
                imageUrl: PropTypes.string,
                name: PropTypes.string
              } )
            } )
          )
        } )
      } )
    ),
    suggestionsLabel: PropTypes.string,
    trendingLabel: PropTypes.string
  } ),
  firstTopResult: PropTypes.shape( {
    popularProductsLabel: PropTypes.string,
    topResultsLabel: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape( {
        brandName: PropTypes.string,
        productName: PropTypes.string,
        variantImage: PropTypes.shape( {
          imageUrl: PropTypes.string,
          name: PropTypes.string
        } )
      } )
    ),
    viewAllAction: PropTypes.shape( {
      label: PropTypes.string,
      url: PropTypes.string
    } )
  } ),
  loading: PropTypes.bool,
  searchBar: PropTypes.object,
  searchEvent: PropTypes.object,
  ariaLabel: PropTypes.string,
  screenReaderText: PropTypes.string,
  clearRecentLabel: PropTypes.string,
  recentLabel: PropTypes.string,
  resultsCountText: PropTypes.string,
  recentSearchLimit: PropTypes.number,
  totalTrendingAndRecentSearchLimit: PropTypes.number,
  componentKey: PropTypes.string,
  recentlyViewedLabel : PropTypes.string,
  clearAllRecentlyViewedLabel: PropTypes.string
};

SearchHeader.propTypes = propTypes;
SearchHeader.displayName = 'SearchHeader';

export default React.memo( SearchHeader );
