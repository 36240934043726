/**
 * Provides context for device inflection (mobile/desktop) and breakpoint information.
 * This allows components to adapt their layout and behavior based on the current device.
 *
 * @module core/providers/InflectionProvider/InflectionProvider
 */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { getCurrentBreakpoint, getDeviceInflection, isMobileDisplay } from '../../utils/device_detection/device_detection';
import * as deviceDetection from '../../utils/device_detection/device_detection';

/**
 * Inflection provider component.
 * Wraps its children and provides inflection and breakpoint information via context.
 *
 * @param {object} props - Component props.
 * @param {ReactNode} props.children - Child components to be rendered within the provider's scope.
 * @param {string} [props.baseInflection] - Initial inflection (e.g., 'Desktop', 'Mobile'). Defaults to 'Desktop'.
 * @returns {JSX.Element} The InflectionProvider component.
 */
export const InflectionProvider = ( { children, baseInflection } ) => {
  /**
     * State for the current inflection.
     */
  const [inflection, setInflection] = useState( baseInflection );
  /**
     * State for the current breakpoint.
     */
  const [breakpoint, setBreakpoint] = useState( getCurrentBreakpoint( baseInflection ) );
  /**
     * Ref for the active timer.
     */
  const resizeTimer = useRef( null );

  /**
     * Memoized inflection response object.
     */
  const inflectionResponse = useMemo( () => getInflectionResponse( inflection ), [inflection] );

  /**
     * Memoized breakpoint response object.
     */
  const breakpointResponse = useMemo( () => getBreakpointResponse( breakpoint ), [breakpoint] );

  /**
     * Updates the inflection and breakpoint based on device detection.
     *
     * @param {string} currentInflection - The current inflection value.
     * @param {string} currentBreakpoint - The current breakpoint value.
     */
  const updateInflection = useCallback( ( currentInflection, currentBreakpoint ) => {
    const newInflection = deviceDetection.getDeviceInflection( isMobileDisplay() );
    const newBreakpoint = deviceDetection.getCurrentBreakpoint();

    if( currentInflection !== newInflection ){
      setInflection( newInflection.toUpperCase() );
    }

    if( currentBreakpoint !== newBreakpoint ){
      setBreakpoint( newBreakpoint.toUpperCase() );
    }
  }, [] );

  /**
     * Debounced resize handler to prevent excessive updates.
     */
  const debouncedHandleResize = useCallback( () => {
    if (resizeTimer.current) {
      clearTimeout( resizeTimer.current );
    }
    resizeTimer.current = setTimeout( () => {
      updateInflection( inflection, breakpoint );
    }, 100 );
  }, [inflection, breakpoint, updateInflection] );

  useEffect( () => {
    global.addEventListener( 'resize', debouncedHandleResize );
    return () => global.removeEventListener( 'resize', debouncedHandleResize );
  }, [debouncedHandleResize] );

  /**
     * Memoized context value.
     */
  const contextValue = useMemo( () => ( {
    inflection: inflectionResponse,
    breakpoint: breakpointResponse
  } ), [inflectionResponse, breakpointResponse] );


  return (
    <InflectionContext.Provider value={ contextValue }>
      { children }
    </InflectionContext.Provider>
  );
};

/**
 * PropTypes for InflectionProvider.
 * @type {object}
 */
export const propTypes = {
  inflection: PropTypes.string,
  children: PropTypes.node.isRequired // Added children as required
};

/**
 * Default props for InflectionProvider.
 * @type {object}
 */
export const defaultProps = {
  inflection: 'Desktop'
};

/**
 * Creates an inflection response object.
 *
 * @param {string} [inflection=''] The current inflection.
 * @returns {object} An object indicating mobile/desktop state.
 */
export const getInflectionResponse = ( inflection = '' ) => ( {
  MOBILE: inflection?.toUpperCase() === 'MOBILE',
  DESKTOP: inflection?.toUpperCase() === 'DESKTOP',
  TYPE: inflection?.toUpperCase() === 'MOBILE' ? 'mobile' : 'desktop'
} );

/**
 * Creates a breakpoint response object.
 *
 * @param {string} [inputBreakpoint=''] The current breakpoint.
 * @returns {object} An object with breakpoint information and helper functions.
 */
export const getBreakpointResponse = ( inputBreakpoint = '' ) => {
  const breakpoint = inputBreakpoint.toUpperCase();
  return {
    BREAKPOINTS: initialState.BREAKPOINTS,
    CURRENT_BREAKPOINT: breakpoint,
    isSmallDevice: () => breakpoint === 'SM',
    isMediumDevice: () => breakpoint === 'MD',
    isLargeDevice: () => breakpoint === 'LG' || breakpoint === 'XL'
  };
};

/**
 * Initial state for the inflection context.
 * @type {object}
 * @property {boolean} MOBILE - Initial mobile inflection state.
 * @property {boolean} DESKTOP - Initial desktop inflection state.
 * @property {Array<string>} BREAKPOINTS - Supported breakpoints.
 * @property {string} CURRENT_BREAKPOINT - Initial breakpoint.
 */
export const initialState = {
  MOBILE: false,
  DESKTOP: false,
  BREAKPOINTS: ['SM', 'MD', 'LG', 'XL'],
  CURRENT_BREAKPOINT: ''
};

/**
 * Context for device inflection and breakpoint.
 */
const InflectionContext = createContext( initialState );

/**
 * Hook to access device inflection and breakpoint information.
 *
 * @returns {object} An object containing inflection and breakpoint data.
 */
export const useDeviceInflection = () => useContext( InflectionContext );

InflectionProvider.propTypes = propTypes;
InflectionProvider.defaultProps = defaultProps;

export default InflectionProvider;
