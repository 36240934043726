import { useEffect, useRef } from 'react';

import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { getGeoLocation } from '@ulta/core/utils/getGeoLocation/getGeoLocation';

import { getRequiredClientParams } from '../useLayerHostAction/useLayerHostAction';
import * as utils from './useStorePersistAction';

export const useStorePersistAction = ( data ) => {

  const {
    props,
    storePersistAction,
    invokeAction
  } = data;
  const { store, user } = useUserContext();

  const hasProcessedStorePersistAction = useRef( false );

  const { stagingHost, previewDate } = useAppConfigContext();
  const { breakpoint: { CURRENT_BREAKPOINT } = {} } = useDeviceInflection();
  const location = global.location;


  useEffect( () => {
    if( hasProcessedStorePersistAction.current || !storePersistAction || store ){
      return;
    }

    hasProcessedStorePersistAction.current = true;

    const request = {
      latitude: '',
      longitude: '',
      user,
      props,
      location,
      action: storePersistAction,
      breakpoint: CURRENT_BREAKPOINT,
      stagingHost,
      previewDate
    };

    let action = utils.getAction( request );
    invokeAction( action );
    utils.processGeoLocation( { request }, { invokeAction } );
  }, [storePersistAction] );
};

export const processGeoLocation = async( data, methods ) => {
  const { request } = data;
  const { invokeAction } = methods;
  const location = await getGeoLocation();
  const { coords } = location;
  const { latitude, longitude } = coords;
  let action = utils.getAction( { ...request, latitude, longitude } );
  invokeAction( action );
};

export const getAction = ( data ) => {
  const { latitude = '', longitude = '' } = data;

  let action = getRequiredClientParams( data );

  action.variables.moduleParams = {
    ...action.variables.moduleParams,
    lat: latitude,
    long: longitude
  };

  return action;
};
