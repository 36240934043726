/**
 * The vertical slot is used to render scheduled content from the Amplience definitions. The content is stacked vertically thus the name vertical.
 *
 * @module views/components/VerticalSlot
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';

import AlignmentContainer from '@ulta/core/components/AlignmentContainer/AlignmentContainer';
import Modules from '@ulta/core/components/Modules/Modules';
import { hasItems } from '@ulta/core/utils/array/array';

/**
 * Represents a VerticalSlot component
 *
 * @method
 * @param {VerticalSlotProps} props - React properties passed from composition
 * @returns VerticalSlot
 */
export const VerticalSlot = function( props ){
  const {
    modules,
    styleConfigs
  } = props;

  if( !hasItems( modules ) ){
    return null;
  }

  const hasAlignmentProps = ( !!styleConfigs );

  return (
    <div className='VerticalSlot'>
      { hasAlignmentProps &&
        <AlignmentContainer
          styleConfigs={ styleConfigs }
        >
          <Modules
            modules={ modules }
          />
        </AlignmentContainer>
      }
      { !hasAlignmentProps &&
        <Modules
          modules={ modules }
        />
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef VerticalSlotProps
 * @type {object}
 * @property {array} modules - Array of modules (components) in this slot
 * @property {object} styleConfigs - The object of settings (alignment, backgroundColor, padding) for each breakpoint
 */
export const propTypes =  {
  /** Array of modules (components) in this slot */
  modules: PropTypes.array,
  /** The object of settings (alignment, backgroundColor, padding) for each breakpoint */
  styleConfigs: PropTypes.object
};


VerticalSlot.propTypes = propTypes;

export default VerticalSlot;
