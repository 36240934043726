/**
* Represents a compact card component to create banner
*
* @module views/components/PromotedCardMini
* @memberof -Common
*/
import './PromotedCardMini.scss';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import Tooltip from '@ulta/core/components/Tooltip/Tooltip';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import { constants } from '@ulta/core/utils/constants/constants';
import { isEmptyObject } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleIntersection } from '@ulta/core/utils/intersectionProcessor/intersectionProcessor';
/**
* Represents a PromotedCardMini component
*
* @method
* @param {PromotedCardMiniProps} props - React properties passed from composition
* @returns PromotedCardMini
*/
export const PromotedCardMini = function( props ){
  const {
    action,
    actionIconName,
    backgroundColor,
    displayAsMobile,
    headline,
    headlineColor,
    headlineType,
    iconName,
    onCtaClick,
    subheadline,
    toolTip,
    secondaryAction,
    root,
    rootMargin,
    threshold
  } = props;

  const componentRef = useRef();
  useIntersectionObserver(
    componentRef,
    {
      root: root,
      rootMargin: rootMargin,
      threshold: threshold
    },
    handleIntersection( props )
  );
  const hasAction = !isEmptyObject( action ) && !!action.label;
  const hasSecondaryAction = !!secondaryAction?.label;
  const hasLinkAction = action?.navigationType === DXL_NAVIGATION_TYPE.Redirect;
  const hasButtonAction = hasAction && !hasLinkAction;
  return (
    <div
      className={ classNames( 'PromotedCardMini', {
        [`PromotedCardMini--${backgroundColor}`]: backgroundColor
      } ) }
      ref={ componentRef }
    >
      <div
        className={ classNames( 'PromotedCardMini__Content', {
          'PromotedCardMini__Content--withIconWithAction': iconName && hasAction,
          'PromotedCardMini__Content--noIconWithAction': !iconName && hasAction,
          'PromotedCardMini__Content--withIconNoAction': iconName && !hasAction,
          'PromotedCardMini__Content--noIconNoAction': !iconName && !hasAction,
          'PromotedCardMini__Content--column': displayAsMobile
        } ) }
      >
        { iconName &&
        <div className='PromotedCardMini__Icon'>
          <Icon name={ iconName }
            aria-hidden={ true }
          />
        </div>
        }
        <div className='PromotedCardMini__ContentWrapper'>
          <div className='PromotedCardMini__HeadlineWrapper'>
            <div className='PromotedCardMini__headerContainer'>
              <div className='PromotedCardMini__Headline'>
                <Text htmlTag={ headlineType }
                  textStyle='body-1'
                  color={ headlineColor }
                >
                  { headline }
                </Text>
                { toolTip?.message &&
                  <Tooltip { ...toolTip } />
                }
              </div>
              <div className='PromotedCardMini__subHeadline'>
                <Text htmlTag='p'
                  textStyle='body-2'
                  textAlign='left'
                  color='neutral-800'
                >
                  { subheadline }
                </Text>
              </div>
            </div>
            { hasSecondaryAction &&
              <div className='PromotedCardMini__helperLabel'>
                <Link_Huge
                  secondary
                  action={ secondaryAction }
                >
                  { secondaryAction.label }
                </Link_Huge>
              </div>
            }
          </div>
          { hasAction && (
            <div className='PromotedCardMini__Action'>
              { hasLinkAction && (
                <Link_Huge
                  compact
                  action={ action }
                  { ... ( onCtaClick && { onClick: onCtaClick } ) }
                >
                  { action.label }
                </Link_Huge>
              ) }

              { hasButtonAction && (
                <Button
                  tertiary
                  withHover
                  tiny
                  label={ action.label }
                  iconImage={ actionIconName }
                  iconRight
                  iconSize='s'
                  alignment='right'
                  action={ action }
                  { ... ( onCtaClick && { onClick: onCtaClick } ) }
                  crossButtonVisibility={ true }
                />
              ) }
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

/**
* Property type definitions
*
* @typedef PromotedCardMiniProps
* @type {object}
* @property {string} headline - Sets the headline properties
* @property {string} headlineType - Sets the headlineType
* @property {string} backgroundColor - Sets the backgroundColor
* @property {string} subheadline - Sets the subheadline
* @property {object} action - Sets the action properties
* @property {string} headlineColor - Sets the headlineColor
* @property {string} iconName - Sets iconName
* @property {string} actionIconName - Sets actionIconName
* @property {function} onCtaClick - CTA click handler
* @property {object} secondaryAction - Sets the action properties
*/
export const propTypes = {
  headline: PropTypes.oneOfType( [PropTypes.string, PropTypes.element] ),
  headlineType: PropTypes.string,
  backgroundColor: PropTypes.string,
  subheadline: PropTypes.string,
  headlineColor: PropTypes.string,
  iconName: PropTypes.string,
  action: PropTypes.object,
  actionIconName: PropTypes.string,
  onCtaClick: PropTypes.func,
  secondaryAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string
  } )
};

/**
* Default values for passed properties
* @type {object}
* @property {string} headlineColor='black' - By default headline color is black
* @property {string} headlineType='h3' - Default HTML heading value
*/
export const defaultProps = {
  headlineColor: 'black',
  headlineType: 'h3',
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

PromotedCardMini.propTypes = propTypes;
PromotedCardMini.defaultProps = defaultProps;
export default PromotedCardMini;