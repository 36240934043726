import React, { createContext, useContext, useRef, useState } from 'react';

/**
 * Context Provider for managing parent styles.
 *
 * @module core/providers/StyleContextProvider
 * @component StyleContextProvider
 * @example
 * <StyleProvider>
 *   <ChildComponent />
 * </StyleProvider>
 */
export const StyleProvider = function( props ){
  /**
   * State variable to hold the parent style object.
   * @type {[object, function]}
   * @default {}
   */
  const [mainWrapperStyle, setMainWrapperStyle] = useState( {} );
  // use a date to ensure that when updated multiple times all changes trigger updates
  const loaderRealized = useRef( Date.now() );

  // helper function to let the components inform the parents that the componet as been mounted
  const loaderMounted = () => {
    loaderRealized.current = Date.now();
  };

  return (
    <StyleContext.Provider
      value={ {
        mainWrapperStyle,
        setMainWrapperStyle,
        loaderRealized,
        loaderMounted
      } }
    >
      { props.children }
    </StyleContext.Provider>
  );
};

/**
 * Context object for consuming parent styles.
 *
 * @type {React.Context}
 * @property {object} parentStyle - The style object to apply to the parent.
 * @property {function} setParentStyle - Function to update the parent style object.
 */
export const StyleContext = createContext( {
    mainWrapperStyle: {},
    setMainWrapperStyle: () => {},
    loaderRealized: { current: Date.now() },
    loaderMounted: () => {}
} );

/**
 * Custom hook for accessing the StyleContext.
 *
 * @function useStyleContext
 * @returns {object} The StyleContext value.
 * @example
 * const { parentStyle, setParentStyle } = useStyleContext();
 */
export const useStyleContext = () => useContext( StyleContext );

export default StyleProvider;
