/**
 * Use badges to communicate information with a positive, neutral or negative sentiment. They can be semantic in meaning.
 *
 * @module views/components/StatusBadges
 * @memberof -Common
 */
import './StatusBadges.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import Text from '@ulta/core/components/Text/Text';

/**
 * Represents a StatusBadges component
 *
 * @method
 * @param {StatusBadgesProps} props - React properties passed from composition
 * @returns StatusBadges
 */
export const StatusBadges = ( props ) => {

  const { badgeType, badgeValue } = props;

  return (
    <div className='StatusBadges'>
      { ( badgeType === 'verification' || badgeType === 'icon' ) &&
        <div className={ classNames( { 'StatusBadges__Container--icon': ( badgeType === 'icon' ) } ) }>
          <Icon
            aria-hidden={ true }
            name={ badgeValue }
            size='lg'
          />
        </div>
      }
      { badgeType === 'notification' &&
        <div className={ `StatusBadges__Container--${ badgeType }` }>
          <Text
            textStyle='body-3'
            textAlign='center'
            children={ badgeValue }
          />
        </div>
      }
      { badgeType === 'quantity' &&
        <div className={ `StatusBadges__Container--${ badgeType }` }>
          <Text
            textStyle='body-3'
            textAlign='center'
            children={ badgeValue }
          />
        </div>
      }
    </div>
  );
};

/**
 * Property type definitions
 * @typedef StatusBadgesProps
 * @type {object}
 * @property {string} badgeType - Type of Badge
 * @property {node} badgeValue - Value of Badge
 */
export const propTypes =  {
  badgeType: PropTypes.string,
  badgeValue: PropTypes.node
};

StatusBadges.propTypes = propTypes;

export default StatusBadges;
