/**
 * The OverlayFlyout component is used to display content in the OverlayFlyout section
 *
 * @module views/__core/OverlayFlyoutHeader
 * @memberof -Common
 */
import './OverlayFlyoutHeader.scss';

import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';

import * as utils from './OverlayFlyoutHeader';

/**
   * Represents a OverlayFlyoutHeader component
   * @type {React.FC<OverlayFlyoutProps>}
   */
export const OverlayFlyoutHeader = React.forwardRef( ( props, ref )=>{
  const { closeAccessibilityLabel, invokeAction } = props;
  const { backAction, backActionCallback, closeOverlay } = useOverlay();
  const buttonRef =  useRef();

  // Auto Focus the cross button for Keyboard users.
  useEffect( ()=>{
    buttonRef?.current?.focus();
  }, [] );

  const isFlyout = backAction?.navigationType === 'flyout';
  return (
    <div
      className={ `OverlayFlyoutHeader` }
      ref={ ref }
    >
      { ( backAction || backActionCallback ) &&
      <div className={ `OverlayFlyoutHeader__back` }>
        <Button
          variant='linkSecondary'
          ariaLabel={ backAction?.label }
          { ...( !isFlyout && { onClick: () => utils.handleOverlayBackAction( { backAction }, { invokeAction, backActionCallback } ) } ) }
          { ...( isFlyout && { action: backAction } ) }
          label={ backAction?.label }
          iconImage={ 'CaretBack' }
          iconSize={ 'default' }
          iconLeft={ true }
          tabIndex='0'
          ariaHiddenIcon={ true }
        >
        </Button>
      </div>
      }
      <Button className='OverlayFlyoutHeader__close'
        variant='unstyled'
        ariaLabel={ closeAccessibilityLabel }
        onClick={ () =>closeOverlay( { userCancelled: true } ) }
        ref={ buttonRef }
        iconImage={ 'X' }
        iconSize={ 'lg' }
        ariaHiddenIcon={ true }
      />
    </div>
  );
} );

/**
   * handleOverlayBackAction method definition
   *
   * @param { object } data
   */

export const handleOverlayBackAction = ( data, methods ) => {
  const { backAction } = data || {};
  const { invokeAction, backActionCallback } = methods || {};
  const { graphql, customHeaders, params, variables, method } = backAction || {};

  backActionCallback && backActionCallback();

  if( !graphql || !invokeAction ){
    return;
  }

  invokeAction( {
    graphql,
    customHeaders,
    method,
    params,
    variables
  } );
};

/**
   * Property type definitions
   * @typedef OverlayFlyoutHeaderProps
   * @type {object}
   * @property {object} backAction - Sets the back button action and label
   * @property {string} closeAccessibilityLabel - Sets close accessibility label
   */

/**
   * @type {OverlayFlyoutProps}
   */
export const propTypes = {
  backAction: PropTypes.shape( {
    label: PropTypes.string,
    graphql: PropTypes.string
  } ),
  closeAccessibilityLabel: PropTypes.string
};

/**
   * Default values for passed properties
   *
   * @type {object}
   * @property {string} closeAccessibilityLabel = 'Close' - Sets close accessibility label
   */
export const defaultProps =  {
  closeAccessibilityLabel: 'Close'
};

OverlayFlyoutHeader.propTypes = propTypes;
OverlayFlyoutHeader.defaultProps = defaultProps;
OverlayFlyoutHeader.displayName = 'OverlayFlyoutHeader';

export default OverlayFlyoutHeader;