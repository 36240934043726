/**
 * This is a ProductOffersCard component. It will be used to display all offers details to user with their specified url.
 *
 * @module views/components/ProductOffersCard
 * @memberof -Common
 */
import './ProductOffersCard.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Text from '@ulta/core/components/Text/Text';

import ProductOffersDetail from '../ProductOffersDetail/ProductOffersDetail';

/**
 * Represents a ProductOffersCard component
 *
 * @method
 * @param {ProductOffersCardProps} props - React properties passed from composition
 * @returns ProductOffersCard
 */
export const ProductOffersCard = function( props ){

  const {
    offersTitle,
    offers,
    brandName,
    productName,
    variantTypeLabel,
    variantLabel,
    image,
    offersAction
  } = props;
  const offersActionLabel = offersAction?.accessibility?.label ? `${offersAction?.accessibility?.label} - ${offersAction.label}` : offersAction.label ;

  return (
    <div className='ProductOffersCard'>
      <Button
        tertiary
        iconRight
        className={ classNames( 'ProductOffersCard_link', {
          'ProductOffersCard_hasLabel': offersAction?.label } ) }
        iconImage='ArrowForward'
        iconSize='m'
        action={ {
          ...offersAction,
          navigationType: 'flyout'
        } }
        label={
          <div className='ProductOffersCard_label'>
            <Text textStyle='body-3'
              htmlTag='span'
              color='magenta-500'
            >
              { offersAction?.label }
            </Text>
          </div>
        }
        ariaLabel={ offersActionLabel }
        alignment='right'
        crossButtonVisibility={ true }
        overlayAriaLabel={ offersTitle }
        overlayContent={ <ProductOffersDetail
          title={ offersTitle }
          offers={ offers }
          brandName={ brandName }
          productName={ productName }
          variantTypeLabel={ variantTypeLabel }
          variantLabel={ variantLabel }
          image={ image }/> }
      />
    </div>
  );
};

/**
 * Property type definitions
 *
 * @typedef ProductOffersCardProps
 * @type {object}
 * @property {string} offersTitle - Sets the value of offersTitle
 * @property {object} offersAction - Sets the offersAction such as label and url for the product offers
 * @property {array} offers - List of offers for this product
 * @property {string} brandName - Sets the brandName
 * @property {string} productName - Sets the productName
 * @property {string} variantTypeLabel - Sets the variantTypeLabel
 * @property {string} variantLabel - Sets the variantLabel
 * @property {object} image - Sets the image
 * @property {string} image.imageUrl - Sets the image
 */
export const propTypes =  {
  offersTitle: PropTypes.string,
  offersAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string,
    accessibility: PropTypes.shape( {
      label: PropTypes.string
    } )
  } ).isRequired,
  offers: PropTypes.array,
  brandName: PropTypes.string,
  productName: PropTypes.string,
  variantTypeLabel: PropTypes.string,
  variantLabel: PropTypes.string,
  image: PropTypes.shape( {
    imageUrl: PropTypes.string
  } )
};

ProductOffersCard.propTypes = propTypes;

export default ProductOffersCard;
