/**
 * renders an image to the calling component. It typically is used for rendering proudct images. It has an intersection observer which is used for lazyloading.
 *
 * @module views/components/Image
 * @memberof -Common
 */
import './Image.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { useComponentIndexContext } from '@ulta/core/providers/ComponentIndexProvider/ComponentIndexProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { RESOURCE_CATEGORY, useResourceLoaderContext } from '@ulta/core/providers/ResourceLoaderProvider/ResourceLoaderProvider';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import * as utils from './Image';

const widthSteps = [100, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000];
const placeholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
/**
 * Represents a Image component
 *
 * @method
 * @param {ImageProps} props - React properties passed from composition
 * @returns Image
 */
export const Image = function( props ){
  // TODO the darkmode attribut will be a global setting at some point. for now it is local to the image for the background transfomration tempalte
  const isDarkMode = props.darkMode; // TEMPORARY: added as a prop for testing TODO is complete

  const ref = useRef( null );
  const imgRef = useRef( null );
  const { breakpoint, inflection } = useDeviceInflection();
  const isMobile = breakpoint?.CURRENT_BREAKPOINT === 'SM';
  const { queueResource, resourceLoaded } = useResourceLoaderContext();
  const { componentIndex } = useComponentIndexContext();

  const [prefetchImages] = utils.useImagePreload( { isMobile, prefetch: props.prefetch, componentIndex } );

  if( isServer() && prefetchImages ){
    queueResource( { category: RESOURCE_CATEGORY.PriorityImages } );
  }

  const { hasIntersected } = useIntersectionObserver( ref, {
    shouldUseIntersectionObserver: props.shouldUseIntersectionObserver,
    root: props.root,
    rootMargin: props.rootMargin,
    threshold: props.threshold,
    triggerOnce: true
  } );

  const [isLoadingImage, setIsLoadingImage] = useState( true );
  const [imgSrc, setImgSrc] = React.useState( '' );

  const [imageSet, setImageSet] = useState(
    // eslint-disable-next-line
    breakpoint.BREAKPOINTS.reduce((acc, curr) => ((acc[curr] = ''), acc), {})
  );

  const showImage = !isServer() && !!props.src && ( hasIntersected || prefetchImages );
  const prevActiveBreakpoint = useRef();

  let { width: metaDataWidth, height: metaDataHeight, imageTemplate, format } = props.metaData || {};

  let aspectRatio;

  const hasValidAspectRatio = /(\d+):(\d+)$/.test( props.aspectRatio );
  if( hasValidAspectRatio ){
    // if an aspect ratio is provided then use that as the height/width for the metaData
    let [, heightRatio] = ( aspectRatio = props.aspectRatio.split( ':' ).map( ( i ) => parseFloat( i, 10 ) ) );
    metaDataHeight = metaDataWidth * heightRatio;
  }

  let shouldSetImageTemplate = !!imageTemplate && imageTemplate !== 'None';

  const getLoadingImage = useCallback(
    utils.composeGetLoadingImage(
      { imgSrc, isBackground: props.isBackground },
      { setIsLoadingImage } ),
    [imgSrc, props.isBackground, setIsLoadingImage] );

  useEffect( () => {
    if( !prefetchImages || !imgSrc ){
      return;
    }

    resourceLoaded( { category: RESOURCE_CATEGORY.PriorityImages } );
  }, [imgSrc, prefetchImages] );

  useEffect( () => {
    if( !showImage ){
      return;
    }

    // if the imgTag doesn't have a src tag then we will calculate the lowres image as a fraction of the max size
    const activeBreakpointIndex = breakpoint.BREAKPOINTS.findIndex( ( val ) => val === breakpoint.CURRENT_BREAKPOINT );
    const previousBreakpointIndex = breakpoint.BREAKPOINTS.findIndex( ( val ) => val === prevActiveBreakpoint.current );

    const imageSrcHasChanged =
      imageSet[breakpoint.CURRENT_BREAKPOINT]?.length > 5 &&
      !imageSet[breakpoint.CURRENT_BREAKPOINT].includes( props.src );

    let imageParams;

    if( imageSrcHasChanged || ( activeBreakpointIndex > previousBreakpointIndex && !imageSrcHasChanged ) ){
      let imageWidth = Math.round( ( props.width ? props.width : ref.current?.offsetWidth ) * global.devicePixelRatio );
      // if the width of the image is greater than the largest in the dam default ot the larges size in the dam
      imageWidth = metaDataWidth && ( imageWidth > metaDataWidth ) ? metaDataWidth : imageWidth;
      // adjust image to a normalized value to increase cache hit utilization
      imageWidth = widthSteps.find( ( step ) => step >= imageWidth ) || widthSteps[widthSteps.length - 1];

      let newImgSetSrc = `${props.src}`;

      // no need to add parameters to data based srcs
      if( props.src.indexOf( 'http' ) > -1 ){
        imageParams = `w=${imageWidth}`;
        if( shouldSetImageTemplate ){
          imageParams += `&$${imageTemplate}${isDarkMode ? 'Dark' : 'Light'}$`;
        }

        if( hasValidAspectRatio ){
          let [widthRatio, heightRatio] = aspectRatio;

          // calculate the height
          imageParams += `&h=${Math.round( ( heightRatio / widthRatio ) * imageWidth )}`;

          // set the metaData Height based on the aspect ratio value
          metaDataHeight = metaDataWidth * heightRatio;
        }

        const paramSeparator = utils.getParamSeparator( { url: props.src } );
        newImgSetSrc = `${props.src}${paramSeparator}${imageParams}`;
      }

      if( newImgSetSrc !== imgSrc ){
        setIsLoadingImage( true );

        setImageSet( {
          ...imageSet,
          [breakpoint.CURRENT_BREAKPOINT]: newImgSetSrc
        } );

        setImgSrc( newImgSetSrc );

      }

      prevActiveBreakpoint.current = breakpoint.CURRENT_BREAKPOINT;
    }
  }, [
    showImage,
    breakpoint.CURRENT_BREAKPOINT,
    inflection.MOBILE,
    props.src,
    props.aspectRatio,
    props.width
  ] );

  const imageStyles = {
    ...( props.width && { width: `${props.width}px` } ),
    ...( props.height && { height: `${props.height}px` } )
  };

  const fmtParam = utils.getFmtParam( { format, imgSrc } );

  let backgroundStyle;

  if( props.isBackground && showImage && imgSrc ){
    backgroundStyle = { backgroundImage: `url(${imgSrc}${fmtParam})` };
  }

  const imgSrcUrl = `${imgSrc}${fmtParam}`;

  return (
    <div

      className={ classNames( 'Image', {
        'Image--placeholder': props.bgAnimation
      } ) }
      ref={ ref }
      { ...( ( props.width || props.height ) && { style: imageStyles } ) }
      { ...( props.ariaLabel && { [`aria-label`]: props.ariaLabel } ) }
      { ...( !!backgroundStyle && { style: backgroundStyle } ) }
    >
      { !props.isBackground && (
        <img
          onLoad={ getLoadingImage }
          className={ classNames( {
            'Image--loading': isLoadingImage
          } ) }
          ref={ imgRef }
          alt={ props.alt }
          { ...( props.ariaHidden && { [`aria-hidden`]: 'true' } ) }
          { ...( !showImage && { src: placeholder } ) }
          { ...( !( props.shouldUseIntersectionObserver || prefetchImages ) && { loading: 'lazy' } ) }
          { ...( showImage && imgSrc && { src: imgSrcUrl } ) }
          { ...( props.id && { id: props.id } ) }
          { ...( props.metaData?.width && { width: metaDataWidth } ) }
          { ...( props.metaData?.height && { height: metaDataHeight } ) }
          { ...( props.ariaCurrent && {
            [`aria-current`]: props.ariaCurrent
          } ) }
        />
      ) }
    </div>
  );

};

export const prefetchModuleIndexPriority = {
  mobile: parseInt( process.env.REACT_APP_MODULE_INDEX_FOR_IMAGE_FETCH_PRIORITY_MOBILE, 10 ),
  desktop: parseInt( process.env.REACT_APP_MODULE_INDEX_FOR_IMAGE_FETCH_PRIORITY_DESKTOP, 10 )
};

/**
 * Composes a function to handle loading images.
 *
 * @param {Object} data - The data object containing image properties.
 * @param {Object} methods - The methods object containing the function to update loading state.
 * @returns {Function} A function that checks the image source and updates loading state.
 */
export const composeGetLoadingImage = ( data, methods ) => () => {
  const { imgSrc, isBackground } = handleEmptyObjects( data );
  const { setIsLoadingImage } = handleEmptyObjects( methods );

  if( !imgSrc || isBackground ){
    return;
  }

  setIsLoadingImage( false );
};

/**
 * Return the correct query param seperator based on the provided URL
 * @param {Object} data - url to process
 * @returns String
 */
export const getParamSeparator = ( data ) => {
  const { url = '' } = handleEmptyObjects( data );
  return url.indexOf( '?' ) > -1 ? '&' : '?';
};

/**
 * Return the fmt (format) of the image to return
 * @param {Object} data - format and imgSrc to process
 * @returns String
 */
export const getFmtParam = ( data ) => {
  const { format, imgSrc } = handleEmptyObjects( data );
  const pSep = utils.getParamSeparator( { url: imgSrc } );
  return !format?.includes( 'svg' ) && imgSrc.startsWith( 'http' ) && !imgSrc.includes( 'fmt=' ) ? `${pSep}fmt=auto` : '';
};

export const useImagePreload = ( data ) => {
  const { isMobile, prefetch, componentIndex } = data || {};

  const prefetchIndex = utils.prefetchModuleIndexPriority[isMobile ? 'mobile' : 'desktop'];
  return [prefetch && ( componentIndex.current <= prefetchIndex )];
};

/**
 * Property type definitions
 * @typedef ImageProps
 * @type {object}
 * @property {string} src - Sets the image src for image display
 * @property {string} alt - Sets the alt text for image. Required for all images. If decorative image pass ''
 * @property {boolean} ariaHidden - Sets the area hidden
 * @property {number} width - Sets the image width
 * @property {number} aspectRatio - Sets the images aspect ratio so that a specific size can be reuqested
 * @property {object} metaData - Image metadata coming down from DXL/Amplience
 * @property {string} ariaLabel - Sets the ariaLabel for the image
 * @property {boolean} isBackground - Denotes whether this is a background image
 * @property {boolean} bgAnimation - a flag to set the background color as placeholder while the banner image is loading
 */
export const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  ariaHidden: PropTypes.bool,
  width: PropTypes.number,
  aspectRatio: PropTypes.string,
  metaData: PropTypes.object,
  ariaLabel: PropTypes.string,
  isBackground: PropTypes.bool,
  shouldUseIntersectionObserver: PropTypes.bool,
  bgAnimation: PropTypes.bool,
  prefetch: PropTypes.bool
};

/**
 * Default values for passed properties
 *
 * @type {object}
 * @property {string} rootMargin='50px 50px' - Margin around the root. If the image gets within 50px in the Y axis, start the download.
 * @property {object} root=null - The element that is used as the viewport for checking visiblity of the target.
 * @property {number} threshold=0.01 - Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
 * @property {string} alt='' - The "alt" attribute value defaults to an empty string
 * @property {number} metaData.width=undefined - Set the image metaData width to undefined by default
 * @property {number} metaData.height=undefined - Set the image metaData height to undefined by default
 * @property {boolean} isBackground=false - Default setting for is this a background image is false
 */
export const defaultProps = {
  // If the image gets within 50px in the Y axis, start the download.

  rootMargin: '50px 50px',
  root: null,
  threshold: 0.01,
  alt: '',
  metaData: {
    width: undefined,
    height: undefined
  },
  isBackground: false,
  shouldUseIntersectionObserver: true,
  prefetch: true
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default React.memo( Image );
