export const constants = {
  APPLICATION_ROOT: 'root',
  // The options are object passed into the IntersectionObserver() constructor that controls
  // the circumstances under which the observer's callback is invoked
  INTERSECTION_OBSERVER_OPTIONS: {
    rootMargin: '0px',
    root: null,
    threshold: [0, 0.25, 0.5, 0.75, 1]
  },
  DATACAPTURE_EVENT_TYPE: {
    viewport: 'viewport',
    click: 'click',
    response: 'response'
  },
  EVENTS: {
    UPDATE_APP_CONFIG: 'updateAppConfig'
  },
  TEALIUM_LINK_EVENT_TIMEOUT: 1000,
  PRODUCT_HIGHLIGHTER_VISIBILITY_LENGTH: 3,
  LOGIN_STATUS: {
    SOFT_LOGIN: 'softLogin',
    HARD_LOGIN: 'hardLogin',
    ANONYMOUS: 'anonymous'
  },
  URGENCY_TAG_TYPE: {
    THREE_PLUS_DAYS: '3+ days',
    TWO_DAYS: '2 days',
    ONE_DAY: '1 day',
    HOURS: 'Hours',
    EXTENDED: 'Extended',
    UPCOMING: 'Upcoming',
    TIMER: 'Timer'
  },
  TAB_KEY: 9,
  ENTER_KEY: 13,
  ESC_KEY: 27,
  UPARROW_KEY: 38,
  DOWNARROW_KEY: 40,
  LEFTARROW_KEY: 37,
  RIGHTARROW_KEY: 39,
  SPACE_KEY: 32,
  PLUS_KEY: 187,
  MINNUS_KEY: 189,
  COLUMN_CONTAINER_BREAKPOINTS: {
    XL: 'extraLargeDevice',
    LG: 'largeDevice',
    MD: 'mediumDevice',
    SM: 'smallDevice'
  },
  SAVE_FOR_LATER_OPTIONS: {
    FLYOUT: 'flyout'
  },
  DELIVERY_TYPE:{
    PICKUP: 'pickup',
    SHIP: 'ship',
    DELIVERY: 'delivery'
  },
  PICKUP_STORE_AVAILABILITY_STATUS: {
    COMPLETELY_AVAILABLE: 'completelyAvailable'
  },
  SEARCH_MOBILE_MODAL_CLASS: 'SearchMobile',
  GIFTCARD: {
    NUMBERFORMATTER: '9999 9999 9999 9999',
    PINFORMATTER: '99999999'
  },
  FORMATTER_PATTERNS: {
    CREDITCARD_FORMATTER_AMEX: { pattern: '9999 999999 99999' },
    CREDITCARD_FORMATTER: { pattern: '9999 9999 9999 9999' },
    DASHED_PHONE_NUMBER: { pattern: '999-999-9999' },
    DOB_FORMATTER : { pattern: '99/99' },
    DOB_YEAR_FORMATTER: { pattern: '99/99/9999' },
    EXPDATE_FORMATTER: { pattern: '99/99' },
    PHONE_NO_FORMATTER: { pattern: '(999) 999-9999' },
    REWARDS_ID_FORMATTER: { pattern: '9999999999999' },
    SSN_FORMATTER: { pattern: '999-99-9999', hidden: '***-**-9999' },
    ZIPCODE_FORMATTER: { pattern: '99999' }
  },
  REGEX_PATTERNS:{
    nicknameRegex: '^(?! )[ A-Za-z0-9_@&.,/\\[\\]()^?-]*$',
    nameoncardRegex: `^(?! )[ A-Za-z0-9_@&.,/\\[\\]()^?'*-]*$`, // changes
    firstNameRegex : `^(?! )[ A-Za-z0-9_@&.,/\\[\\]()^?'*-]*$`,
    lastNameRegex : `^(?! )[ A-Za-z0-9_@&.,/\\[\\]()^?'*-]*$`,
    phoneNumberRegex : '^((\\([2-9][0-9][0-9]\\))|\\d{3})[- ]?\\d{3}[- ]?\\d{4}$',
    zipCodeOrCityRegex: /^[a-zA-Z ,]+$|\b\d{5,}\b/,
    dashedPhoneNumberRegex: '^[2-9]\\d{2}-\\d{3}-\\d{4}$',
    emailRegex: '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'
  },
  HFN: {
    IncrementalModuleName: 'IncrementalModule',
    MainWrapperModuleName: 'MainWrapper',
    TopBarModuleName: 'TopBar',
    FooterModuleName: 'Footer',
    header: '/header',
    footer: '/footer',
    href: '{href}',
    EXTERNAL_HEADER: 'header-ds',
    EXTERNAL_FOOTER: 'footer-ds'
  },
  PAYPALBUTTON: {
    PAYMENT_TYPE: 'paypal',
    STYLE: {
      LABEL: 'pay', // oneOf( 'pay', 'paypal', 'buynow', 'checkout', 'installment' )
      SIZE: 'responsive', // oneOf( 'small', 'medium', 'large', 'responsive' )
      COLOR: 'gold', // oneOf( 'silver , 'white', 'black', 'gold', 'blue' )
      SHAPE: 'rect' // oneOf( 'rect', 'pill' )
    },
    FLOW: 'vault',
    LOCALE: 'en_US',
    CURRENCY: 'USD' // Required currency
  },
  SEARCH_PARAM: 'search',
  SEARCH_HEADER_PATH: '/searchHeader',
  COMAPCTDEALCARD_IMAGE_DIMENSIONS: {
    singleImage: [123, 158, 192, 212],
    multiImage: [60, 97, 118, 129]
  },
  CVV:{
    AMEXCARD_FORMATTER: { pattern: '9999' },
    OTHERCARD_FORMATTER: { pattern: '999' }
  },
  SNACKBAR_DISPLAY_DURATION: 3000,
  ITEMS_PER_PAGE: 96,
  SPACER_IGNORED_MODULES:['GamBanner'],
  PREVIEWDATE_COOKIE: 'X-ULTA-PREVIEW-DATE'
};

export default constants;