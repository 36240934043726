/**
 * The alignment container allows a user to align a group of components left, center, or right and apply a background color as well.
 *
 * @module views/components/AlignmentContainer
 * @memberof -Common
 */
import React from 'react';

import PropTypes from 'prop-types';
import PaletteAlignmentContainer from 'web-palette/dist/components/AlignmentContainer/AlignmentContainer';

/**
 * Represents a AlignmentContainer component
 *
 * @method
 * @param { AlignmentContainerProps} props - React properties passed from composition
 * @returns AlignmentContainer
 */
export const AlignmentContainer = function( props ){
  const {
    children,
    styleConfigs
  } = props;

  if( !children ){
    return null;
  }

  const PalAlignmentContainer = PaletteAlignmentContainer.default || PaletteAlignmentContainer;

  const {
    backgroundColor,
    extraLargeDevice,
    largeDevice,
    mediumDevice,
    smallDevice
  } = styleConfigs;

  /** Create all of the objects for the different configurations to be sent to Palette */
  const xlDevice = {
    alignment: extraLargeDevice && extraLargeDevice.alignment,
    backgroundColor: backgroundColor,
    padding: extraLargeDevice && extraLargeDevice.padding
  };

  const lgDevice = {
    alignment: largeDevice && largeDevice.alignment,
    backgroundColor: backgroundColor,
    padding: largeDevice && largeDevice.padding
  };

  const mdDevice = {
    alignment: mediumDevice && mediumDevice.alignment,
    backgroundColor: backgroundColor,
    padding: mediumDevice && mediumDevice.padding
  };

  const smDevice = {
    alignment: smallDevice && smallDevice.alignment,
    backgroundColor: backgroundColor,
    padding: smallDevice && smallDevice.padding
  };

  return (
    <div className='AlignmentContainer'>
      <PalAlignmentContainer
        xlDevice={ xlDevice }
        lgDevice={ lgDevice }
        mdDevice={ mdDevice }
        smDevice={ smDevice }
      >
        { children }
      </PalAlignmentContainer>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef AlignmentContainerProps
 * @type {object}
 * @property {node} children - object of children
 * @property {object} styleConfigs - The object of settings (alignment, backgroundColor, padding) for each breakpoint
 */
export const propTypes =  {
  /** The object of children */
  children: PropTypes.node,
  /** The object of settings (alignment, backgroundColor, padding) for each breakpoint */
  styleConfigs: PropTypes.object
};

AlignmentContainer.propTypes = propTypes;

export default AlignmentContainer;
