/**
 * When a user scrolls down on a page, the BackToTop component should display at the bottom right of the page, and smooth scroll to the top of the page when a user clicks/touches the button.
 *
 * @module views/Navigation/BackToTop
 */

import './BackToTop.scss';

import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { handleScrollToTop } from '@ulta/core/utils/animation/animation';
import { constants } from '@ulta/core/utils/constants/constants';

const { ENTER_KEY } = constants;

/**
 * Represents a BackToTop component
 *
 * @method
 * @param {BackToTopProps} props - React properties passed from composition
 * @returns BackToTop
 */
export const BackToTop = function( props ){
  const divRef = useRef( null );
  useEffect( () => {
    // When the user scrolls down 20px from the top of the document, show the button
    global.onscroll = ( ) => {
      backToTopStyler( divRef );
    };
  } );
  UseKeyPress( ENTER_KEY, divRef, handleScrollToTop );

  return (
    <div ref={ divRef }
      className='BackToTop'
    >
      <Button
        id='backToTop'
        variant='unstyled'
        type='button'
        onClick={ handleScrollToTop }
        ariaLabel={ props.backToTopAriaLabel }
        className='BackToTop__Button'
        iconName='arrowUp'
        iconSize='lg'
        ariaHiddenIcon={ true }
      />
    </div>
  );
};


/**
 * Apply classes to BackToTop button on scroll
 *
 * @method
 * @param { Object } divRef - Target ref on whose child the class should be applied
 * @returns { void }
 */
export const backToTopStyler = ( divRef = null ) => {
  if( divRef?.current ){
    divRef.current.children[0].classList.add( 'BackToTop__Button' );
    if( document.documentElement.scrollTop > 20 && document.documentElement.scrollTop <= 300 ){
      divRef.current.children[0].classList.add( 'BackToTop__Button--isVisible' );
    }
    else if( document.documentElement.scrollTop > 300 ){
      divRef.current.children[0].classList.add( 'BackToTop__Button--fadeOut' );
    }
    else {
      divRef.current.children[0].classList.remove( 'BackToTop__Button--isVisible' );
      divRef.current.children[0].classList.remove( 'BackToTop__Button--fadeOut' );
    }
  }
};

/**
 * property type definitions
 * @typedef BackToTopProps
 * @type { object }
 * @property { string } backToTopAriaLabel - Button aria label
 */
export const propTypes =  {
  backToTopAriaLabel: PropTypes.string
};

/**
 * Default values for passed properties
 * @type object
 * @property { string } [ backToTopAriaLabel = 'back to top'] - Set the arial-label attribute for button
 */
export const defaultProps =  {
  backToTopAriaLabel: 'back to top'
};

BackToTop.propTypes = propTypes;
BackToTop.defaultProps = defaultProps;


export default BackToTop;
