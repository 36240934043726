/**
 * RewardsOffersRail is consist of LargedealRail and manages different states
 *
 * @module views/components/RewardsOffersRail
 * @memberof -Common
 */
import './RewardsOffersRail.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import palette_variables from 'web-styles/src/_exports.module.scss';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { handleSwiperImpressionEvent } from '@ulta/core/utils/datacaptureAggregator/datacaptureAggregator';

import LargeDealCard from '@ulta/components/LargeDealCard/LargeDealCard';

import carouselUtils, { handleInactiveSwiperSlides } from '@ulta/utils/carouselUtils/carouselUtils';

SwiperCore.use( [Navigation, Keyboard, A11y] );

let swiperCounter = 1;

/**
 * @description RewardsOffersRail component displays RewardsOffersRail offers data.
 * @param {object} props - React props passed from composition
 * @param {array} props.offers - RewardsOffersRail offers data
 * @param {string} props.headerTitle - Title of UltamateRewards offers
 * @param {string} props.pointsLabel - Label for points
 * @param {string} props.pointsValueLabel - Label for points value
 * @param {string} props.loyaltyTier - Label for loyalty tier
 * @param {string} props.carouselAccessibility - Accessibility string for carousel
 * @param {string} props.carouselDetailsAccessibility - Accessibility string for carousel details
 * @param {boolean} props.hasOffers - Flag to check if RewardsOffersRail offers exist
 * @param {boolean} props.vertical - Flag to check if offers are displayed vertically
 * @param {string} props.componentKey - Unique key for each component
 * @param {function} props.invokeMutation - Function to invoke mutation
 * @param {object} props.nextClickAction - Action to be performed when next button is clicked
 * @param {object} props.previousClickAction - Action to be performed when previous button is clicked
 * @returns {ReactElement} RewardsOffersRail component
 */
const RewardsOffersRail = ( props ) => {
  const [index, setIndex] = useState( 0 );
  const {
    offers,
    hasOffers,
    title,
    headerTitle,
    pointsLabel,
    pointsValueLabel,
    loyaltyTier,
    carouselAccessibility,
    nextAccessibility,
    previousAccessibility,
    viewAllOffersAction,
    carouselDetailsAccessibility,
    vertical,
    componentKey,
    invokeMutation,
    nextClickAction,
    previousClickAction
  } = props;

  const ultaMateOffersRef = useRef();
  const carouselRef = useRef();
  const [currentIndex, SetCurrentIndex] = useState( 0 );
  swiperCounter += 1;

  const offersLength = offers?.length;
  const isSingleItem = offersLength === 1;
  const isLastItem = index === ( offersLength - 1 );
  const selectedOffer = currentIndex + 1;
  const validOffers = isSingleItem ? null : offers;

  useIntersectionObserver(
    ultaMateOffersRef,
    {
      root: props.root,
      rootMargin: props.rootMargin,
      threshold: props.threshold
    },
    handleSwiperImpressionEvent( { ...props, items: validOffers }, carouselRef )
  );

  if( !offersLength ){
    return null;
  }

  return (
    <div className='RewardsOffersRail'
      ref={ ultaMateOffersRef }
      key={ `${componentKey}-rewards-${offersLength}` }
    >
      <div className='RewardsOffersRail__wrapper'>
        <div className={
          classNames( 'RewardsOffersRail__content', {
            'RewardsOffersRail__content--vertical': vertical
          }
          ) }
        >
          <div className={
            classNames( 'RewardsOffersRail__header', {
              'RewardsOffersRail__header--singleItem': isSingleItem,
              'RewardsOffersRail__header--vertical': vertical
            } )
          }
          >
            <div className={
              classNames( 'RewardsOffersRail__titleInfo', {
                'RewardsOffersRail__titleInfo--vertical': vertical
              } )
            }
            >
              { headerTitle &&
              <div className='RewardsOffersRail__headerTitle'>
                <Text
                  textStyle='title-6'
                  htmlTag={ headerTitle.htmlTag }
                >
                  { headerTitle.text }
                </Text>
              </div>
              }
              { viewAllOffersAction &&
              <div className={ classNames( 'RewardsOffersRail__headerLink', {
                'RewardsOffersRail__headerLink--vertical': vertical
              } )
              }
              >
                <Link_Huge
                  target='_self'
                  withHover={ true }
                  action={ viewAllOffersAction }
                >
                  { viewAllOffersAction.label }
                </Link_Huge>
              </div>
              }
            </div>
            <div className={ classNames( 'RewardsOffersRail__rewardInfo', {
              'RewardsOffersRail__rewardInfo--vertical': vertical
            } )
            }
            >
              <ul className='RewardsOffersRail__rewardList'>
                { pointsLabel &&
                <li className='RewardsOffersRail__points'>
                  <Text
                    textStyle='body-3'
                    htmlTag='p'
                  >
                    { pointsLabel }
                  </Text>
                </li>
                }
                { pointsValueLabel &&
                <li className='RewardsOffersRail__value'>
                  <Text
                    textStyle='body-3'
                    htmlTag='p'
                  >
                    { pointsValueLabel }
                  </Text>
                </li>
                }
              </ul>
              { loyaltyTier &&
              <div className='RewardsOffersRail__memberType'>
                <Text
                  textStyle='body-3'
                  htmlTag='p'
                  color='neutral-600'
                >
                  { loyaltyTier }
                </Text>
              </div>
              }
            </div>
          </div>
          <div className={
            classNames( 'RewardsOffersRail__container', {
              'RewardsOffersRail__container--singleItem': isSingleItem,
              'RewardsOffersRail__container--lastItem': !isSingleItem && isLastItem,
              'RewardsOffersRail__container--firstItem': !isSingleItem && index === 0,
              'RewardsOffersRail__container--firstItem--vertical': !isSingleItem && index === 0 && vertical,
              'RewardsOffersRail__container--singleItem--vertical': isSingleItem && vertical
            } )
          }
          { ... ( !isSingleItem && { role: 'region' } ) }
          { ... ( !isSingleItem && { 'aria-label': carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) } ) }
          >
            { /* TODO : aria-label will be localized */ }
            { carouselDetailsAccessibility && <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p> }
            {
              !isSingleItem &&
                <div className={
                  classNames( `RewardsOffersRail__Pagination RewardsOffersRail__Pagination-${swiperCounter}`, {
                    'RewardsOffersRail__Pagination--vertical': vertical
                  } )
                }
                >
                  <Button
                    action={ previousClickAction }
                    variant='navigation'
                    iconImage='CaretBack'
                    iconSize='lg'
                    className='RewardsOffersRail__Pagination__button RewardsOffersRail__Pagination__button--back'
                    ariaLabel={ previousAccessibility }
                    ariaHiddenIcon={ true }
                  />
                  <Text
                    textStyle='body-3'
                    htmlTag='span'
                  >
                    { `${selectedOffer}/${offersLength}` }

                  </Text>

                  <Button
                    action={ nextClickAction }
                    variant='navigation'
                    iconImage='CaretForward'
                    iconSize='lg'
                    className={ 'RewardsOffersRail__Pagination__button RewardsOffersRail__Pagination__button--forward' }
                    ariaLabel={ nextAccessibility }
                    ariaHiddenIcon={ true }
                  />
                </div> }
            <Swiper
              virtualindex={ currentIndex }
              initialSlide={ currentIndex }
              defaultValue={ currentIndex }
              ref={ carouselRef }
              onSwiper={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
              onSlideChange={ ( swiper ) => {
                SetCurrentIndex( swiper?.realIndex );
                handleInactiveSwiperSlides( swiper );
              } }
              watchOverflow={ true }
              watchSlidesProgress={ true }
              watchSlidesVisibility={ true }
              centeredSlides={ true }
              freeMode={ false }
              preloadImages={ true }
              spaceBetween={ isSingleItem ? 0 : 16 }
              navigation={ {
                prevEl: `.RewardsOffersRail__Pagination-${swiperCounter} .RewardsOffersRail__Pagination__button--back`,
                nextEl: `.RewardsOffersRail__Pagination-${swiperCounter} .RewardsOffersRail__Pagination__button--forward`,
                disabledClass: 'RewardsOffersRail__Pagination__button--disabled',
                hiddenClass: 'RewardsOffersRail__Pagination__button--hidden'
              } }
              breakpoints={ {
                [palette_variables.breakpointLG]: {
                  slidesPerView: isSingleItem ? 1 : 1.2
                },
                [palette_variables.breakpointMD]: {
                  slidesPerView: isSingleItem ? 1 : 1.5
                },
                [palette_variables.breakpointSM]: {
                  slidesPerView: isSingleItem ? 1 : 1.100
                }
              } }
            >
              {
                !isSingleItem && offers?.map( ( offer, index ) => (
                  <SwiperSlide key={ `RewardsOffersRail${index + 1} ` }
                    aria-label={ `deal ${index + 1} of ${offersLength}` }
                  >
                    <div
                      className={
                        classNames( 'RewardsOffersRail__itemclass', {
                          'RewardsOffersRail__itemclass--lastItem': !isSingleItem && isLastItem,
                          'RewardsOffersRail__itemclass--singleItem': isSingleItem,
                          'RewardsOffersRail__itemclass--firstItem': currentIndex === 1,
                          'RewardsOffersRail__itemclass--vertical': vertical
                        } ) }
                    >
                      <LargeDealCard
                        key={ index }
                        isUltamateRewards={ hasOffers }
                        image={ offer.image }
                        headline={ offer.headline }
                        description={ offer.description }
                        tag={ carouselUtils.computeProductTag( offer.tag, offer.urgencyTag?.timerMeta?.endsInLabel ) }
                        eyebrow={ offer.eyebrow }
                        action1={ offer.actionGroup?.action1 }
                        action2={ offer.actionGroup?.action2 }
                        action1Style={ offer.actionGroup?.action1Style }
                        action2Style={ offer.actionGroup?.action2Style }
                        isOfferActivated={ offer.isOfferActivated }
                        noOffers={ offer.noOffers }
                        activatedMessage={ offer.activatedMessage }
                        activateAction={ offer.activateAction }
                        invokeMutation={ invokeMutation }
                        activatedLabel={ offer.activatedLabel }
                        offerId={ offer.offerId }
                        dataCapture={ offer.dataCapture }
                        vertical={ vertical }
                      />
                    </div>
                  </SwiperSlide>
                ) )
              }
              {
                isSingleItem && offers?.map( ( offer, index ) => (
                  <div
                    key={ `RewardsOffersRail__itemclass-${index}` }
                    className={
                      classNames( 'RewardsOffersRail__itemclass', {
                        'RewardsOffersRail__itemclass--singleItem': isSingleItem,
                        'RewardsOffersRail__itemclass--firstItem': currentIndex === 1,
                        'RewardsOffersRail__itemclass--vertical': vertical
                      } ) }
                  >
                    <LargeDealCard
                      key={ index }
                      isUltamateRewards={ hasOffers }
                      item={ offer.length }
                      image={ offer.image }
                      headline={ offer.headline }
                      description={ offer.description }
                      tag={ carouselUtils.computeProductTag( offer.tag, offer.urgencyTag?.timerMeta?.endsInLabel ) }
                      eyebrow={ offer.eyebrow }
                      action1={ offer.actionGroup?.action1 }
                      action2={ offer.actionGroup?.action2 }
                      action1Style={ offer.actionGroup?.action1Style }
                      action2Style={ offer.actionGroup?.action2Style }
                      isOfferActivated={ offer.isOfferActivated }
                      noOffers={ offer.noOffers }
                      activatedMessage={ offer.activatedMessage }
                      activateAction={ offer.activateAction }
                      invokeMutation={ invokeMutation }
                      activatedLabel={ offer.activatedLabel }
                      offerId={ offer.offerId }
                      vertical={ vertical }
                    />
                  </div>
                ) )
              }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
   * Property type definitions
   * @typedef RewardsOffersRailProps
   * @type {object}
   * @property {function} invokeAction - Set the invokeAction object
   * @property {array} offers - Set the reward offers object
   * @property {boolean} hasOffers - flag to set reward offers
   * @property {string} title - the offer title in plain text
   * @property {object} headerTitle - set the offer title with proper accessiblity.
   * @property {string} pointsLabel - sets the rewards points.
   * @property {string} pointsValueLabel - sets the rewards points value.
   * @property {string} loyaltyTier - sets the loyalty type
   * @property {string} carouselAccessibility - Value for aria label for carousel.
   * @property {string} carouselDetailsAccessibility - sets sr-only text for keyboard users.
   * @property {string} previousAccessibility - Sets the aria label for previous button.
   * @property {string} nextAccessibility - Sets the aria label for next button.
   * @property {object} viewAllOffersAction - Sets the lable and url for viewAll offers
   * @property {string} componentKey - set componentKey.
   * @property {boolean} vertical - Sets the offers variant as Bonus Offers for Dashboard
   * @property {function} invokeMutation - Sets the function for invokeMutation
   * @property {object} nextClickAction - Set the action for nextClick of arrow button
   * @property {object} previousClickAction - Set the action for previousClick of arrow button
  */

export const propTypes = {
  invokeAction: PropTypes.func,
  offers: PropTypes.array,
  hasOffers: PropTypes.bool,
  title: PropTypes.string,
  headerTitle: PropTypes.shape( {
    htmlTag: PropTypes.string,
    text: PropTypes.string
  } ),
  pointsLabel: PropTypes.string,
  pointsValueLabel: PropTypes.string,
  loyaltyTier: PropTypes.string,
  carouselAccessibility: PropTypes.string,
  carouselDetailsAccessibility: PropTypes.string,
  nextAccessibility: PropTypes.string,
  previousAccessibility: PropTypes.string,
  viewAllOffersAction: PropTypes.object,
  componentKey: PropTypes.string,
  vertical: PropTypes.bool,
  invokeMutation: PropTypes.func,
  nextClickAction: PropTypes.object,
  previousClickAction: PropTypes.object
};

RewardsOffersRail.propTypes = propTypes;
export default RewardsOffersRail;
