/**
 * Primary Navigation component for desktop pages across the portal
 *
 * @module views/components/PrimaryBar
 * @memberof -Common
 */
import './PrimaryBar.scss';

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import Button from '@ulta/core/components/Button/Button';
import Icon from '@ulta/core/components/Icon/Icon';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import { NAVIGATION_MODULES, useTopBar } from '../TopBar/TopBar';

/**
 * Represents a PrimaryBar component
 *
 * @method
 * @param {PrimaryBarProps} props - React properties passed from composition
 * @returns PrimaryBar
 */

export const PrimaryBar = React.forwardRef( ( props, _ ) => {
  const { inflection, breakpoint } = useDeviceInflection();
  const isMobile = inflection.MOBILE;
  const {
    setShowHambugerMenu,
    displaySearchInput,
    headerTitle,
    displayPrimaryNavigationLinks
  } = useTopBar();


  const {
    componentKey,
    logo,
    logoAction,
    modules,
    searchHeader,
    isMobileContainerContent,
    menuAccessibilityLabel
  } = props;

  const searchHeaderComponent = useMemo( () => {
    return (
      <AsyncComponent { ...searchHeader }/>
    );
  }, [] );

  if( !logo && !logoAction && !modules && !searchHeader ){
    return null;
  }

  const showToggleMenu = ( breakpoint.CURRENT_BREAKPOINT === 'SM' || breakpoint.CURRENT_BREAKPOINT === 'MD' ) && !headerTitle;

  const showLogo = !isMobile || !showToggleMenu;

  if( isMobile && isMobileContainerContent ){
    return (
      <nav>
        { modules.map( ( module, index ) => (
          ( module.moduleName === NAVIGATION_MODULES.PrimaryLinks || index === modules.length - 1 ) &&
          <AsyncComponent { ...module }
            key={ index }
          />
        ) ) }
      </nav>
    );
  }

  return (
    <div className='PrimaryBar'>
      { showToggleMenu && (
        <div className='PrimaryBar__toggleMenu'>
          <Button
            ariaLabel={ menuAccessibilityLabel }
            ariaHiddenIcon={ true }
            icon
            iconImage='menu'
            iconSize='l'
            className='PrimaryBar--hamburger'
            onClick={ () => {
              setShowHambugerMenu( true );
              document.body.classList.add( 'leftNavFlyoutOpen' );
            } }
          />
          <div className='PrimaryBar__logo'>
            <Link_Huge target='_self'
              action={ logoAction }
            >
              <div className='PrimaryBar__logo__image'>
                <Icon
                  name='ultaSiteLogo'
                  size='custom'
                  title={ logoAction.label }
                  ariaHidden={ false }
                  focusable={ true }
                />
              </div>
            </Link_Huge>
          </div>

          { displaySearchInput && searchHeaderComponent }
        </div>
      ) }

      { showLogo && <div className='PrimaryBar__logo'>
        <Link_Huge target='_self'
          action={ logoAction }
        >
          <div className='PrimaryBar__logo__image'>
            <Icon
              name='ultaSiteLogo'
              size='custom'
              title={ logoAction.label }
              ariaHidden={ false }
              focusable={ true }
            />
          </div>
        </Link_Huge>
      </div>
      }

      { headerTitle && (
        <div className='PrimaryBar__headerTitle'>
          <Text textStyle='title-6'>{ headerTitle }</Text>
        </div>
      ) }

      { !isMobile && modules && displayPrimaryNavigationLinks && (
        <nav aria-label='secondary'>
          { modules.map(
            ( module, index ) =>
              module.modules &&
              module.moduleName === NAVIGATION_MODULES.PrimaryLinks &&
              <AsyncComponent { ...module }
                key={ index }
              />
          ) }
        </nav>
      ) }

      <div className='PrimaryBar__search'>
        <ul className='PrimaryBar__shoppingIcons'>
          { !isMobile && displaySearchInput && searchHeaderComponent }
          { modules?.map(
            ( module, index ) =>
              module.moduleName !== NAVIGATION_MODULES.PrimaryLinks && (
                <li key={ `${componentKey}-pli-${index}` }>
                  <AsyncComponent { ...module } />
                </li>
              )
          ) }
        </ul>
      </div>

    </div>
  );
} );

/**
 * Property type definitions
 *
 * @typedef PrimaryBarProps
 * @type {object}
 * @property {string} logo - sets the logo
 * @property {object} logoAction - sets the logoAction
 * @property {array} modules - sets the primary links, avatar, bag icon and favorites icon
 * @property {object} searchHeader - set and pass the prop values for SearchHeader
 */
const propTypes = {
  logo: PropTypes.string,
  modules: PropTypes.array,
  logoAction: PropTypes.object,
  searchHeader: PropTypes.object
};

PrimaryBar.propTypes = propTypes;
PrimaryBar.displayName = 'PrimaryBar';

export default PrimaryBar;
