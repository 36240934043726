/**
 * ProductDimension Component will display product's type and description.
 *
 * @module views/components/ProductDimension
 * @memberof -Common
 */
import './ProductDimension.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';

import Swatch from '../Swatch/Swatch';

/**
 * Represents a ProductDimension component
 *
 * @method
 * @param {ProductDimensionProps} props - React properties passed from composition
 * @returns ProductDimension
 */
export const ProductDimension = function( props ){
  const {
    type,
    description,
    swatch,
    shadeDescription
  } = props;
  const swatchAltDesc = `${swatch?.description}${shadeDescription ? shadeDescription : ''}`;

  if( !type && !( swatch?.imageUrl && swatch?.description ) && !description ){
    return null;
  }

  return (
    <div className='ProductDimension'>
      { type &&
      <Text
        htmlTag='span'
        textStyle='body-3'
        color='neutral-600'
      >
        { `${type}:` }
      </Text>
      }

      { swatch?.imageUrl && swatch?.description &&
        <Swatch
          src={ swatch.imageUrl }
          alt={ swatchAltDesc }
          height={ swatch.width || 12 }
          width={ swatch.height || 12 }
        />
      }

      { description &&
      <Text
        htmlTag='span'
        textStyle='body-3'
        color='black'
      >
        { description }
      </Text>
      }
    </div>
  );
};

/**
 * Property type definitions
 *
 * @typedef ProductDimensionProps
 * @type {object}
 * @property {string} type - Sets the type of product
 * @property {string} description - Sets the description of the product
 * @property {object} swatch - Sets the swatch of the product
 */
export const propTypes =  {
  type: PropTypes.string,
  description: PropTypes.string,
  swatch: PropTypes.shape( {
    imageUrl: PropTypes.string,
    description: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
  } ),
  shadeDescription: PropTypes.string
};

ProductDimension.propTypes = propTypes;

export default ProductDimension;
