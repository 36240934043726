/**
 * measures the device pixel width and return sa boolean for isMobile
 * @returns boolean
 */
export const isMobileDisplay = function(){
  return document.documentElement.clientWidth <= 1023;
};

/**
 *  returns if the view is desktop or mobile
 * @param { boolean } isMobileDisplay - overload for setting if the displays is a mobile device
 * @returns string
 */
export const getDeviceInflection = function( isMobileDisplay ){
  if( typeof isMobileDisplay !== 'boolean' ){
    throw new Error( 'Expected an argument of a boolean type' );
  }

  return ( isMobileDisplay ? 'Mobile' : 'Desktop' ).toUpperCase();
};

/**
 * returns a string represneting the breakpoint according to the design system specifications
 * @param { number } clientWidth - the current width of the clietn viewport
 * @returns string
 */
export const getCurrentBreakpoint = function( baseInflection, clientWidth, isSSR = false ){
  // handling SSR
  let width;
  let serverCheck = isSSR || isServer();
  if( serverCheck ){
    width = baseInflection?.toLowerCase() === 'mobile' ? 1023 : 1440;
  }
  else {
    width = clientWidth || global.document.documentElement.clientWidth;
  }

  if( width <= 767 ){
    return 'SM';
  }
  else if( width >= 768 && width <= 1023 ){
    return 'MD';
  }
  else if( width >= 1024 && width <= 1439 ){
    return 'LG';
  }
  else if( width >= 1440 ){
    return 'XL';
  }
};

export const isServer = function(){
  return typeof window === 'undefined';
};