/**
 * This is a RecentSearchList Component.This Component is used to render the Recent Search List. This list will display recent searches if search term is less than 3 chars.
 *
 * @module views/Organisms/RecentSearchList
 * @memberof -Common
 */
import './RecentSearchList.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import Text from '@ulta/core/components/Text/Text';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { hasItems } from '@ulta/core/utils/array/array';

import RecentSearchItem from '../RecentSearchItem/RecentSearchItem';

/**
 * Represents a RecentSearchList component
 *
 * @method
 * @param {RecentSearchListProps} props - React properties passed from composition
 * @returns RecentSearchList
 */
export const RecentSearchList = function( props ){
  const { typeaheadMinInputLength } = useAppConfigContext();
  const {
    clearRecentLabel,
    recentLabel,
    recentSearches,
    handleAddRecentSearch,
    handleRemoveRecentSearch,
    handleClearAllRecentSearches,
    handleSaveSearchTerm,
    searchString,
    dataCaptureHandler,
    componentKey
  } = props;

  const hasRecentSearches = hasItems( recentSearches );

  if( searchString?.length >= typeaheadMinInputLength || !hasRecentSearches ){
    return null;
  }

  return (
    <div className='RecentSearchList'>
      <div className='RecentSearchList__header'>
        <Text textStyle='body-2'
          htmlTag='h2'
        >
          { recentLabel }
        </Text>
        <Button
          className='RecentSearchList__clearAll'
          onClick={ handleClearAllRecentSearches }
          ariaHiddenIcon={ true }
          variant='unstyled'
        >
          <Text htmlTag='span'
            textStyle='body-2'
            color='neutral-600'
            textColor='xsubtle'
          >
            { clearRecentLabel }
          </Text>
        </Button>
      </div>
      <ul className={ classNames( 'RecentSearchList__recents', {
        'RecentSearchList__recents--hasRecentSearches': hasRecentSearches
      } ) }
      >
        { recentSearches.map( ( recentSearch, index ) => (
          <li key={ `${componentKey}-recent-search-${index}` }>
            <RecentSearchItem
              clearRecentLabel={ clearRecentLabel }
              label={ recentSearch.label }
              url={ recentSearch.url }
              dataCaptureHandler={ dataCaptureHandler }
              handleAddRecentSearch={ handleAddRecentSearch }
              handleRemoveRecentSearch={ handleRemoveRecentSearch }
              handleSaveSearchTerm={ handleSaveSearchTerm }
            />
          </li>
        ) ) }
      </ul>
    </div>
  );
};

/**
 * property type definitions
 * @typedef RecentSearchListProps
 * @type object
 * @property {string} clearRecentLabel - This is recent searches clear all label
 * @property {string} recentLabel - This is recent searches label for current recent searches
 * @property { array } recentSearches - This is array of recent search strings
 * @property { string } searchString - This is the current search input value
 * @property { function } dataCaptureHandler - Function will handle/capture datacapture events and store in local storage
 * @property {function} handleRemoveRecentSearch - Function will handle removing recent search from local storage and update state
 * @property {function} handleClearAllRecentSearches - Function will handle clearing all recent searches from local storage and update state
 * @property { function } handleAddRecentSearch - Function will handle adding recent search to local storage
 * @property { function } handleSaveSearchTerm - Function will handle saving search term to local storage
 * @property {string} componentKey - provided by LayerHost, timestamp of the last time the component was updated
 */
export const propTypes = {
  clearRecentLabel: PropTypes.string,
  recentLabel: PropTypes.string,
  /** Determines the list of the recent search component */
  recentSearches: PropTypes.arrayOf(
    PropTypes.shape( {
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    } )
  ),
  searchString: PropTypes.string,
  dataCaptureHandler: PropTypes.func,
  handleRemoveRecentSearch: PropTypes.func,
  handleClearAllRecentSearches: PropTypes.func,
  handleAddRecentSearch: PropTypes.func,
  handleSaveSearchTerm: PropTypes.func,
  componentKey: PropTypes.string
};

RecentSearchList.propTypes = propTypes;

export default RecentSearchList;
