/**
 * UltamateRewardsOffers is consist of LargedealRail and manages different states
 *
 * @module views/components/UltamateRewardsOffers
 * @memberof -Common
 */

import './UltamateRewardsOffers.scss';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { constants } from '@ulta/utils/constants/constants';
import { hasItems } from '@ulta/utils/Validations/Validations';

import DealsRail from '../../components/DealsRail/DealsRail';
import RewardsOffersRail from '../../components/RewardsOffersRail/RewardsOffersRail';

/**
   * Represents a UltamateRewardsOffers component
   *
   * @method
   * @param {UltamateRewardsOffersProps} props - React properties passed from composition
   * @returns UltamateRewardsOffers
   */
export const UltamateRewardsOffers = function( props ){
  const { offers, variantType, titleText, title, setShowSpacer } = props;

  useEffect( ()=>{
    if( setShowSpacer ){
      setShowSpacer( hasItems( offers ) );
    }
  }, [offers] );

  if( !hasItems( offers ) ){
    return null;
  }

  // temporary title object for backwards compatibility
  // if titleText is not used, fallback to title string
  const headerTitle = {
    text: titleText?.text || title,
    htmlTag: titleText?.htmlTag || defaultProps.titleText.htmlTag
  };

  const updatedProps = Object.assign( { headerTitle }, props );

  return (
    <div className='UltamateRewardsOffers'>
      { variantType === ULTAMATE_REWARDS_RAIL_VIEW_OPTION?.DEALS ? (
        <DealsRail { ...updatedProps } />
      ) : (
        <RewardsOffersRail { ...updatedProps } />
      ) }
    </div>
  );
};

/**
 * @const {string} ULTAMATE_REWARDS_RAIL_VIEW_OPTION - option for horizontal rail view
 */
export const ULTAMATE_REWARDS_RAIL_VIEW_OPTION = {
  DEALS: 'mediumDealCard'
  // NOTE: beware backwards compatibility issues with the OFFERS variantType. DXL only sends this variantType prop for our new DEALS variant type.
};

/**
   * Default values for passed properties
   * @type {object}
   */
export const defaultProps = {
  ...constants.INTERSECTION_OBSERVER_OPTIONS,
  titleText: {
    htmlTag: 'h2'
  }
};

/**
   * Property type definitions
   * @typedef UltamateRewardsOffersProps
   * @type {object}
   * @property {array} offers - Set the reward offers object
   * @property {string} variantType - set the offer title
  */

export const propTypes = {
  offers: PropTypes.array,
  variantType: PropTypes.string
};

UltamateRewardsOffers.propTypes = propTypes;
UltamateRewardsOffers.defaultProps = defaultProps;

export default UltamateRewardsOffers;