import { useCallback, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import deepmerge from 'deepmerge';

import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { getClient } from '@ulta/core/utils/apollo_client/apollo_client';

import { useAppConfigContext } from '../../providers/AppConfigProvider/AppConfigProvider';
import { useRequestTracking } from '../../providers/LayerHostProvider/LayerHostProvider';
import { hasItems } from '../../utils/array/array';
import { processEvents } from '../../utils/datacapture/datacapture';
import { devLogger, LOG_TOPIC } from '../../utils/devMode/devMode';
import * as utils from './useDXLQuery';

/**
 * This is hook to invoke the DXL queries, and takes care of common logic
 * which needs to executed for all DXL requests
 *
 * @method
 * @param { Object } query - this represents the dxl query
 * @param { Object } config - this represents the config details for the query
 * @returns useDXLQuery
 */

export const useDXLQuery = ( query, config = {}, _isLazy = false, debugLabel ) => {
  const {
    enableSecurityErrorPage,
    graphqlDomain,
    graphqlServerUri,
    graphqlURI,
    isNative,
    isStaging,
    securityErrorStatusCodes
  } = useAppConfigContext();
  const { setPageData } = usePageDataContext();

  const [loading, setLoading] = useState( false );
  const [data, setData] = useState( null );
  const [error, setError] = useState( null );

  const invoke = useCallback( async( action ) => {
    const graphql = action.graphql || query;

    if( !graphql ){
      return;
    }
    const isMutation = graphql.substring( 0, 10 ).includes( 'mutation' );
    let method = 'POST';
    if( action.method ){
      method = action.method;
    }
    const client = getClient( { isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri, method } );
    const isNonCachedQuery = graphql.includes( 'query NonCachedPage(' );
    const handler = isMutation ? client.mutate : client.query;

    const defaultFetchPolicy =  'no-cache';
    const fetchPolicy = action.fetchPolicy || defaultFetchPolicy;

    setLoading( true );

    const options = {
      variables: action.variables,
      context: {
        ...action.context,
        fetchOptions: { method }
      },
      ...( !isMutation && { fetchPolicy } )
    };

    if( isMutation ){
      options.mutation = gql`${graphql}`;
    }
    else {
      options.query = gql`${graphql}`;
    }

    devLogger( {
      title: `DXL Request [${debugLabel}]`,
      topic: LOG_TOPIC.DXL,
      value: { action, isMutation, isNonCachedQuery, options }
    } );

    try {
      const response = await handler( options );
      setData( response?.data );
      setLoading( false );
    }
    catch ( error ){
      devLogger( {
        title: `DXL Request [${debugLabel}]`,
        topic: LOG_TOPIC.DXL,
        value: { action, isMutation, isNonCachedQuery, options, error }
      } );

      utils.onError(
        { error, enableSecurityErrorPage, securityErrorStatusCodes },
        { setPageData, setError, setLoading }
      );
    }
  }, [] );

  // Track the request stack
  useRequestTracking( { loading, debugLabel } );

  // Process data capture events
  useEffect( () => {
    if( !data?.Page?.meta?.dataCaptureEventList ){
      return;
    }

    utils.onResponseDataCapture( data.Page.meta.dataCaptureEventList );
  }, [data?.Page?.meta?.dataCaptureEventList] );

  return [invoke, { loading, data, error }];
};

/**
 * Handles errors and updates the application state accordingly.
 *
 * @param {object} [data] - An object containing error information and configuration.
 * @param {object} [data.error] - The error object.
 * @param {object} [data.error.networkError] - Network error details.
 * @param {number} [data.error.networkError.statusCode] - The HTTP status code of the error.
 * @param {boolean} [data.enableSecurityErrorPage] - A flag indicating whether to display a security error page.
 * @param {string[]} [data.securityErrorStatusCodes] - An array of status codes that indicate a security error.
 * @param {object} [methods] - An object containing methods to update the application state.
 * @param {function} [methods.setPageData] - A function to set the page data.
 * @param {function} [methods.setError] - A function to set the error state.
 * @param {function} [methods.setLoading] - A function to set the loading state.
 *
 * @returns {void}
 */
export const onError = ( data, methods ) => {
  const { error, enableSecurityErrorPage, securityErrorStatusCodes } = data || {};
  const { setPageData, setError, setLoading } = methods || {};

  if( !setLoading || !setError || !setPageData ){
    return;
  }

  const statusCode = error?.networkError?.statusCode?.toString();
  const isSecurityError = enableSecurityErrorPage && statusCode && securityErrorStatusCodes.includes( statusCode );

  setLoading( false );

  if( isSecurityError ){
    setPageData( { data: null, loading: false, error: { ...error, isFatal: true } } );
    return;
  }

  setError( error );
};

/**
 * @method onResponseDataCapture
 * @summary This method triggers datacapture events on a mutation's response
 * @param { Object } data muation response
 */
// TODO Move to useLayerHostMeta processor
export const onResponseDataCapture = ( dataCaptureEventList ) => {
  if( !hasItems( dataCaptureEventList ) ){
    return;
  }

  dataCaptureEventList.forEach( ( dataCaptureEvent ) => {
    const data = deepmerge( {}, { dataCapture: dataCaptureEvent } );
    processEvents( data, dataCaptureEvent.clientEvent?.toLowerCase?.() );
  } );
};
