import React from 'react';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import OverLayContainer from '@ulta/core/components/OverLayContainer/OverLayContainer';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { CLIENT_ACTION_TYPES, DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import datacapture from '@ulta/core/utils/datacapture/datacapture';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import { handleRedirect } from '@ulta/core/utils/handleLocation/handleLocation';
import { DXL_QUERY_TYPE, getQueryType, queryProcessor } from '@ulta/core/utils/queryProcessor/queryProcessor';
import { isFunction } from '@ulta/core/utils/types/types';

import constants from '@ulta/utils/constants/constants';
import { dispatchLegacyLogin } from '@ulta/utils/dispatchLegacyLogin/dispatchLegacyLogin';
import { copyTextToClipboard } from '@ulta/utils/Formatters/Formatters';
import { medalliaOpenFeedbackModal } from '@ulta/utils/medallia/medallia';

import { AUTH0_SCREEN } from '../auth/auth';
import * as utils from './clientActionProcessor';

/**
 * Function to process the action received from dxl reponse
 * it sets action to setQuery if there is a graphQl in the action
 * if pop is true in the action response then if it closes the ovelay
 * and then invokes handleDXLNavigationType
 *
 * @param {object} data arguments
 * @param {object} data.action DXL action
 * @param {object} methods functions to call
 * @param {function} methods.setQuery setQuery sets the graphql in LayerHost
 * @param {function} methods.openOverlay openOverlay from the OverlayProvider
 * @param {function} methods.closeOverlay closeOverlay from the OverlayProvider
 * @param {function} methods.refetch refetch page level graphQL
 */
export const actionProcessor = ( data, methods ) => {
  const { action = {} } = handleEmptyObjects( data );
  const { setQuery, setMutation, openOverlay, closeOverlay, refetch } = methods || {};

  const preppedQuery = !action.navigationType && queryProcessor( { action } );

  if( preppedQuery?.isMutation ){
    if( !preppedQuery?.variables?.url ){
      preppedQuery.variables = preppedQuery.variables || {};
      preppedQuery.variables.url = { path: global.location.pathname };
    }
    setMutation?.( preppedQuery );
  }
  else if( !!preppedQuery?.graphql ){
    setQuery?.( queryProcessor( { action } ) );
  }

  // to close the overlay - had to do it this way because of how app handles
  // history - we want to refetch the page when we receive pop = true
  // used specifically for checkout page for validate address with vertex
  if( action.pop ){
    closeOverlay?.();
  }

  // we want to use this to handle the visual display of prepopulated data
  utils.handleDXLNavigationType( { action }, { openOverlay, refetch } );
};

/**
  * handleDXLNavigationType - is used to handle the DXL navigation type
  *
 * @param {object} data arguments
 * @param {object} data.action DXL action
 * @param {object} methods methods
 * @param {function} methods.setQuery setQuery sets the graphql in LayerHost
 * @param {function} methods.openOverlay openOverlay from the OverlayProvider
 * @param {function} methods.closeOverlay closeOverlay from the OverlayProvider
 * @param {function} methods.refetch refetch page level graphQL
 */
export const handleDXLNavigationType = ( data, methods ) => {
  const { action = {} } = handleEmptyObjects( data );
  const { openOverlay, refetch } = methods || {};

  const { graphql, navigationType, content, url, variables = {} } = handleEmptyObjects( action );
  const { previewDate } = variables.moduleParams || {};

  if( !navigationType ){
    return false;
  }

  // Accounts for if the content passed is an OverLayContainer, we need to supress the
  // OverlayFlyout header
  const showDynamicFlyoutHeader = content?.moduleName === OverLayContainer.displayName && !graphql;

  let returnType = false;
  // we want to use this to handle the visual display of prepopulated data
  switch ( navigationType ){
    case DXL_NAVIGATION_TYPE.Flyout:
    case DXL_NAVIGATION_TYPE.Modal:
      const alignment = navigationType === DXL_NAVIGATION_TYPE.Flyout && 'right';

      openOverlay( {
        type: navigationType,
        crossButtonVisibility: true,
        ...( alignment && { alignment } ),
        ...( content && { content: <AsyncComponent { ...content } /> } ),
        ...( graphql && { action } ),
        ...( showDynamicFlyoutHeader && { showDynamicFlyoutHeader } )
      } );
      returnType = true;
      break;
    case DXL_NAVIGATION_TYPE.Redirect:
    case DXL_NAVIGATION_TYPE.Push:
      handleRedirect( { url, previewDate } );
      returnType = true;
      break;
    case DXL_NAVIGATION_TYPE.Refresh:
      refetch && refetch();
      returnType = true;
      break;
  }

  return returnType;
};

/**
 * Provides a hook to process client action types
 * @returns {object} handler - processClientActionType
 */
export const useClientActionType = () => {
  const { user } = useUserContext();

  const handler = React.useCallback( ( data, methods ) => {
    utils.processClientActionType( { ...data, user }, methods );
  }, [user.resolved, user] );

  return { processClientActionType: handler };
};

/**
 * Calls script based on client action type
 *
 * @param {object} action
 */
export const processClientActionType = ( data, methods ) => {
  const { action, user = {} } = handleEmptyObjects( data );
  const { closeOverlay, setViewType, getAuthUrl } = methods || {};
  const { clientActionType } = action || {};

  if( !clientActionType ){
    return;
  }

  // We need to check if the user object exists and if the user has unresolved state, if not we default
  // to letting the action type run. This is because not every caller injects a user object and if they
  // don't the assumption is we do not need to wait for user resolution before running the action.
  const isUserResolved = !!user.loginType ? user.resolved : true;

  switch ( clientActionType ){
    case CLIENT_ACTION_TYPES.GLADLY_CHAT_CLICK:
      closeOverlay();
      global.chatWidgetShowHide && global.chatWidgetShowHide( true );
      break;
    case CLIENT_ACTION_TYPES.LEGACY_LOGIN:
      dispatchLegacyLogin( global.location.href );
      break;
    case CLIENT_ACTION_TYPES.MEDALLIA_FEEDBACK_CLICK:
      medalliaOpenFeedbackModal();
      break;
    case CLIENT_ACTION_TYPES.COPYTEXT_CLICK:
      copyTextToClipboard( action?.copyText );
      break;
    case CLIENT_ACTION_TYPES.CONDITIONAL_VIEW_RENDER1:
    case CLIENT_ACTION_TYPES.CONDITIONAL_VIEW_DEFAULT:
      utils.renderConditionalView( { action }, { setViewType } );
      break;
    case CLIENT_ACTION_TYPES.WIZARD_VIEW:
      break;
    case CLIENT_ACTION_TYPES.OVERLAY_DISMISS:
      closeOverlay();
      break;
    case CLIENT_ACTION_TYPES.AUTH_SIGNIN:
      isUserResolved && !user.loginStatus && handleRedirect( { url: getAuthUrl() } );
      break;
    case CLIENT_ACTION_TYPES.AUTH_SIGNUP:
      isUserResolved && !user.loginStatus && handleRedirect( { url: getAuthUrl( { screen: AUTH0_SCREEN.Signup } ) } );
      break;
    case CLIENT_ACTION_TYPES.AUTH_CHECKOUT_SIGNIN_CLICK:
      isUserResolved && !user.loginStatus && handleRedirect( { url: getAuthUrl( { screen: AUTH0_SCREEN.Checkout } ) } );
    default:
      return null;
  }
};

/**
 * Processes overlay close action
 * @param {object} data
 * @param {object} data.closeActionModel Contains data/methods for processing a back action
 * @returns {Promise} finishes when action has processed
 */
export const processCloseAction = async( data ) => {
  const { closeActionModel = {}, userCancelled = {} } = handleEmptyObjects( data );
  closeActionModel.current = closeActionModel.current || {};

  const { invokeAction, invokeMutation, onBeforeClose, onAfterClose } = closeActionModel.current.methods || {};
  const { action } = closeActionModel.current.data || {};


  return new Promise( resolve => {
    // Execute onBeforeClose
    if( isFunction( onBeforeClose ) ){
      onBeforeClose();
    }

    // Clear timer, execute onAfterClose and resolve promise
    const onActionComplete = ( response ) => {
      clearInterval( closeActionModel.current.interval );

      if( isFunction( onAfterClose ) ){
        onAfterClose( response );
      }

      resolve();
    };

    // Determine query type and bind action handler
    const isMutation = getQueryType( { query: action?.graphql } ) === DXL_QUERY_TYPE.Mutation;
    const actionHandler = isMutation ? invokeMutation : invokeAction;

    // only process dataCapture if user has initiated close and action.dataCaptureData is defined
    if( userCancelled.current === true && action?.dataCaptureData ){
      datacapture.processEvents( { dataCapture: { ...action.dataCaptureData } }, constants.DATACAPTURE_EVENT_TYPE.click );
    }

    if( userCancelled.current !== true || !action?.graphql || !isFunction( actionHandler ) ){
      onActionComplete();
      return resolve();
    }

    action.customHeaders = action.customHeaders || [];
    action.customHeaders.push( { key: 'X-ULTA-GRAPH-MODULE-NAME', value: 'DynamicOverlayCloseAction' }, );

    // Invoke query/mutation
    actionHandler( action );

    // Set interval that polls loading status
    closeActionModel.current.ticks = 0;
    const intervalHanlder = composeBackActionInterval( { closeActionModel }, { resolve, onActionComplete } );
    closeActionModel.current.interval = setInterval( intervalHanlder, CLOSE_ACTION_RESPONSE_TICK );
  } );
};

/**
 * Interval handler that listens to loading state and determines when the flyout action has completed
 * @param {object} data - Arguments
 * @param {object} data.closeActionModel - Contains data/methods for processing a back action
 * @param {object} methods - Methods
 * @param {function} methods.resolve - Promise resolution callback
 * @param {function} methods.onActionComplete - Close action after close callback
 * @returns {function} - Process back action interval handler
 */
export const composeBackActionInterval = ( data, methods ) => () => {
  const { closeActionModel = {} } = data || {};
  const { resolve, onActionComplete } = methods || {};

  closeActionModel.current = closeActionModel.current || {};
  const { loading } = closeActionModel.current.data || {};

  if( !isFunction( onActionComplete ) || !isFunction( resolve ) ){
    return;
  }

  // Increase ticks and bail on max timeout
  if( ++closeActionModel.current.ticks === Math.floor( CLOSE_ACTION_RESPONSE_TIMEOUT / CLOSE_ACTION_RESPONSE_TICK ) ){
    onActionComplete( { timeout: true } );
    return;
  }

  if( loading !== false ){
    return;
  }

  // When loading === false, we can cleanup
  onActionComplete();
};

/**
 * renderConditionalView method to toggle Approval responsepage and Credit Report
 * @param {action} data.action action
 * @param {method} methods.setViewType setViewType method
 */
export const renderConditionalView = ( data, methods ) => {
  const { action } = data || {};
  const { setViewType } = methods || {};

  const viewType = action?.clientActionType?.match( /::([^:]+)$/ )[1];
  // Early return
  if( !setViewType || !viewType ){
    return;
  }
  setViewType( viewType );
};

/**
 * @const {number} CLOSE_ACTION_RESPONSE_TIMEOUT - Timeout in ms for back action to complete
 */
export const CLOSE_ACTION_RESPONSE_TIMEOUT = 8000;

/**
  * @const {number} CLOSE_ACTION_RESPONSE_TICK - Interval in ms to check for back action completion
  */
export const CLOSE_ACTION_RESPONSE_TICK = 50;
