/**
 * This utility is used to prompt the user to share their location with the site for personalized experience.
 *
 * @module utils/GetLocation
 * @memberof -Common
 */

// Paramteres to getCurrentPosition method
var geoLocationOptions = {
  enableHighAccuracy: true, // false | true
  timeout: 60000, // amount of time before the error callback is invoked.
  maximumAge:30000 // maximum cached position age.
};

/**
 * Represents a success handler function
 *
 * @method
 * @param {function} resolve - resolve function to resolve promise with user location details
 * @returns user's geolocation object
 */
export const getPositionSuccess = ( resolve ) => {
  return function( position ){
    resolve( position );
  };
};

/**
 * Represents a failure handler function
 *
 * @method
 * @param {function} resolve - resolve function to resolve promise
 * @returns undefined if geolocation object not found
 */
export const getPositionError = ( resolve ) => {
  return function( err ){
    resolve( undefined );
  };
};


/**
 * Represents a GeoLocation Component
 *
 * @method
 * @param {function} successMethod - function resolves with user location object
 * @param {function} failureMethod - function resolves with undefined if location object unavailable
 * @returns user current location object if geolocation object is not null
 */
export const getGeoLocation = ( successMethod = getPositionSuccess, failureMethod = getPositionError ) => {
  const geolocation = navigator.geolocation;
  return new Promise( ( resolve ) => {
    if( !geolocation ){
      // call failureMethod method the geolocation object is not available
      failureMethod( resolve )();
    }
    else {
      // call HTML5 getCurrentPosition method to get users current positions
      geolocation.getCurrentPosition(
        successMethod( resolve ),
        failureMethod( resolve ),
        geoLocationOptions

      );
    }
  } );
};