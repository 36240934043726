import { CLIENT_ACTION_TYPES } from '@ulta/core/utils/constants/action';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

/**
 * These utils are made for action related stuffs
 *
 * @module utils/actionUtils
 * @memberof -Common
 */

/**
 * Find if the action type is button
 * @param  {object} action - action object
 * @return {boolean} true/false
 */
export const isButton = ( action ) => {
  return !!( action && ( action.label && action.navigationType && action.graphql ) );
};

/**
   * Find if the clientActionType type is for auth0
   * @param  {object} action - action object
   * @return {boolean} true/false
   */
export const isAuthButton = ( action ) => {
  if( !action?.label ){
    return false;
  }

  const { clientActionType } = action;
  return [CLIENT_ACTION_TYPES.AUTH_SIGNIN, CLIENT_ACTION_TYPES.AUTH_SIGNUP].includes( clientActionType );
};

/**
   * Find if the clientActionType type exists in the defined list CLIENT_ACTION_TYPES
   * @param  {object} action - action object
   * @return {boolean} true/false
   */
export const isClientAction = ( action ) => {
  const { label, clientActionType } = handleEmptyObjects( action );
  if( !label || !clientActionType ){
    return false;
  }

  return Object.values( CLIENT_ACTION_TYPES ).includes( clientActionType );
};

/**
   * Find if only icon is present
   * @param  {object} props -  object
   * @return {boolean} true/false
   */
export const isOnlyIcon = ( props ) => {
  return !!( !props.action?.label && props.icon );
};