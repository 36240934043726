/**
 * Will ensure that the passed in value always returns an object for desctructuring
 * where null values are flipped to undefined
 * @template T
 * @param {T} data - Arguments to sanitize
 * @returns {T} - Sanitized object
 */
export const handleEmptyObjects = ( data ) => {
  if( typeof data !== 'object' || data === null ){
    return {};
  }

  return Object.keys( data ).reduce( ( acc, key ) => ( {
    ...acc,
    [key]: data[key] ?? undefined
  } ), {} );
};

export const isEmptyObject = ( data )=>{
  return !( !!data && Object.keys( data ).length > 0 );
};