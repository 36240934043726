import { useCallback, useEffect, useRef, useState } from 'react';

import deepmerge from 'deepmerge';

import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useAuthContext } from '@ulta/core/providers/AuthProvider/AuthProvider';
import { isOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { processClientActionType } from '@ulta/core/utils/clientActionProcessor/clientActionProcessor';

import { useLayerHostContext } from '../../providers/LayerHostProvider/LayerHostProvider';
import { usePageDataContext } from '../../providers/PageDataProvider/PageDataProvider';
import { DXL_NAVIGATION_TYPE } from '../../utils/constants/action';
import { devLogger, LOG_TOPIC } from '../../utils/devMode/devMode';
import gQL from '../../utils/graphql/queries/cms/noncachedcms';
import { handleEmptyObjects } from '../../utils/handleEmptyObjects/handleEmptyObjects';
import { queryProcessor } from '../../utils/queryProcessor/queryProcessor';
import { useDXLQuery } from '../useDXLQuery/useDXLQuery';
import { getRequiredClientParams } from '../useLayerHostAction/useLayerHostAction';
import * as utils from './useSessionAction';

/**
 * Manages session data request/response life cycle
 * @param {object} data - arguments
 * @param {object} methods - methods
 * @returns {function} - processSessionAction
 */
export const useSessionAction = ( data, methods ) => {
  const {
    isInitialSessionPing = {},
    isProcessingAuthAction = {},
    refetchPageAfterResolved = {},
    sessionUpdateRequested = {},
    user = {}
  } = handleEmptyObjects( data );

  const { setUser, setDxlUser } = methods || {};

  const { getAuthUrl } = useAuthContext();

  // Disaster scenarios
  const { incrementSessionAttempts, killSwitchActivated } = useLayerHostContext();
  const { DONOTUSE_broadcastInitAction, processProtectedPageAction, setPageData, isRestrictedPage } = usePageDataContext();

  // When requestStackResolving=false it means we're idle
  const { requestStackResolving } = useLayerHostContext();
  const { refetchRef } = usePageDataContext();
  const { adoptSessionExternally } = useAppConfigContext();

  // If the sessionAction has been dispatched or not
  const sessionActionDispatched = useRef( false );

  // Session action + DXL invokation
  const debouncedAction = useRef();
  const [pendingAction, setPendingAction] = useState( null );
  const [sessionAction, setSessionAction] = useState( { graphql: gQL } );
  const [queryHandler, { loading, data: userData, error }] = useDXLQuery(
    sessionAction.graphql,
    sessionAction.config,
    true,
    'useSessionAction'
  );

  // Session Management Flow
  // ----------------------
  // 1. Listen for session action from any layer -> set pendingAction
  // 2. Ignore further pending actions while request stack is resolving -> prep/set a single sessionAction
  // 3. Execute the prepared sessionAction
  // 4. Response from DXL -> update user object

  // 1. Everytime a layer receives a session action, it will be set as the next "pending session action"
  const processSessionAction = useCallback(
    utils.composeProcessSessionAction(
      { refetchPageAfterResolved, sessionUpdateRequested },
      { setPendingAction }
    ),
    [setPendingAction]
  );

  // 2. Once the request stack has resolved, we can process the next session action
  useEffect( () => {
    utils.handleIncomingSessionActions(
      { user, pendingAction, sessionUpdateRequested, refetchPageAfterResolved, requestStackResolving },
      { setSessionAction, setPendingAction, setUser }
    );
  }, [user, pendingAction, requestStackResolving, sessionUpdateRequested, setSessionAction, setUser, setPendingAction] );

  // 3. Execute session action
  useEffect( () => {
    utils.executeSessionAction(
      {
        killSwitchActivated,
        sessionAction,
        sessionUpdateRequested,
        sessionActionDispatched,
        debouncedAction,
        adoptSessionExternally
      },
      { queryHandler, incrementSessionAttempts }
    );
  }, [sessionAction, sessionUpdateRequested, queryHandler, incrementSessionAttempts, adoptSessionExternally] );

  // 4. Handle response, update user
  useEffect( () => {
    utils.handleSessionDataResponse(
      {
        error,
        isInitialSessionPing,
        isProcessingAuthAction,
        isRestrictedPage,
        loading,
        sessionActionDispatched,
        sessionUpdateRequested,
        userData
      },
      { setDxlUser, processProtectedPageAction, DONOTUSE_broadcastInitAction, refetchRef, setPageData, getAuthUrl }
    );
  }, [loading, userData, error] );

  // Return processSessionAction handler
  return [processSessionAction];
};

/**
 * API method exposed for capturing incoming session actions. This is required so that
 * we can easily short circuit incoming session actions if the session is already being updated.
 *
 * We want to store the incoming session action in state so that we can process it once the current
 * request stack has resolved.
 *
 * This way, all of our layer host's can focus on one responsibility, which is tracking when there is a "new"
 * incoming session action, then they report it here and this is where we process it and ignore if we're already
 * updating our session.
 *
 * @param {object} data - arguments
 * @param {object} methods - methods
 * @returns {function} - processSessionAction
 */
export const composeProcessSessionAction = ( data, methods ) => ( fnData ) => {
  const { sessionUpdateRequested = {} } = handleEmptyObjects( data );
  const { setPendingAction } = methods || {};
  const { sessionAction = {} } = fnData || {};

  if( sessionUpdateRequested.current || !sessionAction?.graphql || !setPendingAction ){
    return;
  }

  setPendingAction( sessionAction );
};

/**
 * Responsible for short circuiting incoming session actions if the session is already being updated.
 *
 * If we are in the position to process a session action, this function also prepares the session action
 * for dispatch to DXL.
 *
 * A "pending" session action will be validated by `handleIncomingSessionActions`, if there's already
 * a session action being processed, or the request stack is resolving, the incoming action will be ignored.
 *
 * @param {object} data - arguments
 * @param {object} methods - methods
 */
export const handleIncomingSessionActions = ( data, methods ) => {
  const {
    user = {},
    pendingAction = {},
    sessionUpdateRequested = {},
    requestStackResolving
  } = handleEmptyObjects( data );
  const { setUser, setSessionAction, setPendingAction } = methods || {};

  if( !pendingAction?.graphql || !setUser || !setSessionAction || !setPendingAction ){
    return;
  }

  if( requestStackResolving ){
    devLogger( {
      topic: LOG_TOPIC.Session,
      title: '[Session] handleIncomingSessionActions() short-circuit: requestStackResolving',
      value: {
        ...data,
        requestStackResolving,
        sessionUpdateRequested: sessionUpdateRequested.current
      }
    } );

    return;
  }

  if( sessionUpdateRequested.current ){
    devLogger( {
      topic: LOG_TOPIC.Session,
      title: '[Session] handleIncomingSessionActions() short-circuit: sessionUpdateRequested',
      value: {
        ...data,
        requestStackResolving,
        sessionUpdateRequested: sessionUpdateRequested.current
      }
    } );

    return;
  }

  // Halt processing any requests or further sessionActions with this ref flag and setting user.resolved to false
  sessionUpdateRequested.current = true;
  setUser( user => ( { ...user, resolved: false } ) );

  // Prepare session action w/ client params
  const action = getRequiredClientParams( {
    action: deepmerge( pendingAction, {} ),
    user,
    location: global.location
  } );

  // Query processor will prepare the action for DXL/Apollo
  const preppedAction = queryProcessor( { action } );

  devLogger( {
    topic: LOG_TOPIC.Session,
    title: '[Session] Requested',
    value: { user, preppedAction }
  } );

  setSessionAction( action );
  setPendingAction( null );
};

/**
 * Execute session action
 * @param {object} data.sessionUpdateRequested - sessionUpdateRequested ref
 */
export const executeSessionAction = ( data, methods ) => {
  const {
    adoptSessionExternally,
    debouncedAction = {},
    killSwitchActivated = {},
    sessionAction = {},
    sessionActionDispatched = {},
    sessionUpdateRequested = {}
  } = data || {};

  const { incrementSessionAttempts, queryHandler } = methods || {};

  if( !sessionAction.config || killSwitchActivated.current || adoptSessionExternally ){
    return;
  }

  if( sessionActionDispatched.current || !sessionUpdateRequested.current ){
    devLogger( {
      topic: LOG_TOPIC.Session,
      title: '[Session] executeSessionAction() short-circuit: sessionActionDispatched/sessionUpdateRequested',
      value: {
        ...data,
        sessionActionDispatched: sessionActionDispatched.current,
        sessionUpdateRequested: sessionUpdateRequested.current
      }
    } );

    return;
  }

  sessionActionDispatched.current = true;
  sessionAction.variables.queryId = Math.random();

  incrementSessionAttempts( sessionAction );

  clearTimeout( debouncedAction.current );
  debouncedAction.current = setTimeout( () => {
    queryHandler( sessionAction );
  }, 0 );
};


/**
 * Handle session data response
 * @param {object} data.sessionUpdateRequested - sessionUpdateRequested ref
 */
export const handleSessionDataResponse = ( data, methods ) => {
  const {
    isInitialSessionPing = {},
    isProcessingAuthAction = {},
    isRestrictedPage,
    loading,
    sessionActionDispatched = {},
    sessionUpdateRequested = {},
    userData
  } = handleEmptyObjects( data );

  const {
    setDxlUser,
    processProtectedPageAction,
    DONOTUSE_broadcastInitAction,
    refetchRef = {},
    getAuthUrl
  } = methods || {};

  // Early return when session hasn't been requested
  if( !sessionUpdateRequested.current || !setDxlUser ){
    return;
  }

  // Debugg session request life cycle (this will show loading states and session data)
  devLogger( {
    topic: LOG_TOPIC.Session,
    title: '[Session] handleSessionDataResponse',
    value: {
      ...data,
      sessionUpdateRequested: sessionUpdateRequested.current,
      sessionActionDispatched: sessionActionDispatched.current
    }
  } );

  // While loading or missing data we don't need to process information
  if( loading !== false || !userData ){
    return;
  }

  sessionUpdateRequested.current = false;
  sessionActionDispatched.current = false;

  const { sessionData = {}, initAction, snackBar } = handleEmptyObjects( userData?.Page?.meta );

  // If DXL sends refresh or another sessionAction, session has not been established
  if( initAction?.navigtionType === DXL_NAVIGATION_TYPE.Refresh ){
    devLogger( 'sessionAction response returned another sessionAction, possible recursion detected', 1, LOG_TOPIC.Session );
    isInitialSessionPing.current = true;
    refetchRef.current?.();
    return;
  }

  // We always want to update the user object
  setDxlUser( dxlUser => ( { ...dxlUser, ...sessionData, resolved: true } ) );

  // We need to handle 2 different flows for initActions/flyouts from either a sessionAction or a postAuth0Action:
  // 1. If DXL sesnds an initAction/flyout with graphql, we assume this is a protected page and
  //    we need to pop the sign in overlay.

  // 2. If DXL sends an initAction/flyout with an overlay in the content, we assume this is the
  //    post auth new account creation overlay.

  // For either of these flows, we only want to process the initAction and use the Page object as a proxy
  // to dispatch side effects. This is done because initActions are tied to a LayerHost instances, so for
  // general side effects that come down and are not tied to a specific LayerHost instance, we need use the
  // Page (top most layerhost) because it's always going to be there.

  const hasOverlay = isOverlay( initAction?.navigationType );
  const isProtectedOverlay = hasOverlay && isRestrictedPage && initAction?.graphql;
  const isPostAuthOverlay = hasOverlay && isProcessingAuthAction.current;
  const isProtectedClientAction = !hasOverlay && !!initAction?.clientActionType;
  const isProtectedInitAction = !!initAction?.graphql;

  const shouldBroadcast = !!snackBar || isPostAuthOverlay || isProtectedInitAction;
  const protectedFlow = isProtectedOverlay || isProtectedInitAction;

  // 1. Protected client action type ( redirects to auth0 )
  if( isProtectedClientAction ){
    processClientActionType( { action: initAction }, { getAuthUrl } );
  }
  // 2. Protected page flow sign in overlay (THIS IS SUBJECT TO GO AWAY)
  else if( isProtectedOverlay ){
    processProtectedPageAction( { initAction } );
  }
  // 3. Post auth0 action ( new account creation overlay -or- protected page content ) + snackbar
  else if( shouldBroadcast ){
    DONOTUSE_broadcastInitAction( { initAction, snackBar, protectedFlow } );
  }

  // Reset the isProcessingAuthAction flag
  isProcessingAuthAction.current = false;
};
