import { getRetailerIdFromCookie } from '../../hooks/useRetailerVisitorId/useRetailerVisitorId';
import { getUserFromStorage, USER_PROFILE_KEYS } from '../../providers/UserContextProvider/UserContextProvider';
import { constants } from '../constants/constants';
import { isServer } from '../device_detection/device_detection';
import { devLogger } from '../devMode/devMode';
import { handleEmptyObjects } from '../handleEmptyObjects/handleEmptyObjects';
import { getStorage, removeStorage, setStorage } from '../storage/storage';
import { STORAGE_KEY } from '../storageKeys/storageKeys';
import tealiumProcessor from '../tealium/tealium';
import * as utils from './datacapture';

/**
 * This method holds the logic to trigger the event if there is no
 * unresolveData and push it to the queue if there is unresolvedData
 *
 * 1. Early exit if there's no data
 * 2.
 * 3. Check if we've landed on the destination url for a typehead dc event flow
 * 4. Attempt to process unresolved attributes for an event. DXL doesn't know everything about a client so we
 *    populate requested keys from DXL with client side attributes
 * 5. If there are no unresolved attributes for an event, we can just move on to trigger the event.
 * @param {object} data
 */
export const processDataCaptureEvent = ( data ) => {
  /*
   * 1. Fail gracefully
   */
  if( !data ){
    return;
  }

  /*
   * 2. Evaluate data capture readiness
   */
  const event = utils.evaluateDataCaptureReadiness( data );

  /*
   * 3. Check type ahead status
   */
  if( !isServer() && datacapture.typeAheadStatus === TYPEAHEAD_DATA_STATUS.Init ){
    utils.emitTypeAheadData( { currentPath: global.location?.pathname } );
  }

  /*
   * 4. If we have unresolved data, process it and start the interval until the queue is empty
   */
  if( event.unresolvedData?.length > 0 ){
    datacapture.dataCaptureQueue.push( event );

    const triggerResolution = global.setInterval( function(){
      utils.triggerEvents();

      /*
       * Every 500 milliseconds try to resolve client side data,
       * once all data are resolved exit the timer
       */
      if( datacapture.dataCaptureQueue.length === 0 ){
        global.clearInterval( triggerResolution );
      }
    }, utils.DC_UNRESOLVED_PROCESSING_INTERVAL );
  }
  else {
    /*
     * 5. Otherwise just trigger the event
     */
    utils.triggerEvent( event );
  }
};

/**
 * @const {number} DC_UNRESOLVED_PROCESSING_INTERVAL - Interval to process unresolved data
 */
export const DC_UNRESOLVED_PROCESSING_INTERVAL = 500;

/**
 * @let {boolean} DATACAPTURE_READY - used to determine when data capture is ready
 */
export let DATACAPTURE_READY = false;

/**
 * @let {boolean} HAS_SET_READINESS_EVENT - used to determine if event has been set
 */
export let HAS_SET_READINESS_EVENT = false;

/**
 * @const {string} DATACAPTURE_READINESS_FLAG - flag used to identify type of event for when data capture is ready
 */
export const DATACAPTURE_READINESS_FLAG = 'DATACAPTURE_READINESS_FLAG';

/**
 * Decorate events to wait for dc readiness when enabled
 * @param {object} data
 */
export const evaluateDataCaptureReadiness = ( data ) => {
  if( isServer() || global.__ENABLE_DC_EVENT_READINESS__ !== true || typeof data !== 'object' || !data ){
    return data;
  }

  // Adds unresolved data so we pause until DATACAPTURE_READINESS_FLAG fires from Tealium
  let event = { ...data };
  event.unresolvedData = event.unresolvedData || [];
  event.unresolvedData.push( { service: 'Client', serviceDataPath: DATACAPTURE_UNRESOLVED_KEY.DataCaptureReady } );

  return event;
};

/**
 * Register event listener for data capture readiness when the conditions are met
 */
export const setDataCaptureReadinessEvent = () => {
  if( isServer() || global.__ENABLE_DC_EVENT_READINESS__ !== true || utils.HAS_SET_READINESS_EVENT ){
    return;
  }

  HAS_SET_READINESS_EVENT = true;

  const body = global.document.querySelector( 'body' );
  body.addEventListener( utils.DATACAPTURE_READINESS_FLAG, () => {
    DATACAPTURE_READY = true;
  } );
};

setDataCaptureReadinessEvent();

/**
 * This method invokes processDataCaptureEvent to trigger the event
 * depending on the eventType passed as param
 * @param  {Object} data
 * @param  {String} eventType
 * @param  {Function} handleInteractionEvents
 * @param  {Object} additionalEventAttributes
 */
export const processEvents = ( data, eventType, handleInteractionEvents, additionalEventAttributes = {} ) => {
  if( !data?.dataCapture ){
    return;
  }

  switch ( eventType ){
    case constants.DATACAPTURE_EVENT_TYPE.viewport:
      utils.processDataCaptureEvent( {
        ...data.dataCapture,
        handleInteractionEvents
      } );
      break;
    case constants.DATACAPTURE_EVENT_TYPE.click:
      utils.processDataCaptureEvent( {
        ...data.dataCapture,
        handleInteractionEvents,
        additionalEventAttributes,
        allowMultiple: true
      } );
      break;
    case constants.DATACAPTURE_EVENT_TYPE.response:
      utils.processDataCaptureEvent( {
        ...data.dataCapture,
        handleInteractionEvents,
        additionalEventAttributes
      } );
      break;
  }
};

export const IFRAMED_DATA_CAPTURE_EVENT = 'IFRAMED_DATA_CAPTURE_EVENT';

/**
 * This method takes the eventData loops through the integrations,
 * fetches the integration processor and triggers the event
 * @param  {Object} data
 */
export const triggerEvent = ( data ) => {
  const { category, dataLayer = {}, allowMultiple, ...rest } = data || {};

  const keys = Object.keys( dataLayer );

  if( keys.length === 0 || dataLayer._hasTriggered ){
    return;
  }

  // Set a has triggered flag to prevent duplicate events. This is not ideal because
  // the object should be immutable in practive but we don't have a straight forward
  // solution for globally tracking events that have been triggered.
  if( !allowMultiple ){
    dataLayer._hasTriggered = true;
  }


  keys.forEach( ( key ) => {
    const eventProcessor = utils.fetchEventProcessor( key );
    eventProcessor?.triggerEvent( { category, dataLayer: dataLayer[key], ...rest } );
  } );
};

/**
 * This method takes the integrationName and sends the corresponding processor
 * @param {String} key
 */
export const fetchEventProcessor = ( key ) => {
  const processor = key?.toLowerCase();

  if( !processor ){
    return;
  }

  return PROCESSOR_MAP[processor];
};

/**
 * @const {object} PROCESSOR_MAP - holds the mapping of integrationName and processor
 */
export const PROCESSOR_MAP = {
  tealium: tealiumProcessor
};


/**
 * This method loops through the array , resolves the client data, if all data are resolved then
 * trigger the event if there is data yet to be resolved then it adds the event back to the queue
 */
export const triggerEvents = () => {
  const temporaryQueue = [];
  let eventData = datacapture.dataCaptureQueue.shift();

  const user = getUserFromStorage();

  while ( eventData ){
    const builtData = utils.resolveClientDataLayer( { ...eventData, user } );

    if( builtData?.unresolvedData?.length === 0 ){
      utils.triggerEvent( builtData, builtData.handleInteractionEvents );
    }
    else {
      temporaryQueue.push( builtData );
    }
    eventData = datacapture.dataCaptureQueue.shift();
  }

  datacapture.dataCaptureQueue = temporaryQueue.slice();
};

/**
 * this method loops through the unresolved data array resolves
 * the data and removes that entry from the array
 *
 * @param  {Object} data
 */
export const resolveClientDataLayer = ( data ) => {

  if( typeof data !== 'object' || !data ){
    return {};
  }

  const localUnresolvedData = [];

  const {
    user,
    category: originalCategory,
    dataLayer: originalDataLayer,
    unresolvedData: originalUnresolvedData,
    additionalEventAttributes,
    ...rest
  } = data;

  const clonedData = JSON.parse( JSON.stringify( { originalCategory, originalDataLayer, originalUnresolvedData } ) );

  const {
    originalCategory: category,
    originalDataLayer: dataLayer,
    originalUnresolvedData: unresolvedData = []
  } = clonedData;

  unresolvedData.forEach( ( elementData ) => {
    if( elementData?.service?.toLowerCase() !== 'client' ){
      return;
    }

    let dataValue = null;

    const { serviceDataPath, integrationsforChannel = [] } = elementData;

    switch ( serviceDataPath ){
      case DATACAPTURE_UNRESOLVED_KEY.DataCaptureReady:
        dataValue = utils.DATACAPTURE_READY;
        break;
      case DATACAPTURE_UNRESOLVED_KEY.GTI:
        dataValue = user.gti;
        break;
      case DATACAPTURE_UNRESOLVED_KEY.CookieDeviceId:
        dataValue = getRetailerIdFromCookie();
        break;
      case DATACAPTURE_UNRESOLVED_KEY.KnownGTI:
        dataValue = utils.getLoggedInUserKey( { user, key: USER_PROFILE_KEYS.Gti } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.SearchTerm:
        dataValue = utils.getTypeAheadKey( { key: DATACAPTURE_UNRESOLVED_KEY.SearchTerm } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.SearchType:
        dataValue = utils.getTypeAheadKey( { key: DATACAPTURE_UNRESOLVED_KEY.SearchType } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.SearchIndex:
        dataValue = utils.getTypeAheadKey( { key: DATACAPTURE_UNRESOLVED_KEY.SearchVisualIndex } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.SearchResult:
        dataValue = utils.getTypeAheadKey( { key: DATACAPTURE_UNRESOLVED_KEY.SearchResult } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.UserType:
        dataValue = utils.getUserType( { user } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.UserMemberId:
        dataValue = utils.getLoggedInUserKey( { user, key: USER_PROFILE_KEYS.LoyaltyId } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.UserMemberStatus:
        dataValue = utils.getLoggedInUserKey( { user, key: USER_PROFILE_KEYS.RewardsMemberType } );
        break;
      case DATACAPTURE_UNRESOLVED_KEY.StoreId:
        const storeId = getStorage( {
          secure: false,
          key: STORAGE_KEY.storePersist
        } )?.bopisStoreId;

        dataValue = storeId || '';
        break;
      default:
        dataValue = utils.resolveAdditionalAttributes( elementData?.serviceDataPath, additionalEventAttributes );
        break;
    }

    if( ( dataValue || dataValue === 0 ) && typeof dataValue === 'number' ){
      dataValue = dataValue.toString();
    }

    if( dataValue === '' || dataValue ){
      if( dataValue && typeof dataValue === 'string' ){
        dataValue = dataValue.toLowerCase();
      }

      integrationsforChannel.forEach( ( integration ) => {
        dataLayer[integration.integrationName] = dataLayer[integration.integrationName] || {};
        dataLayer[integration.integrationName][integration.dataKey] = dataValue;
      } );
    }
    else {
      localUnresolvedData.push( elementData );
    }
  } );

  return { category, dataLayer, unresolvedData: localUnresolvedData, additionalEventAttributes, ...rest };
};

/**
 * Loops through unresolved data and resolves based on the values passed
 * @param {object} data Arguments
 * @param {object} data.dataCaptureData Datacapture object
 * @param {object} data.values Values to be resolved
 * @returns {object} resolved data layer
 */
export const patchDataLayer = data => {
  const { dataCaptureData, values } = handleEmptyObjects( data );

  if( !dataCaptureData || !values ){
    return;
  }

  const localUnresolvedData = [];

  const clonedData = JSON.parse( JSON.stringify( dataCaptureData ) ) ;

  const {
    dataLayer,
    unresolvedData = [],
    ...rest
  } = clonedData;

  unresolvedData.forEach( ( elementData ) => {
    if( elementData?.service?.toLowerCase() !== 'client' ){
      return;
    }
    const { serviceDataPath, integrationsforChannel = [] } = elementData;

    let dataValue = values[serviceDataPath];

    if( dataValue !== undefined ){
      if( typeof dataValue === 'string' ){
        dataValue = dataValue.toLowerCase();
      }

      integrationsforChannel.forEach( ( integration ) => {
        dataLayer[integration.integrationName] = dataLayer[integration.integrationName] || {};
        dataLayer[integration.integrationName][integration.dataKey] = dataValue;
      } );
    }
    else {
      localUnresolvedData.push( elementData );
    }
  } );

  return { dataLayer, unresolvedData: localUnresolvedData, ...rest };
};

/**
 * this method loops through the unresolved data form values array, resolves
 * the data and removes that entry from the array
 *
 * @param  {Object} data
 */
export const resolveFormValuesDataLayer = ( data ) => {
  const { action } = handleEmptyObjects( data );
  const { dataCaptureData, variables } = action || {};

  if( !dataCaptureData ){
    return {};
  }

  const localUnresolvedData = [];

  const {
    category: originalCategory,
    dataLayer: originalDataLayer,
    unresolvedData: originalUnresolvedData,
    ...rest
  } = dataCaptureData || {};

  const values = variables?.moduleParams;

  const clonedData = JSON.parse( JSON.stringify( { originalCategory, originalDataLayer, originalUnresolvedData } ) );

  const {
    originalCategory: category,
    originalDataLayer: dataLayer,
    originalUnresolvedData: unresolvedData = []
  } = clonedData;

  unresolvedData.forEach( ( elementData ) => {
    if( elementData?.service?.toLowerCase() !== 'client' ){
      return;
    }

    let dataValue = null;

    const { serviceDataPath, integrationsforChannel = [] } = elementData;

    if( values && values[serviceDataPath]?.value ){
      dataValue = values[serviceDataPath]?.value; // for contact form or form fields
    }
    else if( values[serviceDataPath] && typeof values[serviceDataPath] !== 'object' ){
      dataValue = values[serviceDataPath]; // for global module params (gtid,storeId)
    }
    else {
      dataValue = ''; // TBD : needs to be reviewed ( kept it to support the old code )
    }

    if( dataValue === '' || dataValue ){
      if( dataValue && typeof dataValue === 'string' ){
        dataValue = dataValue.toLowerCase();
      }

      integrationsforChannel.forEach( ( integration ) => {
        dataLayer[integration.integrationName] = dataLayer[integration.integrationName] || {};
        dataLayer[integration.integrationName][integration.dataKey] = dataValue;
      } );
    }
    else {
      localUnresolvedData.push( elementData );
    }
  } );
  return { category, dataLayer, unresolvedData: localUnresolvedData, ...rest };
};

/**
 * @const {object} DATACAPTURE_UNRESOLVED_KEY - Unresolved data keys that come down from DXL
 */
export const DATACAPTURE_UNRESOLVED_KEY = {
  DataCaptureReady: 'DataCaptureReady',
  GTI: 'GTI',
  CookieDeviceId: 'CookieDeviceId',
  StorageDeviceId: 'deviceID',
  KnownGTI: 'KnownGTI',
  SearchTerm: 'searchTerm',
  SearchIndex: 'searchIndex',
  SearchVisualIndex: 'searchVisualIndex',
  SearchType: 'searchType',
  SearchResult: 'searchResult',
  StoreId: 'storeId',
  UserType: 'userType',
  UserMemberId: 'userMemberId',
  UserMemberStatus: 'userMemberStatus'
};

/**
 * method to getTypeAheadKey
 * @param {object} data Arguments
 * @param {object} data.key Arguments
 * @returns Resolved datacapture key
 */
export const getTypeAheadKey = ( data ) => {
  const { key } = data || {};
  const value = datacapture.typeAheadData?.[key];
  return value ? String( value ) : '';
};

/**
 * Retrieves user type only after resolution
 *
 * @param {object} data Arguments
 * @param {UserContext} data.user Arguments
 * @returns {string|undefined} Resolved user key
 */
export const getUserType = ( data ) => {
  const { user } = data || {};

  if( !user?.resolved ){
    return undefined;
  }

  return user.loginStatus ? DC_UNRESOLVED_USER_TYPE.Member : DC_UNRESOLVED_USER_TYPE.Guest;
};

/**
 * Retrieves authenticated user key only after resolution
 *
 * @param {object} data Arguments
 * @param {UserContext} data.user Arguments
 * @param {USER_PROFILE_KEYS} data.key USER_PROFILE_KEYS key
 * @returns {string|undefined} Resolved user key
 */
export const getLoggedInUserKey = ( data ) => {
  const { key, user } = data || {};

  if( !user?.resolved ){
    return undefined;
  }

  // Edge case where user profile might not have loyalty ID because
  // the ATG profile does not contain it, it only exists on the global page data object
  if( key === USER_PROFILE_KEYS.LoyaltyId && !user[key] && user.loginStatus ){
    return global.globalPageData?.rewards?.loyaltyId || '';
  }

  return user.loginStatus && !!user[key] ? user[key] : '';
};

/**
 * @const {object} DC_UNRESOLVED_USER_TYPE - Unresolved user type
 */
export const DC_UNRESOLVED_USER_TYPE = {
  Guest: 'guest',
  Member: 'member'
};

/**
 * @method resolveAdditionalAttributes
 * @summary This method takes the serviceDataPath and additionalEventAttributes retrieves
 * the value matching the serviceDataPath from the additionalEventAttributes and returns the match
 * @param  {String} serviceDataPath
 * @param  {Object} additionalEventAttributes
 * @returns the matching value
 */
export const resolveAdditionalAttributes = ( serviceDataPath, additionalEventAttributes ) => {
  return additionalEventAttributes ? additionalEventAttributes[serviceDataPath] : '';
};

/**
 * @method triggerDatacaptureEvent
 * @summary This method triggers datacapture events
 * @param { Object } datacaptureEvent dxl response
 */
export const triggerDatacaptureEvent = ( datacaptureEvent ) => {
  if( !datacaptureEvent ){
    return;
  }

  utils.processEvents( { dataCapture: datacaptureEvent }, datacaptureEvent?.clientEvent?.toLowerCase() );
};

/**
 * @method triggerScrollDCEvent
 * @summary This method triggers datacapture events
 * @param { Object } datacaptureData dxl response
 */
export const triggerScrollDCEvent = ( datacaptureData ) => {
  const isViewPort = datacaptureData?.clientEvent?.toLowerCase() === constants.DATACAPTURE_EVENT_TYPE.viewport;
  if( datacaptureData && isViewPort ){
    utils.processEvents( { dataCapture: datacaptureData }, datacaptureData?.clientEvent?.toLowerCase() );
  }
};

/**
 * Handle typeahead Data capture across multiple requests
 *
 * Stage 1: We have just executed a search and are saving data before redirecting to the target page
 *    1a. For a keyword search, we will be saving temp data until we hit the product results page
 *
 * Stage 2: We are on the product listing results and need to determine to send events now or wait for a redirect
 *    2a. Check for the existince of `url`
 *    2b. If exists store resolve and store the model
 *    2c. If it doesn't exist, resolve and dispatch
 *
 * Stage 3: If a redirect has occured, we just need to load the stored model and dispatch
 */

/**
 * @method emitTypeAheadData
 * @summary This method will persist or dispatch TypeAhead datacapture data
 * @param {object} data Arguments
 * @param {object} data.typeAheadData contains typeahead datacapture data
 * @param {action} data.searchEvent search action
 * @param {string} data.targetUrl contains URL to redirect
 * @param {string} data.currentPath contains currentPath URL to redirect
 * @param {boolean} data.fireEvent will execute existing event
 */
export const emitTypeAheadData = ( data ) => {
  const { typeAheadData, searchEvent, targetUrl, currentPath, fireEvent } = data || {};

  // Update typeAheadStatus
  datacapture.typeAheadStatus = TYPEAHEAD_DATA_STATUS.Checked;

  // Check for existing data
  let existingData = getStorage( { secure: false, key: STORAGE_KEY.typeAheadDatacapture } );
  const event = searchEvent || existingData?.event;

  // If no event was passed in and nothing was found in local storage, do nothing
  if( !event ){
    return;
  }

  // Stage 1 or Stage 2:
  // -> Stage 1: initiated by a click of item so we will have the target url
  // -> Stage 2: initiated when we detect a destination url and we want to merge that into an existing model
  if( targetUrl ){
    utils.persistTypeAheadData( { event, targetUrl, typeAheadData, existingData } );
    return;
  }
  // Stage 1a:
  // -> keyword search, store to localStorage and wait for next hop
  else if( !existingData ){
    utils.persistTypeAheadData( { event, typeAheadData } );
    return;
  }

  // Stage 3:
  // 1. If the url is our current url, we are ready to fire the event
  // 2. If we have existing data and are on the search page, fireEvent should be true
  if( currentPath === existingData.targetPath || fireEvent ){
    utils.dispatchTypeAheadEvent( { existingData, typeAheadData } );

    if( existingData.targetPath ){
      // we are using this setTimeout to wait for AppConfigProvider eventListeners to set up properly
      setTimeout( () => {
        global.document.dispatchEvent(
          new CustomEvent( constants.EVENTS.UPDATE_APP_CONFIG, {
            detail: {
              config: {
                redirectedSearchTerm: existingData?.typeAheadData?.searchTerm,
                isRedirect: true
              }
            }
          } )
        );
      }, 0 );
    }
  }
};

/**
 * @method persistTypeAheadData
 * @summary This method persist/store TypeAhead datacapture data
 * @param {object} data Arguments
 * @param {object} data.event contains datacapture event
 * @param {string} data.targetUrl contains URL to redirect
 * @param {object} data.typeAheadData contains typeahead datacapture data
 * @param {object} data.existingData contains datacapture event and typeaheaddata
 */
export const persistTypeAheadData = ( data ) => {
  const { event, targetUrl, typeAheadData: updatedTypeAheadData = {}, existingData } = data || {};

  devLogger( {
    title: 'persistTypeAheadData',
    value: { targetUrl, updatedTypeAheadData, existingData },
    collapsed: true
  } );

  const typeAheadData = {
    ...existingData?.typeAheadData,
    ...updatedTypeAheadData
  };

  const updatedValue = {
    ...existingData,
    typeAheadData,
    event
  };

  // If a url is present, we're either calling this from a user clicking on an item
  // or during the initAction redirect to the final destination
  if( targetUrl ){
    const { pathname } = new URL( targetUrl );
    updatedValue.targetPath = pathname;
  }

  setStorage( { secure: false, key: STORAGE_KEY.typeAheadDatacapture, value: updatedValue } );
};

/**
 * @method dispatchTypeAheadEvent
 * @summary This method dispatch TypeAhead datacapture events
 * @param {object} data Arguments
 * @param {object} data.existingData contains datacapture event and typeaheaddata
 * @param {object} data.typeAheadData contains typeahead datacapture data
 */
export const dispatchTypeAheadEvent = ( data ) => {
  const { existingData, typeAheadData: updatedTypeAheadData } = data || {};
  const { event, typeAheadData } = existingData || {};

  devLogger( { title: 'dispatchTypeAheadEvent', value: { typeAheadData, updatedTypeAheadData }, collapsed: true } );

  if( !event || !typeAheadData ){
    return;
  }

  // Sets type ahead data for client resolution
  datacapture.typeAheadData = {
    ...typeAheadData,
    ...updatedTypeAheadData
  };

  const existingSearchCount = datacapture.typeAheadData[DATACAPTURE_UNRESOLVED_KEY.SearchResult];

  // Fallback to '1' if searchCount is not defined
  if( isNaN( parseInt( existingSearchCount, 10 ) ) ){
    datacapture.typeAheadData[DATACAPTURE_UNRESOLVED_KEY.SearchResult] = '1';
  }

  utils.processDataCaptureEvent( event );
  removeStorage( { secure: false, key: STORAGE_KEY.typeAheadDatacapture } );
};

/**
 * @const {object} TYPEAHEAD_DATA_STATUS - Status of typeahead datacapture flow
 */
export const TYPEAHEAD_DATA_STATUS = {
  Init: 'init',
  Checked: 'checked',
  Persist: 'persist',
  Redirect: 'redirect'
};

export const datacapture = {
  typeAheadStatus: TYPEAHEAD_DATA_STATUS.Init,
  typeAheadData: null,
  dataCaptureQueue: [],
  evaluateDataCaptureReadiness,
  processDataCaptureEvent,
  triggerEvent,
  triggerEvents,
  fetchEventProcessor,
  patchDataLayer,
  resolveClientDataLayer,
  resolveFormValuesDataLayer,
  processEvents,
  resolveAdditionalAttributes,
  triggerDatacaptureEvent,
  triggerScrollDCEvent,
  getUserType,
  getResolvedUserKey: getLoggedInUserKey
};

export default datacapture;
