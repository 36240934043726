/**
 * This component is wrapper component for Outer layer integration.
 *
 * @module views/components/StateWrapper
 * @memberof -Common
 */
import React, { useEffect, useRef } from 'react';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { useStyleContext } from '@ulta/core/providers/StyleContextProvider/StyleContextProvider';
import { hasItems } from '@ulta/core/utils/array/array';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';
import * as utils from './StateWrapper';

/**
 * Represents a StateWrapper component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns StateWrapper
 */
export const StateWrapper = function( props ){
  const { modules } = props;
  const elementRef = useRef( null );
  const { setMainWrapperStyle, loaderRealized } = useStyleContext();

  useEffect( () => {
    utils.updateMainWrapperStyle( { modules, elementRef }, { setMainWrapperStyle } );
  }, [loaderRealized.current] );

  if( !hasItems( modules ) ){
    return null;
  }

  return (
    <div ref={ elementRef }
      className='StateWrapper'
    >
      { modules.map( ( component, index ) => (
        <AsyncComponent key={ `${component.id}:${index}` }
          { ...component }
        />
      ) ) }
    </div>
  );
};

/**
 * Updates the main wrapper style with a min-height based on the presence of a loader module.
 *
 * @param {object} data - An object containing the modules and elementRef.
 * @param {object} methods - An object containing the setMainWrapperStyle method.
 *
 */
export const updateMainWrapperStyle = ( data, methods ) => {
  const { modules, elementRef } = handleEmptyObjects( data );
  const { setMainWrapperStyle } = methods;

  if( modules?.length > 0 ){
    const loaderFound = modules.reduce( ( result, module ) => result || utils.containsLoaderModule( { module } ), false );
    if( loaderFound && elementRef.current ){
      setMainWrapperStyle( {
        minHeight: `${elementRef.current?.offsetHeight}px`
      } );
    }
  }
};

/**
 * Checks if a loader module exists within the provided data.
 *
 * @param {object} data - The data to check for the loader module.  This should contain a module property
 * which represents the current module in the recusive stack.
 * @returns {boolean} True if a loader module is found, false otherwise.
 */
export const containsLoaderModule = ( data ) => {
  const { module = {} } = handleEmptyObjects( data );

  if( module.moduleName === 'Loader' && module.displayType === 'ghostCard' ){
    return true;
  }

  const modulesLength = module.modules?.length;
  if( !modulesLength ){
    return false;
  }

  for ( let i = 0; i < modulesLength; i++ ){
    if( containsLoaderModule( { module: module.modules[i] } ) ){
      return true;
    }
  }

  return false;

};

StateWrapper.displayName = 'StateWrapper';

export default StateWrapper;
