/**
 * A modal window is a graphical control element subordinate to an application&#x27;s main window. It creates a mode that disables the main window but keeps it visible, with the dialog window as a child window in front of it
 *
 * @module views/Layouts/Overlay
 */
import './Overlay.scss';

import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { FocusScope } from '@react-aria/focus';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@ulta/core/components/Button/Button';
import OverlayFlyoutHeader from '@ulta/core/components/OverlayFlyoutHeader/OverlayFlyoutHeader';
import UseKeyPress from '@ulta/core/hooks/useKeyPress/UseKeyPress';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import constants from '@ulta/core/utils/constants/constants';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { isFunction } from '@ulta/core/utils/types/types';

import * as utils from './Overlay';

/**
* Represents a Overlay component
* @type {React.FC<OverlayProps>}
*/
const Overlay = forwardRef( ( props, ref ) => {
  const {
    alignment,
    ariaLabel,
    backAction,
    backActionCallback,
    callBackFunc,
    children,
    closeAccessibilityLabel,
    crossButtonVisibility,
    customClassName,
    disableClose,
    isSwatchModal,
    isVideoModal,
    onClose,
    opener,
    overlayType,
    showDynamicFlyoutHeader
  } = props;
  const { closeOverlay } = useOverlay();
  const buttonRef =  useRef();

  const [isCustom, offsetPx, isOpen, overlayBackdrop] = useCustomOverlay( props, ref );

  // Auto Focus the cross button for Keyboard users.
  useEffect( ()=>{
    buttonRef.current?.focus();
  }, [] );


  if( isServer() ){
    return null;
  }
  /*
   * If we're inside a dynamic overlay, it means that we're loading content into
   * OverLayContainer and that component will handle displaying a dynamic overlay header
   * with a potential back action
   */
  const showLocalFlyoutHeader = crossButtonVisibility && !showDynamicFlyoutHeader;
  const isFullHeight = !crossButtonVisibility && !showDynamicFlyoutHeader;
  const isModal = overlayType && overlayType === 'modal';
  const isFlyout = overlayType && overlayType === 'flyout';

  const overlayClass = isFlyout ? OVERLAY_FLYOUT_CLASS : OVERLAY_MODAL_CLASS;
  const showCloseButton = !disableClose && isModal;

  const component = (
    <>
      <div className='Overlay'>
        <div
          { ...( isFlyout && { ref: ref } ) }
          className={ classNames( `${overlayClass} ${overlayClass}--container`, {
            [ `${overlayClass}--${alignment}` ]: alignment,
            [ `${OVERLAY_FLYOUT_CLASS}--dynamic` ]: showDynamicFlyoutHeader,
            ...( isFlyout && {
              [ `${OVERLAY_FLYOUT_CLASS}--UtilityLinks` ]: customClassName,
              [ `${customClassName}` ]: customClassName
            } ),
            ...( isVideoModal && { [ `${OVERLAY_MODAL_CLASS}--modalVideo` ]: isVideoModal } ),
            ...( isSwatchModal && { [ `${OVERLAY_MODAL_CLASS}--modalSwatch` ]: isSwatchModal } )
          } ) }
          { ...( isFlyout && isCustom && { style: { top: offsetPx, height: `calc(100% - ${offsetPx})` } } ) }
          role='dialog'
          aria-modal={ true }
          { ...( { 'aria-label': ariaLabel } ) }
          { ...( isOpen && { 'data-open': true } ) }
        >
          <FocusScope
            contain={ true }
            restoreFocus={ true }
            { ...( isModal && { className: 'OverlayChild_scrollParent' } ) }
          >
            { showLocalFlyoutHeader && (
              <OverlayFlyoutHeader
                moduleName='OverlayFlyoutHeader'
                key='OverlayFlyoutHeader'
                onClose={ onClose }
                backAction={ backAction }
                backActionCallback={ backActionCallback }
                closeAccessibilityLabel={ closeAccessibilityLabel }
              />
            ) }
            <div
              { ...( isModal && { ref: ref } ) }
              { ...( isOpen && { 'data-open': true } ) }
              className={ classNames( {
                ...( isModal && { [ `${overlayClass}__wrapper` ]: overlayType } ),
                ...( isFlyout && {
                  [ `${overlayClass}__content` ]: overlayType,
                  [ `${overlayClass}__content--isBody` ]: !showDynamicFlyoutHeader,
                  // overlayflyout should have 100% height if OverlayFlyoutHeader is not present
                  [ `${overlayClass}__content--isFullHeight` ]: isFullHeight
                } )
              } ) }
            >
              { showCloseButton &&
                <Button
                  variant='unstyled'
                  className={ `${overlayClass}__close` }
                  ariaLabel={ closeAccessibilityLabel }
                  onClick={ () => closeOverlay( { userCancelled: true } ) }
                  ref={ buttonRef }
                  iconImage={ 'X' }
                  iconSize={ 'lg' }
                  icon={ true }
                  ariaHiddenIcon={ true }
                />
              }

              { children }
            </div>
          </FocusScope>

        </div>
      </div>
      { /* Manages Modal backdrop */ }
      { isOpen &&
        <div className={ classNames( `${OVERLAY_CLASS}--backdrop`, {
          ...( customClassName && { [ `${OVERLAY_CLASS}--backdrop--UtilityLinks` ]: customClassName } ),
          ...( customClassName && { [ `${OVERLAY_CLASS}--${customClassName}` ]: customClassName } ),
          ...( isFlyout && { [ `${OVERLAY_CLASS}--backdrop--${overlayType}` ]: overlayType } )
        } ) }
        ref={ overlayBackdrop }
        />
      }
    </>
  );

  return createPortal( component, document.body );
} );

/**
 * Manages state for a custom Overlay and polyfills the div to mimic an HTML dialog
 * @param {object} props - Component props
 * @param {object} ref - Flyout ref
 * @returns {array} Returns array of [isDialog, offsetPx, isOpen]
 */
export const useCustomOverlay = ( props, ref = {} ) => {
  const { disableClose, onClose, offsetElement } = props;

  const [isCustom, setIsCustom] = useState( false );
  const [offsetPx, setOffsetPx] = useState( '' );

  const [isOpen, setIsOpen] = useState( false );
  const overlayBackdrop = useRef();

  // We only need to update the offset if the element changes
  useEffect( () => {
    if( !isFunction( offsetElement?.current?.getBoundingClientRect ) ){
      return;
    }

    const rect = offsetElement.current.getBoundingClientRect();

    // Update top offset
    setOffsetPx( `${rect.top + rect.height + 1}px` );

    // Tell overlay to render custom div offscreen + show backdrop
    setIsCustom( true );

  }, [offsetElement] );

  useEffect( () => {
  // Allow time for DOM to update so the initial element can render off screen, then
    // when we flip this to true it will animate in
    let timeOut = setTimeout( () => {
      setIsOpen( true );
    }, 0 );

    return () => clearTimeout( timeOut );
  }, [] );

  const onOverlayClose = useCallback( ( e, data ) => {
    // if disableClose is true do not close.
    if( disableClose ){
      return;
    }
    onClose( e, data );
    setIsOpen( false );
  }, [onClose, setIsOpen, disableClose] );

  useEffect( () => {
    if( !ref?.current ){
      return;
    }

    // Polyfill the .close function on the element
    // we only set this once because if overlay content gets replaced we will have lost the og reference
    if( !isFunction( ref?.current?.close ) ){
      // eslint-disable-next-line no-param-reassign
      ref.current.close = onOverlayClose;
    }
  }, [onOverlayClose, disableClose] );

  // Handle ESC key
  UseKeyPress( constants.ESC_KEY, { current: document }, ( e ) => {
    if( disableClose ){
      return;
    }
    return onOverlayClose( e, { userCancelled: true } );
  } );

  // Handle backdrop click
  const closeOnBackDropClick = useCallback(
    utils.composeCloseOnBackDropClick( { isOpen, overlayBackdrop }, { onOverlayClose } ),
    [isOpen, overlayBackdrop, onOverlayClose]
  );

  useEffect( ( )=> {
    if( !isOpen ){
      return;
    }
    document.addEventListener( 'click', closeOnBackDropClick );

    return () => {
      document.removeEventListener( 'click', closeOnBackDropClick );
    };
  }, [isOpen, closeOnBackDropClick] );

  return [isCustom, offsetPx, isOpen, overlayBackdrop];
};

/**
* composeCloseOnBackDropClick
*/
export const composeCloseOnBackDropClick = ( data, methods ) => ( e ) => {
  const { isOpen, overlayBackdrop } = data || {};
  const { onOverlayClose } = methods || {};
  const didUserClickOutside = ( overlayBackdrop?.current && overlayBackdrop.current.contains( e.target ) );

  if( isOpen && didUserClickOutside ){
    onOverlayClose( e, { userCancelled: true } );
  }
};


/**
* Overlay class
* @constant {string}
*/
export const OVERLAY_CLASS = 'Overlay';

/**
* Overlay Modal class
* @constant {string}
*/
export const OVERLAY_MODAL_CLASS = 'OverlayModal';

/**
* Overlay Flyout class
* @constant {string}
*/
export const OVERLAY_FLYOUT_CLASS = 'OverlayFlyout';


/**
 * Overlay Flyout class
 * @constant {string} OVERLAY_SCROLL_PARENT
 */
export const OVERLAY_SCROLL_PARENT = 'OverlayChild_scrollParent';

/**
 * Overlay Flyout class
 * @constant {string} OVERLAY_FLYOUT_SCROLL_BODY
 */
export const OVERLAY_SCROLL_BODY = 'OverlayChild_scrollBody';

/**
 * Overlay Flyout class
 * @constant {string} OVERLAY_SCROLL_FOOTER
 */
export const OVERLAY_SCROLL_FOOTER = 'OverlayChild_scrollFooter';

/**
* Default values for passed properties
* @type {object}
* @property {string} closeAccessibilityLabel - Sets close accessibility label
*/

// TODO: remove default label value when content model is update.
export const defaultProps = {
  alignment: 'center',
  closeAccessibilityLabel: 'Close'
};

/**
  * Property type definitions
  * @typedef OverlayProps
  * @type {object}
  * @property {function} onClose - Sets the onClose event on the dialog element
  * @property {string} closeAccessibilityLabel - Sets close accessibility label
  * @property {string} alignment - Sets flyout alignment modifier className
  * @property {function} onClose - Sets the onClose event on the dialog element
  * @property {number} offset - Sets a top offset
  * @property {function} showDynamicFlyoutHeader - Sets is dynamic modifier className
  */

/**
* @type {OverlayProps}
*/
export const propTypes = {
  alignment: PropTypes.string,
  closeAccessibilityLabel: PropTypes.string,
  offset: PropTypes.number,
  onClose: PropTypes.func,
  showDynamicFlyoutHeader: PropTypes.bool
};

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;
Overlay.displayName = 'Overlay';

export default Overlay;
