/**
 * Provides a React context for sharing the `componentIndex` within a subtree of components.
 * This is particularly useful when rendering components within a loop, such as within a `LayerHost`.
 *
 * @module core/providers/ComponentIndexProvider
 */

import React, { createContext, useContext, useRef } from 'react';

/**
 * @typedef {Object} ComponentIndexContextValue
 * @property {React.MutableRefObject<number>} componentIndex - A ref containing the index of the component within its parent's loop.
 */


/**
 * A provider component that makes the `componentIndex` available to its children via context.
 *
 * @param {Object} props - The component's props.
 * @param {number} [props.componentIndex=0] - The index of the component. Defaults to 0.
 * @param {React.ReactNode} props.children - The children to be rendered within the provider's scope.
 * @returns {JSX.Element} A React element that provides the `componentIndex` to its children via context.
 * @example
 * <ComponentIndexProvider componentIndex={i}>
 *   <MyComponent />
 * </ComponentIndexProvider>
 */
export const ComponentIndexProvider = function( props ){

  const componentIndex = useRef( props.componentIndex || 0 );

  return (
    <ComponentIndexContext.Provider
      value={ {
        componentIndex
      } }
    >
      { props.children }
    </ComponentIndexContext.Provider>
  );
};

/**
 * React context to store component index.
 * @type {React.Context<ComponentIndexContextValue>}
 */
export const ComponentIndexContext = createContext( {
  componentIndex: { current: 0 }
} );

/**
 * A hook that allows components to access the `componentIndex` from the context.
 *
 * @returns {ComponentIndexContextValue} The context value, containing the `componentIndex`.
 * @example
 * const { componentIndex } = useComponentIndexContext();
 */
export const useComponentIndexContext = ( ) => useContext( ComponentIndexContext );

export default ComponentIndexProvider;
