/**
 * Common DealsRail component used by other modules as a reusable component which returns the ui for a product carousel.
 *
 * @module views/Organisms/DealsRail
 * @memberof -Common
 */
import './DealsRail.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import React, { useRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '@ulta/core/components/Button/Button';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import Text from '@ulta/core/components/Text/Text';
import { useIntersectionObserver } from '@ulta/core/hooks/useIntersectionObserver/useIntersectionObserver';
import { constants } from '@ulta/core/utils/constants/constants';
import { handleSwiperImpressionEvent } from '@ulta/core/utils/datacaptureAggregator/datacaptureAggregator';

import MediumDealCard from '@ulta/modules/MediumDealCard/MediumDealCard';

import carouselUtils, { handleInactiveSwiperSlides, handleSwiperSlideChange } from '@ulta/utils/carouselUtils/carouselUtils';
import interpolateString from '@ulta/utils/interpolateString/interpolateString';
import { hasItems } from '@ulta/utils/Validations/Validations';


SwiperCore.use( [Navigation, Keyboard, A11y] );
let swiperCounter = 1;

/**
 * Represents a DealsRail component
 *
 * @method
 * @param {DealsRailProps} props - React properties passed from composition
 * @returns DealsRail
 */
const DealsRail = ( props ) => {
  const componentRef = useRef( null );
  const carouselRef = useRef( null );
  const {
    offers,
    invokeMutation,
    nextAccessibility,
    nextClickAction,
    previousAccessibility,
    previousClickAction,
    title,
    headerTitle,
    subTitle,
    viewAllOffersAction,
    carouselAccessibility,
    carouselDetailsAccessibility,
    vertical
  } = props;

  const validOffers = offers.filter( ( offer ) => offer?.actionGroup && offer?.eyebrow && offer?.headline && offer?.image?.imageUrl );
  const dataCapturePayload = {
    ...props,
    items: validOffers
  };

  useIntersectionObserver(
    componentRef,
    {
      root: props.root,
      rootMargin: props.rootMargin,
      threshold: props.threshold
    },
    handleSwiperImpressionEvent( { ...props, items: validOffers }, carouselRef )
  );

  if( !hasItems( validOffers ) ){
    return null;
  }

  return (
    <div className='DealsRail'>
      <div className='DealsRail__content'
        ref={ componentRef }
      >
        <div className='DealsRail__header'>
          <div className='DealsRail__headerTitle'>
            <Text textStyle='title-6'
              htmlTag={ headerTitle?.htmlTag }
            >
              { headerTitle?.text }
            </Text>
            { viewAllOffersAction?.url && viewAllOffersAction?.label && (
              <div className={ classNames( 'DealsRail__headerLink', {
                'DealsRail__headerLink--vertical': vertical
              } )
              }
              >
                <Link_Huge withHover={ true }
                  action={ props.viewAllOffersAction }
                >
                  { viewAllOffersAction.label }
                </Link_Huge>
              </div>
            ) }
          </div>
          { subTitle && (
            <div className='DealsRail__subTitle'>
              <Text textStyle='body-2'
                htmlTag='p'
              >
                { interpolateString( subTitle, [validOffers.length] ) }
              </Text>
            </div>
          ) }
        </div>
        <div className='DealsRail_Carousel'
          role='region'
          aria-label={ carouselUtils.handleTokenizedString( carouselAccessibility, [title] ) }
        >
          { carouselDetailsAccessibility && <p className='sr-only'>{ carouselUtils.handleTokenizedString( carouselDetailsAccessibility, [title] ) }</p> }
          <div className={
            classNames( `DealsRail__Pagination DealsRail__Pagination-${swiperCounter}`, {
              'DealsRail__Pagination--vertical': vertical
            } )
          }
          >
            <Button
              variant='navigation'
              iconImage='CaretBack'
              iconSize='lg'
              className='DealsRail__Pagination__button DealsRail__Pagination__button--back'
              ariaLabel={ previousAccessibility }
              ariaHiddenIcon={ true }
              onClick={ handleSwiperSlideChange(
                null,
                previousClickAction,
                carouselRef,
                handleSwiperImpressionEvent( dataCapturePayload, carouselRef ),
                componentRef?.current
              ) }
            />
            <Button
              variant='navigation'
              iconImage='CaretForward'
              iconSize='lg'
              className={ 'DealsRail__Pagination__button DealsRail__Pagination__button--forward' }
              ariaLabel={ nextAccessibility }
              ariaHiddenIcon={ true }
              onClick={ handleSwiperSlideChange(
                nextClickAction,
                null,
                carouselRef,
                handleSwiperImpressionEvent( dataCapturePayload, carouselRef ),
                componentRef?.current
              ) }
            />
          </div>

          <Swiper
            ref={ carouselRef }
            watchOverflow={ true }
            watchSlidesProgress={ true }
            watchSlidesVisibility={ true }
            freeMode={ {
              minimumVelocity: 0.01,
              momentum: true,
              momentumBounce: true,
              momentumBounceRatio: 1.2,
              momentumRatio: 1.2,
              momentumVelocityRatio: 1.2,
              sticky: true
            } }
            speed={ 600 }
            preloadImages={ true }
            spaceBetween={ 16 }
            onSwiper={ ( swiper ) => handleInactiveSwiperSlides( swiper ) }
            onSlideChange={ ( swiper ) => {
              handleInactiveSwiperSlides( swiper );
            } }
            // setTimeout is needed to ensure swiper is fully initialized because asyncComponent is loaded after swiper
            onSlidesLengthChange={ ( swiper ) => setTimeout( () => handleInactiveSwiperSlides( swiper ), 0 ) }
            navigation={ {
              prevEl: `.DealsRail__Pagination-${swiperCounter} .DealsRail__Pagination__button--back`,
              nextEl: `.DealsRail__Pagination-${swiperCounter} .DealsRail__Pagination__button--forward`,
              disabledClass: 'DealsRail__Pagination__button--disabled',
              hiddenClass: 'DealsRail__Pagination__button--hidden'
            } }
            breakpoints={ {
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              992: {
                slidesPerView: 4,
                slidesPerGroup: 4
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3
              },
              0: {
                slidesPerView: 1.5,
                slidesPerGroup: 1
              }
            } }
          >
            { validOffers?.length > 0 && validOffers?.map( ( offer, index ) => (
              <SwiperSlide key={ index }>
                <div key={ `DealsCard_${index}` }
                  className='DealsRail__dealCard'
                >
                  <MediumDealCard
                    action1={ offer.actionGroup.action1 }
                    action2={ offer.actionGroup.action2 }
                    action1Style={ offer.actionGroup.action1Style }
                    action2Style={ offer.actionGroup.action2Style }
                    eyebrow={ offer.eyebrow }
                    headline={ offer.headline }
                    image={ offer.image }
                    isOfferActivated={ offer.isOfferActivated }
                    tag={ offer.tag }
                    urgencyTag={ offer.urgencyTag }
                    subHeadline={ offer.description }
                    activateAction={ offer.activateAction }
                    invokeMutation={ invokeMutation }
                    offerId={ offer.offerId }
                  />
                </div>
              </SwiperSlide>
            ) ) }
          </Swiper>
        </div>
      </div>
    </div>
  );
};

/**
 * Default values for passed properties
 * @type {object}
 * @property {string} previousAccessibility='' - The default value of the previousAccessibility is empty string.
 * @property {string} nextAccessibility='' - The default value of the nextAccessibility is empty string.
 * @property {string} title='' - The default value of the title is empty string.
 * @property {string} countLabel='' - The default value of the count label is empty string.
 * @property {boolean} arrows=false - The default value for arrows is set to false.
 * @property {number} slidesToSlide=4 - The default value for slidesToSlide is set to 4.
 * @property {array} offers=[] - The default value for offers is set to empty array.
 */
export const defaultProps = {
  previousAccessibility: '',
  nextAccessibility: '',
  title: '',
  countLabel: '',
  arrows: false,
  slidesToSlide: 4,
  offers: [],
  ...constants.INTERSECTION_OBSERVER_OPTIONS
};

/**
* Property type definitions
* @typedef DealsRailProps
* @type {object}
* @property {string} previousAccessibility - Sets the aria label for previous button.
* @property {string} nextAccessibility - Sets the aria label for next button.
* @property {string} title - the offer title in plain text
* @property {object} headerTitle - set the offer title with proper accessiblity.
* @property {string} subtitle - Sets the subtitle for productRail list.
* @property {string} countLabel - Sets the count label for productRail list.
* @property {boolean} arrows - Sets whether to show or hide default arrows on carousel.
* @property {number} slidesToSlide - Sets the number of slides to slide when cliked on next or previous button.
* @property {Array.<{promoText: string, reviewCount: Number, rating: Number, brandName: string, productName: string, variantLabel: string, badge: string, image: string, availability: array, addToBagAccessibility: string, additionalOffersText: string, altImage: string, bookmarkAccessibility: string, bookmarked: boolean, kitPrice: string, listPrice: string, salePrice: string, skuId: string}>} products - List of products to loop over and display in carousel.
* @property {object} viewAllOffersAction - This is the action object with action label and url.
* @property {string} carouselDetailsAccessibility - Value for aria label for carousel
* @property {string} carouselAccessibility - Value for aria label for product rail
* @property {string} carouselCardAccessibility - Value for the product card aria label
* @property {string} reviewAccessibilityLabel - Value for the rating aria label
*/
export const propTypes = {
  /**
  * The prop that holds the aria label for previous button.
  */
  previousAccessibility: PropTypes.string,
  /**
  * The prop that holds the aria label for next button.
  */
  nextAccessibility: PropTypes.string,
  /**
  * The prop that holds the plain text title.
  */
  title: PropTypes.string,
  /**
  * The prop that holds title for the carousel.
  */
  headerTitle: PropTypes.shape( {
    htmlTag: PropTypes.string,
    text: PropTypes.string
  } ),
  /**
  * The prop that holds subtitle for the carousel.
  */
  subtitle: PropTypes.string,
  /**
  * The prop that holds the count label for the product list.
  */
  countLabel: PropTypes.string,
  /**
  * The prop that decides to render default arrows on carousel.
  */
  arrows: PropTypes.bool,
  /**
  * The prop that decides number of slides to slide when cliked on next or previous button.
  */
  slidesToSlide: PropTypes.number,
  /**
  * The prop that decides to show aria-label.
  */
  reviewAccessibilityLabel: PropTypes.string,
  /**
  * The prop that holds the list of products to render.
  */
  offers: PropTypes.arrayOf(
    PropTypes.shape( {
      promoText: PropTypes.string,
      reviewCount: PropTypes.number,
      rating: PropTypes.number,
      brandName: PropTypes.string,
      productName: PropTypes.string,
      variantLabel: PropTypes.string,
      badge: PropTypes.string,
      image: PropTypes.object,
      availability: PropTypes.arrayOf( PropTypes.string ),
      listPrice: PropTypes.string,
      salePrice: PropTypes.string,
      kitPrice: PropTypes.string,
      bookmarkAccessibility: PropTypes.string,
      addToBagAccessibility: PropTypes.string,
      additionalOffersText: PropTypes.string,
      altImage: PropTypes.string,
      bookmarked: PropTypes.bool,
      skuId: PropTypes.string
    } )
  ),
  /**
  * This is the action with label and url.
  */
  viewAllOffersAction: PropTypes.shape( {
    label: PropTypes.string,
    url: PropTypes.string

  } ),
  /** The prop that holds the action for the next button. */
  nextClickAction: PropTypes.object,
  /** The prop that holds the action for the previous button. */
  previousClickAction: PropTypes.object,
  /** The prop that holds  aria label for carousel  */
  carouselDetailsAccessibility: PropTypes.string,
  /** The prop that holds  aria label for carousel  */
  carouselAccessibility: PropTypes.string,
  /** The prop that holds  aria label for product card  */
  carouselCardAccessibility: PropTypes.string
};

DealsRail.propTypes = propTypes;
DealsRail.defaultProps = defaultProps;

export default DealsRail;