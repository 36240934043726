/**
 * Component provides a row of substitutes for product cards while products list data is loading
 *
 * @module views/components/GhostCards
 * @memberof -Common
 */
import './GhostCards.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@ulta/core/components/Icon/Icon';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

/**
 * @method getRandomColorsInAmount
 * @summary Returns a random colors array of the required length
 * @param {array} colors - array of color names
 * @param {number} amount - length of the returned array
 * @returns {array}
 */
export const getRandomColorsInAmount = ( colors, amount ) => {
  if( !Array.isArray( colors ) ||
      colors.findIndex(
        ( color ) => typeof color !== 'string'
      ) !== -1 ){
    throw new Error( 'Invalid colors argument: expected an array of strings' );
  }

  if( colors.length === 0 ){
    throw new Error( 'Invalid colors argument: expected a not empty array of strings' );
  }

  if( typeof amount !== 'number' ){
    throw new Error( 'Invalid amount argument: expected a number' );
  }

  if( colors.length < amount ){
    throw new Error( 'Colors array length can not be less than amount' );
  }

  const randomColors = [];

  for ( let i = 0; i < amount; ){
    const randomIndex = Math.floor( Math.random() * colors.length );
    const isAddedColorToList = Boolean( randomColors.indexOf( colors[randomIndex] ) !== -1 );

    if( isAddedColorToList ){
      continue;
    }

    randomColors.push( colors[randomIndex] );
    i++;
  }
  // During a test run this needs to be skipped, otherwise we get snapshot failures
  // and other test failures.
  if( process.env.NODE_ENV === 'test' ){
    return colors;
  }

  return randomColors;
};

/**
 * @method renderGhostCardsInAmount
 * @summary Renders a required amount of cards with random background colors
 * @param {array} colors - array of color names
 * @param {number} amount - length of the returned array
 * @returns {array}
 */
export const renderGhostCardsInAmount = ( colors, amount ) => {
  return getRandomColorsInAmount( colors, amount ).map( ( color, index ) => (
    <li className={ classNames( 'GhostCards__card', {
      [`GhostCards--${color}`]: color
    } ) }
    data-test='ghost-card'
    key={ `${color}_${index}` }
    />
  ) );
};

/**
 * @method renderGhostCardsOnDevice
 * @summary Renders a required amount of cards depending on a device type and filter state
 * @param {object} config - an object containing inflection data object, colors array and isFilterShown flag
 * @returns {array}
 */
export const renderGhostCardsOnDevice = (
  { inflectionData,
    colors,
    isFilterShown,
    renderCardsJSXinAmount = renderGhostCardsInAmount
  } ) => {
  const { breakpoint } = inflectionData;
  const isMobileDevice = breakpoint.CURRENT_BREAKPOINT === 'SM';

  if( isMobileDevice && !isFilterShown ){
    return renderCardsJSXinAmount( colors, 2 );
  }

  if( isMobileDevice && isFilterShown ){
    return null;
  }

  if( breakpoint.CURRENT_BREAKPOINT === 'MD' && !isFilterShown ){
    return renderCardsJSXinAmount( colors, 3 );
  }

  if( breakpoint.CURRENT_BREAKPOINT === 'MD' && isFilterShown ){
    return renderCardsJSXinAmount( colors, 2 );
  }

  if( ( breakpoint.CURRENT_BREAKPOINT === 'LG' ||
      breakpoint.CURRENT_BREAKPOINT === 'XL' ) &&
      isFilterShown ){
    return renderCardsJSXinAmount( colors, 3 );
  }

  return renderCardsJSXinAmount( colors, 4 );
};

const colors = [
  'background-brand-01',
  'background-brand-02',
  'background-brand-03',
  'background-brand-04'
];

/**
 * Represents a GhostCards component
 *
 * @method
 * @param {GhostCardsProps} props - React properties passed from composition
 * @returns GhostCards
 */
export const GhostCards = function( props ){
  const inflectionData = useDeviceInflection();

  if( inflectionData.breakpoint.CURRENT_BREAKPOINT === 'SM' && props.isFilterShown ){
    return null;
  }

  return (
    <div className='GhostCards'
      data-test='ghost-cards'
    >
      <ul className='GhostCards__list'>
        {
          renderGhostCardsOnDevice( {
            inflectionData,
            colors,
            isFilterShown: props.isFilterShown
          } )
        }
      </ul>
      <div className='GhostCards__loaderWrapper'
        data-test='loader'
      >
        <Icon name={ 'Loader' } />
      </div>
    </div>
  );
};

/**
 * Property type definitions
 * @typedef GhostCardsProps
 * @type {object}
 * @property {boolean} isFilterShown - flag describing whether FiltersColumn is opened or not
 */
export const propTypes =  {
  isFilterShown: PropTypes.bool
};

GhostCards.propTypes = propTypes;

export default GhostCards;
