/* eslint-disable no-console */
/**
 * The AuthProvider component is used to provide the Auth Config
 *
 * @module views/__core/AuthProvider/AuthProvider
 */
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { getAuthUrlForClient } from '../../utils/auth/auth';
import * as utils from './AuthProvider';


/**
 * Represents a AppConfigProvider component
 *
 * @method
 * @param {object} props - React properties passed from composition
 * @returns AppConfigProvider
 */
export const AuthProvider = React.forwardRef( ( props, _ ) => {
  const { auth0login, children } = props;

  const getAuthUrl = useCallback( utils.composeGetAuthUrl( { auth0login } ), [auth0login] );

  const value = useMemo(
    () => ( {
      getAuthUrl
    } ),
    [getAuthUrl]
  );

  return <AuthProviderContext.Provider value={ value }>{ children }</AuthProviderContext.Provider>;
} );

/**
 * Returns a function that handles generating auth redirect urls
 * @param {object} data - arguments
 * @returns {function}
 */
export const composeGetAuthUrl = ( data ) => ( args ) => {
  const { auth0login } = data || {};
  const { redirect = global.location.href, screen } = args || {};

  const url = getAuthUrlForClient( {
    auth0login,
    redirect,
    screen
  } );

  return url.toString();
};

/**
 * Context provider for react reuse
 * @type object
 */
export const AuthProviderContext = createContext( {} );

export const useAuthContext = ( ) => useContext( AuthProviderContext );


AuthProvider.displayName = 'AuthProvider';

export default AuthProvider;
