/**
 * used to add space between components when creating pages in amplience
 *
 * @module views/components/Spacer
 * @memberof -Common
 */
import './Spacer.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Represents a Spacer component
 *
 * @method
 * @param {SpacerProps} props - React properties passed from composition
 * @returns Spacer
 */
export const Spacer = function( props ){

  if( !props.value ){
    return null;
  }
  
  return (
    <div className={ classNames( 'Spacer', {
      [`Spacer--${props.value}`]: props.value
    } )
    }
    >
    </div>
  );
};

/**
 * Property type definitions
 * @typedef SpacerProps
 * @type {object}
 * @property {string} value - Sets the spacing value
 */
export const propTypes =  {
  value: PropTypes.string
};


Spacer.propTypes = propTypes;
export default Spacer;
