/**
 * Use promotional grid to surface and feature more than one promotional card at a time without using a rail
 *
 * @module ULTA_SDK
 * @tutorial SDK
 */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { gql } from '@apollo/client';
import _get from 'lodash/get';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { getRequiredClientParams } from '@ulta/core/hooks/useLayerHostAction/useLayerHostAction';
import { useSubscription } from '@ulta/core/hooks/useSubscription/useSubscription';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';
import { useOverlay } from '@ulta/core/providers/OverlayProvider/OverlayProvider';
import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';
import { useUserContext } from '@ulta/core/providers/UserContextProvider/UserContextProvider';
import { getClient } from '@ulta/core/utils/apollo_client/apollo_client';
import { handleDXLNavigationType } from '@ulta/core/utils/clientActionProcessor/clientActionProcessor';
import { DXL_NAVIGATION_TYPE } from '@ulta/core/utils/constants/action';
import datacapture from '@ulta/core/utils/datacapture/datacapture';
import { isServer } from '@ulta/core/utils/device_detection/device_detection';
import { devLogger } from '@ulta/core/utils/devMode/devMode';
import { queryProcessor } from '@ulta/core/utils/queryProcessor/queryProcessor';

import * as utils from './Sdk';

export const initialPortals = [];
export const initialSubscriptions = {};

export const SDK = ( props ) => {
  const { isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } = useAppConfigContext();
  const { initAction, allowedIframeOrigins = [], contentModel, dataCapture: data_capture, session, ui } = props;

  const flyoutContentRef = useRef();
  const { user } = useUserContext();
  const [subscriptionEvents, setSubscriptionEvents] = useState( utils.flattenObjRecursively( user ) );
  const { openOverlay, closeOverlay } = useOverlay();
  const { refetchRef } = usePageDataContext();
  const { breakpoint: { CURRENT_BREAKPOINT } = {} } = useDeviceInflection();

  const [_contentModel, setContentModel] = useState();
  const [portals, dispatchPortalUpdate] = useReducer( utils.portalsReducer, utils.initialPortals );
  const [subscriptions, dispatchSubscriptionUpdate] = useReducer( utils.subscriptionReducer, utils.initialSubscriptions );

  const triggerDCEvent = utils.triggerDataCaptureEvent( { dataCapture: data_capture, allowedIframeOrigins } );

  useEffect( () => {
    // if the user has changed from the user context provider we need to reset the subscriptioEvents for outside consumption
    setSubscriptionEvents( utils.flattenObjRecursively( user ) );
  }, [user] );

  useSubscription( { val: user, subscriptions }, { dispatchSubscriptionUpdate } );
  useEffect( () => {
    // add the sdk event listener for handling message posts
    if( !isServer() ){
      global.addEventListener( 'message', utils.handleIframeEvent );
    }

    return () => global.removeEventListener( 'message', utils.handleIframeEvent );
  }, [] );

  useEffect( () => {
    if( !isServer() && contentModel ){
      let newCm;

      newCm = utils.distributeRenderMethods( { contentModel }, { dispatchPortalUpdate } );
      newCm = utils.configureGetMethods(
        { contentModel: newCm, CURRENT_BREAKPOINT, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
        { setContentModel }
      );
      newCm = utils.configureModuleInvoke(
        { contentModel: newCm, CURRENT_BREAKPOINT, refetchRef, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
        { invoke: utils.invokeModuleAction }
      );
      const init = utils.initSDK( { action: initAction } );
      const enrichedUI = utils.configureUIActions(
        { ui, flyoutContentRef },
        { openOverlay, closeOverlay, refetchRef, dispatchPortalUpdate }
      );
      const enrichedSession = utils.configureSessionData(
        { contentModel: newCm, session, user, subscriptions, subscriptionEvents, refetchRef, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
        { dispatchSubscriptionUpdate, openOverlay }
      );
      const dataCapture = {
        trigger: triggerDCEvent
      };

      global.ULTA_SDK = {
        init,
        contentModel,
        session: enrichedSession,
        ui: enrichedUI,
        dataCapture
      };
      setContentModel( contentModel );
    }
  }, [contentModel] ); // first time we receive cntent model from DXL

  useEffect( () => {
    utils.configureGetMethods(
      { contentModel: _contentModel, CURRENT_BREAKPOINT, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
      { setContentModel }
    );
    utils.configureModuleInvoke(
      { contentModel: _contentModel, CURRENT_BREAKPOINT, refetchRef, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
      { invoke: utils.invokeModuleAction }
    );
    const enrichedSession = utils.configureSessionData(
      { contentModel: _contentModel, session, user, subscriptions, subscriptionEvents, refetchRef, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
      { dispatchSubscriptionUpdate, openOverlay }
    );

    global.ULTA_SDK = {
      ...global.ULTA_SDK,
      contentModel: _contentModel,
      session: enrichedSession
    };
  }, [_contentModel, CURRENT_BREAKPOINT, user] );

  return (
    <>
      { portals.length > 0 &&
        portals?.map( ( portal, index ) => {
          const { moduleName, data, selector } = portal;
          return ReactDOM.createPortal(
            <AsyncComponent { ...data }
              key={ `${moduleName}_${index}` }
              moduleName={ moduleName }
            />,
            document.querySelector( selector )
          );
        } ) }
    </>
  );
};

/**
 * initSDK
 * @summary a manual invocationmethod of the SDK API from
 * @param { object } data - data to be used in curried method
 * @memberof SDK
 * @returns function
 */
export const initSDK = ( data ) => {
  const { initAction } = data || {};

  return ( callback ) => {};
};

/**
 * handleIframeEvent
 * event handler method for frame events for trigger datacapture methods sent from within a fram
 * @method
 *
 * @param { object } e - event object
 *  @returns function
 */
export const handleIframeEvent = ( data, methods ) => {
  const { allowedIframeOrigins = [] } = data || {};
  const { triggerDCEvent, logger = devLogger } = methods || {};

  return ( e ) => {
    const matchedOrigin = allowedIframeOrigins?.filter( ( allowedOrigin ) => {
      return e.origin === allowedOrigin;
    } );
    if( matchedOrigin.length > 0 ){
      const eventData = e.detail?.data || {};
      logger( {
        title: 'iFrame eventCaptured',
        value: {
          eventData: eventData
        }
      } );
      triggerDCEvent( { ...eventData, iframeSrc: true } );
    }
  };
};

/**
 * flattenObjRecursively takes an object and returns a flattened formatted object only containing the keys that had data
 * @method
 * @param { object } obj - Object to be flattened
 */
export const flattenObjRecursively = ( obj = {} ) => {
  return Object.keys( obj ).reduce( ( acc, entry ) => {
    let newAcc = { ...acc };
    let subObj = obj[entry];
    if( typeof subObj === 'object' && subObj !== null ){
      newAcc = { ...newAcc, ...utils.flattenObjRecursively( obj[entry] ) };
    }
    else {
      newAcc[entry.toUpperCase()] = entry.toLowerCase();
    }
    return newAcc;
  }, {} );
};

/**
 * configureSessionData defines the public exposed session object for SDK usage
 * @method
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 * @returns object
 */
export const configureSessionData = ( data, methods ) => {
  const { session, user, subscriptions, subscriptionEvents, contentModel, refetchRef, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } = data || {};

  const { dispatchSubscriptionUpdate, openOverlay } = methods;

  const newSession = {
    user,
    // expose the login action to invoke action from dxl session obj
    login: utils.triggerQuery(
      { action: session?.signInAction, contentModel, refetchRef, actionPath: '_LOGIN', isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri },
      { openOverlay }
    ),
    subscriptionEvents,
    subscribe: utils.subscribeToSessionChanges( { user }, { dispatchSubscriptionUpdate } ),
    getSubscriptions: utils.getSubscriptions( subscriptions ),
    unsubscribe: utils.unsusbscribeToSessionChanges( { dispatchSubscriptionUpdate } )
  };

  return newSession;
};

/**
 * getSubscriptions retuns the subscriptions object for consumption
 * @method
 * @param { object } subscriptions - object to be returned
 */
export const getSubscriptions = ( subscriptions ) => subscriptions;

/**
 * unsubscribeToSessionChanges
 * @method
 * @param { object } methods - object containing value of the dispatch method
 * @returns function
 */
export const unsusbscribeToSessionChanges = ( methods ) => {
  const { dispatchSubscriptionUpdate } = methods || {};
  if( !dispatchSubscriptionUpdate ){
    return;
  }

  return ( key ) => {
    if( !key ){
      return;
    }
    dispatchSubscriptionUpdate( { type: key, unsubscribe: true } );
  };
};

/**
 * subscribeToSessionChange allows the caller to add a callback function to be executed
 * to a specific session data key
 * @method
 *
 * @param { object }
 *
 * @returns function
 */
export const subscribeToSessionChanges = ( data, methods ) => {
  const { user } = data || {};
  const { dispatchSubscriptionUpdate } = methods || {};
  if( !dispatchSubscriptionUpdate ){
    return;
  }
  return ( key, callback ) => {
    if( !key || !callback ){
      return;
    }
    dispatchSubscriptionUpdate( { type: key, callback, lastVal: user[key] } );
  };
};

/**
 * subsctipionReducer
 *
 * managed the state of new incoming subscriptions passed by the callers
 *
 * @method
 * @param { object } state - current state object
 * @param { object } action - action being passed to manipulate teh state
 *
 * @returns object
 *
 */
export const subscriptionReducer = ( state = {}, action ) => {
  const { type, callback, lastVal, unsubscribe = false } = action || {};

  if( unsubscribe ){
    let newState = { ...state };
    delete newState[type];
    return newState;
  }
  else {
    // validate subscription
    //
    return {
      ...state,
      ...( type && callback && { [type]: { callback, lastVal } } )
    };
  }
};

/**
 * protalsReducer is used to manage react portals to display new UI elemtns within the application context
 * @method
 *
 *
 * @param { object } state - current state object
 * @param { object } action - action being passed to manipulate teh state
 *
 * @returns object
 */
export const portalsReducer = ( state = utils.initialPortals, action ) => {
  const { type, data, selector, moduleName, domain, portalID, unrenderType } = action || {};

  switch ( type ){
    case utils.RENDER_TYPE.RENDER:
      if( !type || !data || !selector || !moduleName || !domain || !portalID ){
        return state;
      }
      const entry = {
        id: portalID,
        moduleName,
        selector,
        data
      };
      return [...state, entry];

    case utils.RENDER_TYPE.UNRENDER:
      // TODO needs t ochange to a filter method for delete

      if( unrenderType === UNRENDER_TYPES.SINGLE ){
        if( !portalID ){
          console.error('to unrender a single module you MUST pass a selector and the contentmodel domain'); // eslint-disable-line
          return state;
        }
        const filteredRenderedModules = state.filter( ( module ) => module.id !== portalID );
        return filteredRenderedModules;
      }
      else if( unrenderType === UNRENDER_TYPES.ALL ){
        const filteredRenderedModules = state.filter( ( module ) => module.moduleName !== moduleName );
        return filteredRenderedModules;
      }
    default:
      console.error(`could not perform the ${type} for the ${domain} domain`); // eslint-disable-line
      return state;
  }
};

/**
 * RENDER_TYPE
 * define the types of render that  can be passed
 *
 * @param RENDER_TYPE
 * @param RENDER_TYPE.RENDER value for the type of render
 * @param RENDER_TYPE.UNRENDER value for the type of unrender
 */
export const RENDER_TYPE = {
  RENDER: 'render',
  UNRENDER: 'unrender'
};

/**
 * triggerDataCaptureEvent
 * @method
 *
 *
 */
export const triggerDataCaptureEvent = ( data ) => {
  const { dataCapture } = data || {};
  return ( innerdata ) => {
    const { type, name, data, useBaseConig = true, iframeSrc } = innerdata || {};
    if( type && data && !isServer() ){
      // TODO spread the base datacapture object with the new one to get desired output here
      const dcEvent = {
        ...( useBaseConig && { dataCapture } ),
        eventName: name,
        moduleName: 'ULTA_SDK',
        category: type,
        dataLayer: {
          ...dataCapture.dataLayer,
          Tealium: {
            ...( dataCapture.dataLayer?.Tealium || {} ),
            ...data
          }
        }
      };

      datacapture.processDataCaptureEvent( dcEvent );
    }
    else {
      return false;
    }
  };
};

/**
 * refreshPage - method that tell the application to refresh
 * @method
 *
 * @param data - data object to be passed
 * @param data.refetchRef - the react reference object forcalling refresh page
 * @param methods - methods passed as a configuration object
 * @param methods.refreshPageAction -  action for triggering a refetch page
 */
export const refreshPage = ( data, methods ) => {
  const { refetchRef } = data || {};
  const { refreshPageAction } = methods || {};
  return () => {
    handleDXLNavigationType( { action: refreshPageAction }, { refetch: refetchRef.current } );
  };
};

/**
 * handleOpenOverlay
 * event handler for opening an overlay
 * @method
 *
 * @param  outerdata - data for curried method instantiation
 * @param methods - methods for cirreid method instantiation
 *
 * @returns method
 */
export const handleOpenOverlay = ( outerdata, methods ) => {
  const { ui, flyoutContentRef } = outerdata || {};
  const { openOverlay, closeOverlay } = methods || {};
  return ( type, data ) => {
    // closeOverlay();
    const { alignment = 'right', crossButtonVisibility = true, loadDXLContentAction, content, onClose } = data || {};

    let overlayAction;
    switch ( type.toLowerCase() ){
      case DXL_NAVIGATION_TYPE.Flyout:
        overlayAction = ui.openFlyoutAction;
        break;
      case DXL_NAVIGATION_TYPE.Modal:
        overlayAction = ui.openModalAction;
        break;
      default:
        overlayAction = ui.openFlyoutAction;
        break;
    }

    const { navigationType, ...rest } = overlayAction || {};

    openOverlay( {
      type: navigationType,
      ...( type === DXL_NAVIGATION_TYPE.Flyout && { alignment } ),
      crossButtonVisibility,
      ...( loadDXLContentAction && { action: loadDXLContentAction } ),
      ...( content && {
        content: (
          <div
            id={ `SDK_Overlay_${type}` }
            ref={ flyoutContentRef }
          >
          </div>
        )
      } ),
      ...( onClose && { onClose } )
    } );
  };
};

/**
 * configureModuleInvoke - setups up the invoke action to be publicly exposed on the ULTA_SDK object
 * @method
 * @param data - data object containg data to be passed
 * @param methods - object contianing injected methods to be executed
 * @returns object
 */

export const configureModuleInvoke = ( data, methods ) => {
  const { contentModel = {}, user, refetchRef, CURRENT_BREAKPOINT, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } = data || {};
  const { invoke } = methods || {};
  let enrichedContentModel = { ...contentModel };
  Object.keys( contentModel )?.map( ( key ) => {
    const targetModule = enrichedContentModel[key];
    targetModule.invokeAction = invoke( {
      contentModel,
      targetModule,
      moduleName: targetModule.moduleName,
      domain: key,
      refetchRef,
      CURRENT_BREAKPOINT,
      user,
      isStaging,
      isNative,
      graphqlURI,
      graphqlDomain,
      graphqlServerUri
    } );
  } );
  return enrichedContentModel;
};

/**
 * triggerQuery returns a function for the SDK user to invoke for excuting an action
 * @method
 *
 * @param data - data object containg data to be passed
 * @param methods - object contianing injected methods to be executed
 *
 * @returns function
 */
export const triggerQuery = function( data, methods ){
  const { action, contentModel, actionPath, refetchRef, CURRENT_BREAKPOINT, user, domain, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } = data || {};
  const { openOverlay, setContentModel } = methods || {};

  return async function( customModuleParams = {} ){
    if( !action ){
      console.warn('there was an issue trying to invoke the action'); // eslint-disable-line
      return null;
    }

    // retrieve the CM content items data
    const module = contentModel?.[domain]?._data?.getAction || {};
    let clientParamsResolvedAction = getRequiredClientParams( {
      user,
      props: module,
      action,
      breakpoint: CURRENT_BREAKPOINT
    } );

    const preppedAction = queryProcessor( { action: clientParamsResolvedAction } );

    devLogger( {
      title: `[SDK] invokeAction for ${domain}`,
      value: { user, preppedAction }
    } );

    const { graphql, config, context, isMutation, variables, method } = preppedAction || {};
    const dxlClient = getClient( { isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri, method } );

    // get the action type
    // TODO   determine if we need to run a DXL navigation or a client navion using the data from the action here

    const wasActionHandled = handleDXLNavigationType( { action }, { refetch: refetchRef.current, openOverlay } );

    if( graphql && !wasActionHandled ){
      const actionType = isMutation ? 'mutate' : 'query';
      const { data: dxlData } = await dxlClient[actionType]( {
        [isMutation ? 'mutation' : 'query']: gql`
          ${graphql}
        `,
        config: { ...config },
        context: { ...context, fetchOptions: { method } },
        variables: {
          contentId: module.id, // add the module Id for the inquery from the module itself
          ...variables,
          moduleParams: {
            ...module,
            ...variables.moduleParams, // add all of the moduleParams. This is needed because not all actions define params for themselves
            ...customModuleParams
          }
        }
      } );
      const res = dxlData?.Page?.content;
      if( res ){
        // if it's a page query then we will
        let updatedContentModel = {
          ...contentModel,
          [domain]: {
            ...contentModel[domain],
            _data: {
              getAction: res // replace the existing domain data with the new data from the DXL response
            }
          }
        };
        // update the content model and expose the response on the saved data path

        // update the ULTA_SDK with the new updatedContentModel
        setContentModel( updatedContentModel );
      }

      return res;
    }
  };
};

/**
 * invokeModuleAction
 * @method
 *
 * @param { object } data - data configureation object
 * returns function
 */

export const invokeModuleAction = function( data, methods ){
  const { contentModel, targetModule, moduleName, domain, refetchRef, CURRENT_BREAKPOINT, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } =
    data || {};
  return async function( innerData ){
    const { actionPath, variables } = innerData || {};
    if( !targetModule._data ){
      // if there's no data we must first go fetch it then we can performe the invoke against it afterwards
      // TODO was looking into calling it for the hwoever the issue is that some arguments may be needed from an outside context, so we can't blindly call the getAction
      // const getData = utils.getModuleData( { contentModel, action: targetModule.getAction, actionPath: 'getAction', domain, dxlClient }, { setContentModel } );
      // const getData
      console.warn(`You must first run the 'get' action to get the module definition before invoking an action`); // eslint-disable-line
      return;
    }

    // invokeAction( { actionPath: 'bonusOffers[0].buttonaction', variables: {}} )

    let requestedAction = _get( targetModule._data.getAction, actionPath );

    if( !requestedAction ){
      // eslint-disable-next-line no-console
      console.warn(
        `You tried to call the action ${actionPath} from the which is not defined on the current contentModel object`
      ); // eslint-disable-line
      return;
    }

    const actionResonse = await utils.triggerQuery( {
      action: requestedAction,
      contentModel,
      actionPath,
      refetchRef,
      CURRENT_BREAKPOINT,
      user,
      domain,
      isStaging,
      isNative,
      graphqlURI,
      graphqlDomain,
      graphqlServerUri
    } )( variables );
    return actionResonse;
    // TODO call the setSDKACtion method with the module passed action
    // TODO (might be interseting t allow the user to only pass the action key and we retrieve the rest behind the scenes )
  };
};

/**
 * configureUIActionsa setups up dactions for the UI object in the SDK
 * @method
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 * @returns object
 */
export const configureUIActions = function( data, methods ){
  const { ui = {}, flyoutContentRef } = data || {};
  const { openOverlay, closeOverlay, refetchRef, dispatchPortalUpdate, getFlyoutRef } = methods || {};
  const enrichedUI = {
    closeOverlay,
    openOverlay: utils.handleOpenOverlay( { ui, flyoutContentRef }, { openOverlay, closeOverlay } ),
    refresh_page: utils.refreshPage( { refreshPageAction: ui.refreshPageAction }, { refetchRef } ),
    render: utils.renderModule( {}, { dispatchPortalUpdate } )
  };
  return enrichedUI;
};

/** cofigureGetMethods maps over the content model and adds a get method for each entry
 * @method
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 * @returns object
 */
export const configureGetMethods = function( data, methods ){
  const { setContentModel } = methods || {};
  const { contentModel = {}, invoke, sdkAction, CURRENT_BREAKPOINT, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } = data || {};
  //
  // let enrichedContentModel = { ...contentModel };
  Object.keys( contentModel )?.map( ( key ) => {
    const targetModule = contentModel[key];
    targetModule._data = targetModule._data || {}; // if there is data in the mdule return that otherwise instantiate with an empty object

    targetModule.get = utils.getModuleData(
      {
        contentModel,
        action: targetModule.getAction,
        actionPath: 'getAction',
        domain: key,
        _data: targetModule._data,
        CURRENT_BREAKPOINT,
        user,
        isStaging,
        isNative,
        graphqlURI,
        graphqlDomain,
        graphqlServerUri
      },
      { setContentModel }
    );
  } );
  return { ...contentModel };
};

/**
 * UNRENDER_TYPES
 * @type {object}
 * @property {string} SINGLE='single' - The single render type
 * @property {string} ALL='all' - The all render type
 */

export const UNRENDER_TYPES = {
  SINGLE: 'single',
  ALL: 'all'
};

/**
 * distribueRenderMethods
 *
 * adds the render methods to the domains on the contentModel for eterna calls
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 *
 * @returns object
 */
export const distributeRenderMethods = function( data, methods ){
  const { contentModel = {} } = data || {};
  const { dispatchPortalUpdate } = methods || {};

  let enrichedContentModel = { ...contentModel };
  Object.keys( contentModel )?.map( ( key ) => {
    const targetModule = enrichedContentModel[key];
    targetModule.render = utils.renderModule(
      { moduleName: targetModule.moduleName, domain: key },
      { dispatchPortalUpdate }
    );
    targetModule.unrender = utils.unRenderModule(
      { moduleName: targetModule.moduleName, domain: key, unrenderType: UNRENDER_TYPES.SINGLE },
      { dispatchPortalUpdate }
    );
    targetModule.unrenderAll = utils.unRenderModule(
      { moduleName: targetModule.moduleName, domain: key, unrenderType: UNRENDER_TYPES.ALL },
      { dispatchPortalUpdate }
    );
  } );
  return enrichedContentModel;
};

/**
 * getModuleData
 *
 * fetches data for a given contentModelObject and defeers to cache responses unless otherwise instructed
 * @method
 *
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 *
 * @returns object
 */
export const getModuleData = function( data, methods ){
  const { contentModel, action, actionPath, domain, _data, CURRENT_BREAKPOINT, user, isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri } = data || {};
  const { setContentModel } = methods || {};

  return async( customModuleParams, force ) => {
    // check the store first...if it's there reutrn it otherwise go to the outside world
    // TODO need to see if this should be throttled to every few minutes, so we don't unecessarily overload the serices with rogue calls
    if( _data[actionPath] && !force ){
      console.warn(`content for ${actionPath} fetched from the ULTA_SDK cache`, _data[actionPath]); //eslint-disable-line
      return _data[actionPath];
    }

    let clientParamsResolvedAction = getRequiredClientParams( {
      user,
      props: {},
      action,
      breakpoint: CURRENT_BREAKPOINT
    } );

    const preppedAction = queryProcessor( { action: clientParamsResolvedAction } );

    devLogger( {
      title: `[SDK] Requested for ${actionPath}`,
      value: { user, preppedAction }
    } );
    const { graphql, config, context, variables, method } = preppedAction;
    const dxlClient = getClient( { isStaging, isNative, graphqlURI, graphqlDomain, graphqlServerUri, method } );
    // TODO result to be called using invoke
    const { data: dxlData } = await dxlClient.query( {
      query: gql`
        ${graphql}
      `,
      config: { ...config },
      context: { ...context, fetchOptions: { method } },
      variables: {
        ...variables,
        moduleParams: {
          ...variables.moduleParams,
          ...customModuleParams
        }
      }
    } );
    const res = dxlData?.Page?.content;
    if( res ){
      let updatedContentModel = {
        ...contentModel,
        [domain]: {
          ...contentModel[domain],
          _data: {
            [actionPath]: res
          }
        }
      };
      setContentModel( updatedContentModel );
    }
    return res;
  };
};

/**
 * getProtalID
 * returns the ID for a rendered portal
 * @method
 *
 * @param { object } data - data objects
 *
 * @returns string
 */
export const getPortalID = function( { selector, moduleName, timeStamp = new Date() } ){
  return `${selector}_${moduleName}_${timeStamp.getTime()}`;
};

/**
 * unRenderModule
 * removes modules from the portals which are used to display them in the DOM
 * @method
 *
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 *
 * @returns function
 */
export const unRenderModule = function( data, methods ){
  const { moduleName, domain, unrenderType } = data || {};
  const { dispatchPortalUpdate } = methods || {};
  return async( args ) => {
    const { id: portalID, moduleName: mName } = args || {};
    dispatchPortalUpdate( { type: utils.RENDER_TYPE.UNRENDER, unrenderType, portalID, moduleName: mName || moduleName } );
  };
};

/**
 * renderModule
 * adds modules to the portals variable for display in the browser
 * @method
 *
 * @param { object } data - data objects
 * @param { object } methods - injected methods
 *
 * @returns function
 */
export const renderModule = function( data, methods ){
  const { moduleName, domain } = data || {};
  const { dispatchPortalUpdate } = methods || {};
  return function( args ){
    const { selector, data = {}, componentName, timeStamp = new Date() } = args || {};

    if( !selector ){
      console.warn('A DOM selector must be passed in order to render a module'); //eslint-disable-line
      return;
    }
    const portalID = utils.getPortalID( { selector, moduleName, timeStamp } );

    dispatchPortalUpdate( {
      type: utils.RENDER_TYPE.RENDER,
      selector,
      moduleName: componentName || moduleName,
      data,
      portalID,
      domain
    } );

    return portalID;
  };
};

export default SDK;
