import './LoaderSkeleton.scss';

import React from 'react';

import PropTypes from 'prop-types';
/**
 * Skeleton Loader Component
 * @method
 * @param {object} props - React properties passed from composition
 * @returns Loading
 */
/**
 * Primary UI component for user interaction
 */
const LoaderSkeleton = props => {
  const { multipleLineLoader } = props;

  return (
    <div className={ 'LoaderSkeleton LoaderSkeleton--shimmer' } >
      <div className='LoaderSkeleton LoaderSkeleton--body' />
      {
        multipleLineLoader &&
        <div>
          <div className='LoaderSkeleton LoaderSkeleton--text' />
          <div className='LoaderSkeleton LoaderSkeleton--text' />
        </div>
      }
    </div>
  );
};

/**
 * Property type definitions
 * @type {object}
 * @property {number} height - height of the skeleton loader
 * @property {number} width - width of the skeleton loader
 * @property {boolean} multipleLineLoader - hide/show text loader under the Product Card
 */
LoaderSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  multipleLineLoader: PropTypes.bool
};

/**
 * Property type definitions
 * @type {object}
 * @property {number} height - set the height
 * @property {number} width - set the width
 * @property {boolean} multipleLineLoader - hide/show text loader under the Product Card
 */
LoaderSkeleton.default = {
  multipleLineLoader: false
};

export default LoaderSkeleton;
