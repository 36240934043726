/**
 * The Provider will update you local data context.
 *
 * @module views/__core/PageDataProvider/PageDataProvider
 */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import deepmerge from 'deepmerge';

import { APP_CONTAINER_CLASS } from '../../containers/AppContainer/AppContainer';
import { getApolloState } from '../../utils/apollo_client/apollo_client';
import constants from '../../utils/constants/constants';
import { devLogger, LOG_TOPIC } from '../../utils/devMode/devMode';
import { handleEmptyObjects } from '../../utils/handleEmptyObjects/handleEmptyObjects';
import { useDeviceInflection } from '../InflectionProvider/InflectionProvider';
import { isOverlay } from '../OverlayProvider/OverlayProvider';
import * as utils from './PageDataProvider';

/**
  * Represents a PageDataProvider component
  *
  * @method
  * @param { Object } props - React properties passed from composition
  * @returns PageDataContext
  */
export const PageDataProvider = React.forwardRef( ( { children }, _ )=>{
  const { breakpoint = {} } = useDeviceInflection();

  // Timestamp of when the page was last updated, used for comparing local updates to global updates from a parent
  // in children components for Layerhost
  const [pageLastUpdated, setLastUpdated] = useState( 0 );
  // This decorates modules from cached page response
  const initialData = useMemo( () => utils.decorateInitialData( { setLastUpdated, getApolloState } ), [] );

  // Manages general page data and loading/error state
  const [pageData, setLocalPageData] = useState( { loading: null, data: initialData, error: null } );

  // Maintains a stable reference to the Apollo SDK's refetch function
  const refetchRef = useRef();
  global.refetch = refetchRef;

  // Manages header offset for show/hide top bar
  const [headerOffset, setHeaderOffset] = useState( 0 );

  // Checks for ATG header and defines header offset
  useEffect( () => {
    utils.manageAtgHeader( { breakpoint }, { setHeaderOffset } );
  }, [setHeaderOffset, breakpoint.CURRENT_BREAKPOINT] );

  // Sets app container height on new data
  useEffect( () => {
    utils.setAppContainerHeight();
  }, [pageData] );

  // Accepts/merges initActions broadcast from protected pages during a session flow
  const protectedPageFlow = useRef( PROTECTED_PAGE_FLOW.Idle );

  // When we're in a protected page flow, we need to update the page data with the initAction
  // 1. DXL will send and initAction to load the protected page after a successful sessionAction response
  // 2. We need to broadcast that to the page level so that the initAction can be merged into the page data
  const processProtectedPageAction = useCallback(
    utils.composeProcessProtectedPageAction( { protectedPageFlow }, { setLocalPageData } ),
    [setLocalPageData]
  );
  const DONOTUSE_broadcastInitAction = useCallback(
    utils.composeBroadcastInitAction( { protectedPageFlow }, { setLocalPageData } ),
    [setLocalPageData]
  );

  // Sets page data and manages last updated timestamp
  const setPageData = useCallback(
    utils.composeUpdatePageData( { protectedPageFlow }, { setLastUpdated, setLocalPageData } ),
    [setLastUpdated, setLocalPageData]
  );

  const isRestrictedPage = !!pageData?.data?.Page?.meta?.isRestrictedPage;

  return (
    <PageDataContext.Provider
      value={ {
        data: pageData.data,
        DONOTUSE_broadcastInitAction,
        error: pageData.error,
        headerOffset,
        isRestrictedPage,
        loading: pageData.loading,
        pageLastUpdated,
        processProtectedPageAction,
        protectedPageFlow,
        refetchRef,
        setHeaderOffset,
        setPageData
      } }
    >
      { children }
    </PageDataContext.Provider>
  );
} );

/**
 * Manages setting up listener for ATG header
 * @param {object} data - Argumentds
 * @param {object} methods - Methods
 */
export const manageAtgHeader = ( data, methods ) => {
  const { breakpoint = {} } = data || {};
  const { setHeaderOffset } = methods || {};

  if( !setHeaderOffset ){
    return;
  }

  if( breakpoint.isSmallDevice?.() ){
    setHeaderOffset( 0 );
    return;
  }

  const height = document.querySelector( ATG_DESKTOP_HEADER_SELECTOR )?.offsetHeight;

  if( !height ){
    return;
  }

  setHeaderOffset( height );
};

/**
 * @constant {string} ATG_DESKTOP_HEADER_SELECTOR
 */
export const ATG_DESKTOP_HEADER_SELECTOR = '.DesktopHeader__StickyHeader';

/**
 * Sets AppContainer height on content change
 */
export const setAppContainerHeight = () => {
  const appContainer = document.querySelector( `.${APP_CONTAINER_CLASS}` );
  const height = appContainer?.clientHeight;

  if( height > 0 ){
    appContainer.style = `min-height: ${height}px`;
    setTimeout( () => {
      appContainer.style = '';
    }, 0 );
  }
};

export const PROTECTED_PAGE_FLOW = {
  // Idle = no protected page flow is in progress
  Idle: 'Idle',
  // Resolving = dispatching an initAction that will return either the overlay or the protected page
  Resolving: 'Resolving',
  // Overlay = the overlay is displayed
  SignInOverlay: 'Overlay',
  // Resolved = the protected page is displayed
  Resolved: 'Resolved',
  // ReAuthenticate = On re-querying the page we need to re-authenticate the user
  ReAuthenticate: 'ReAuthenticate'
};

/**
 * @method composeBroadcastInitAction
 * @param {object} data Arguments
 * @param {object} data.initAction - initAction
 */
export const composeBroadcastInitAction = ( data, methods ) => ( inputData ) => {
  const { protectedPageFlow = {} } = handleEmptyObjects( data );
  const { setLocalPageData } = methods || {};
  const { initAction, snackBar, protectedFlow } = handleEmptyObjects( inputData );

  const hasInitAction = initAction?.graphql || initAction?.content;

  if( !hasInitAction && !snackBar?.message ){
    devLogger( '[Page] No init action or snackbar to broadcast', 1, LOG_TOPIC.DXL );
    return;
  }

  devLogger( '[Page] Broadcasting init action', 1, LOG_TOPIC.DXL );

  const meta = {};

  if( hasInitAction ){
    meta.initAction = initAction;
  }

  if( snackBar ){
    meta.snackBar = snackBar;
  }

  if( protectedFlow ){
    protectedPageFlow.current = PROTECTED_PAGE_FLOW.Resolving;
  }

  setLocalPageData( ( pageData ) =>
    deepmerge( pageData, {
      data: {
        Page: {
          meta
        }
      }
    } )
  );
};

/**
 * @method composeProcessProtectedPageAction
 * @param {object} data Arguments
 * @param {object} data.initAction - initAction
 * @param {object} data.protectedPageFlow - protectedPageFlow
 */
export const composeProcessProtectedPageAction = ( data, methods ) => ( inputData ) => {
  const { protectedPageFlow = {} } = handleEmptyObjects( data );
  const { setLocalPageData } = methods || {};
  const { initAction = {} } = handleEmptyObjects( inputData );

  if( !initAction.graphql ){
    return;
  }

  devLogger( '[Page] Resolving protected page action', 1, LOG_TOPIC.Session );

  protectedPageFlow.current = isOverlay( initAction.navigationType ) ?
    PROTECTED_PAGE_FLOW.SignInOverlay :
    PROTECTED_PAGE_FLOW.Resolving;

  setLocalPageData( ( pageData ) =>
    deepmerge( pageData, {
      data: {
        Page: {
          meta: {
            initAction
          }
        }
      }
    } )
  );
};

/**
 * Handles decorating modules of a cached response and adds a timestamp to each Amplience module for comparison in Layerhost
 *
 * @param {object} methods Arguments
 * @param {function} data.setLastUpdated - a method to update page updated timestamp
 * @param {function} data.getApolloState - a method to get apollo state from Apollo library
 * @returns {array|null} Array of timestamp-decorated modules
 */
export const decorateInitialData = ( methods ) => {
  const { setLastUpdated, getApolloState } = methods || {};

  if( !setLastUpdated || !getApolloState ){
    return null;
  }

  const initialData = getApolloState();
  const lastUpdated = Date.now();

  setLastUpdated( lastUpdated );

  // Deep copy, needed to get around read only properties passed back from Apollo
  const pageData = JSON.parse( JSON.stringify( initialData ) );

  if( pageData?.Page?.content?.modules ){
    const newModules = pageData.Page.content.modules;

    const decoratedModules = utils.decorateModules( {
      modules: newModules,
      componentLastUpdated: lastUpdated
    } );

    pageData.Page.content.modules = decoratedModules;
  }

  return pageData;
};

/**
 * @method
 * @param {object} data Arguments
 * @param {function} data.setLocalPageData - a method to update local state object
 * @param {function} data.setLastUpdated - a method to update page updated timestamp
 * @returns {updatePageData}
 */
export const composeUpdatePageData = ( data, methods ) => ( payload ) => {
  const { protectedPageFlow = {} } = handleEmptyObjects( data );
  const { setLocalPageData, setLastUpdated } = methods || {};

  const { loading, data: pageData, error } = payload || {};

  const lastUpdated = Date.now();

  if( !setLastUpdated ){
    return;
  }

  setLastUpdated( lastUpdated );

  // TODO: Talk to DXL, content should be null as per the protected page flow design
  // When re-authenticating we only want to process the session action and ignore any content
  if( protectedPageFlow.current === PROTECTED_PAGE_FLOW.ReAuthenticate ){
    // Set the status to Resolving
    protectedPageFlow.current = PROTECTED_PAGE_FLOW.Resolving;

    // Don't touch the content because we want to process the meta.sessionAction in the background
    // as we re-authenticate the user
    setLocalPageData( ( { loading, data, error } ) => {
      return {
        data: {
          Page: {
            content: data?.Page?.content,
            meta: pageData?.Page?.meta
          }
        },
        loading,
        error
      };
    } );

    return;
  }

  if( protectedPageFlow.current === PROTECTED_PAGE_FLOW.Resolving ){
    protectedPageFlow.current = PROTECTED_PAGE_FLOW.Resolved;
  }

  devLogger( `[Page] setting page data | protected: ${protectedPageFlow.current}`, 1, LOG_TOPIC.Session );

  setLocalPageData( existing => {
    // Deep copy, needed to get around read only properties passed back from Apollo
    const decoratedData = pageData && JSON.parse( JSON.stringify( pageData ) );

    if( decoratedData?.Page?.content?.modules ){
      let newModules = decoratedData.Page.content.modules;
      let existingModules = existing?.data?.Page?.content?.modules;

      // Fallback to __APOLLO_STATE__
      if( !existingModules ){
        const apolloState = getApolloState();
        existingModules = apolloState?.Page?.content?.modules || [];
      }

      // Determine if we need to swap in the MainWrapper
      const existingMainIdx = existingModules.findIndex(
        ( module ) => module.moduleName === constants.HFN.MainWrapperModuleName
      );
      const needsMainWrapper = !newModules.some( ( module ) => module.moduleName === constants.HFN.MainWrapperModuleName );

      // Swap mainwrapper from new modules to existing modules when we have the
      // correct response model of [TopBar, MainWrapper, Footer]
      if( existingMainIdx > -1 && needsMainWrapper ){
        let newMainWrapper = { moduleName: constants.HFN.MainWrapperModuleName, modules: [...newModules] };

        const mainWrapperModules = existingModules[existingMainIdx]?.modules || [];
        const firstMainWrapperModule = mainWrapperModules[0];
        const lastMainWrapperModule = mainWrapperModules[mainWrapperModules.length - 1];
        const hasFirstIncremental = firstMainWrapperModule?.moduleName === constants.HFN.IncrementalModuleName;
        const hasLastIncremental = lastMainWrapperModule?.moduleName === constants.HFN.IncrementalModuleName;

        if( hasFirstIncremental ){
          newMainWrapper.modules.unshift( deepmerge( {}, firstMainWrapperModule ) );
        }

        if( hasLastIncremental ){
          newMainWrapper.modules.push( deepmerge( {}, lastMainWrapperModule ) );
        }

        newModules = JSON.parse( JSON.stringify( existingModules ) );
        newModules.splice( existingMainIdx, 1, newMainWrapper );
      }

      const decoratedModules = utils.decorateModules( {
        modules: newModules,
        componentLastUpdated: lastUpdated
      } );

      decoratedData.Page.content.modules = decoratedModules;
    }

    return { loading, data: decoratedData, error };
  } );
};

/**
 * Traverses the modules of a response and adds a timestamp to each Amplience module for comparison in Layerhost
 *
 * @param {object} data Arguments
 * @param {array} data.modules Array of modules for current node
 * @param {number} data.componentLastUpdated Page updated timestamp
 * @returns {array|null} Array of timestamp-decorated modules
 */
export const decorateModules = ( data ) => {
  const { moduleName, modules, componentLastUpdated, ignoreUpdates: _ignoreUpdates = false } = data || {};

  if( !Array.isArray( modules ) ){
    return modules;
  }

  const componentModules = [...modules];
  let ignoreUpdates = _ignoreUpdates;

  // TopBar and Footer manage their own updates
  if( !_ignoreUpdates && moduleName ){
    moduleName === constants.HFN.TopBarModuleName && ( ignoreUpdates = true );
    moduleName === constants.HFN.FooterModuleName && ( ignoreUpdates = true );
  }

  for ( let i = 0; i < componentModules.length; i++ ){
    componentModules[i].componentLastUpdated = componentLastUpdated;
    componentModules[i].ignoreUpdates = ignoreUpdates;
    if( Array.isArray( componentModules[i].modules ) ){
      componentModules[i].modules = utils.decorateModules( {
        modules: componentModules[i].modules,
        moduleName: componentModules[i].moduleName,
        componentLastUpdated,
        ignoreUpdates
      } );
    }
  }

  return componentModules;
};

/**
 * Context context object for react reuse
 * @type object
 */
export const PageDataContext = createContext( {
  headerOffset: 0,
  lastUpdated: 0,
  refetchRef: { current: undefined }
} );

/**
 * @callback setPageData
 * @param {{
 *    loading: boolean,
 *    error: boolean,
 *    data: object
 * }} data
 * @param {boolean} loading loading state
 * @param {boolean} error error state
 * @param {object} data page data
 * @returns {void}
 */

/**
 * @callback updatePageData is a function which calls to update the state of the page
 * @param {object} data Arguments
 * @param {boolean} data.loading Page loading status
 * @param {boolean} data.error Page error status
 * @param {object} data.data Page data
 */

/**
 * Page data context provider
 * @typedef {object} PageDataProvider
 * @property {number} pageLastUpdated last time the conent was updated timestamp
 * @property {setPageData} setPageData update page data
 */

/**
 * @methods
 * @returns {PageDataContext}
 */
export const usePageDataContext = () => useContext( PageDataContext );

PageDataProvider.displayName = 'PageDataProvider';

export default PageDataProvider;
