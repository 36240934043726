/**
 * Details containing app for AppDetails component
 *
 * @module views/components/AppDetails
 * @memberof -Common
 */
import './AppDetails.scss';

import React from 'react';

import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import { UltaQrCode } from '@ulta/components/QrCode/QrCode';

import NavigationLink from '../NavigationLink/NavigationLink';


export const AppDetails = React.forwardRef( ( props, _ ) =>{
  const { description, modules, qrCodeURL } = props;
  const { breakpoint } = useDeviceInflection();
  const isLargeDevice = breakpoint.isLargeDevice();
  return (
    <div className='AppDetails'>
      { isLargeDevice && description &&
      <div className='AppDetails__subTitle'>
        <Text
          textStyle='body-3'
        >
          { description }
        </Text>
      </div> }

      <div className='AppDetails__download'>
        { isLargeDevice && qrCodeURL?.url &&
        <div className='AppDetails__qr'>
          <UltaQrCode
            url={ qrCodeURL.url }
            size={ breakpoint.CURRENT_BREAKPOINT === 'XL' ? 105 : 85 }
          />
        </div>
        }
        {
          Array.isArray( modules ) &&
          <div className='AppDetails__downloadApps'>
            {
              modules.map( ( { icon, description, action }, index )=>(

                <NavigationLink
                  action={ { ...action, label: '' } }
                  color='black'
                  icon={ icon }
                  iconSize={ 'custom' }
                  // used css to handle this since palette only has custom icon size
                  // iconSize={ isLargeDevice ? 'customMedium' : 'customLarge' }
                  description={ description }
                  key={ index }
                />

              ) ) }
          </div>
        }

      </div>
    </div>
  );
} );

/**
 * Property type definitions
 * @typedef AppDetailsProps
 * @type {object}
 * @property {string} description - sets the Description
 * @property {string} qrCodeURL - sets the QR Code
 * @property {array} modules - sets the App Details
 */
export const propTypes = {
  description: PropTypes.string.isRequired,
  modules: PropTypes.array,
  qrCodeURL: PropTypes.object.isRequired
};

AppDetails.propTypes = propTypes;
AppDetails.displayName = 'AppDetails';

export default AppDetails;