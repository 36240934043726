/**
* SelectField provides the facility to choose option among various offered options which combines a label, select and validation message in one field control. Used for traditional forms.
*
* @module views/Atoms/SelectField
* @memberof -Common
*/
import './SelectField_New.scss';

import React, { useCallback } from 'react';

import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';

import { triggerDatacaptureEvent } from '@ulta/core/components/DropDown/DropDown';
import Icon from '@ulta/core/components/Icon/Icon';
import InlineMessage from '@ulta/core/components/InlineMessage/InlineMessage';
import { handleEmptyObjects } from '@ulta/core/utils/handleEmptyObjects/handleEmptyObjects';

import * as utils from './SelectField_New';

/**
  * Represents a SelectField component
  *
  * @method
  * @param {SelectFieldProps} props - React properties passed from composition
  * @returns SelectField
  */
const SelectField = ( props ) => {

  const {
    options,
    placeholderLabel,
    error,
    label,
    enabled,
    onChange,
    hideLabel,
    variant,
    required,
    id
  } = props;

  const [field, meta, helper] = useField( props );
  const handleCallback = useCallback( utils.composeHandleCallback( { field, options }, { triggerDatacaptureEvent, onChange } ), [field, options, triggerDatacaptureEvent, onChange] );
  const uniqueId = id || `select-${field.name}`;

  const hasInlineError = error && meta.touched && enabled;
  const hasErrorMessage = meta.error && meta.touched && enabled;
  const hasError = hasInlineError || hasErrorMessage;

  return (
    <div
      className={
        classNames(
          'SelectFieldNew', {
            'SelectFieldNew--disabled':!enabled,
            'SelectFieldNew--error': hasError,
            'SelectFieldNew--underlined': variant === 'underline',
            'SelectFieldNew--horizontalUnderlined': variant === 'horizontalUnderline',
            'SelectFieldNew--defaultCompact': variant === 'defaultCompact'
          }
        )
      }
    >
      { label &&
        <label
          className={
            classNames( 'SelectFieldNew__label', {
              'sr-only': hideLabel
            } )
          }
          htmlFor={ uniqueId }
        >
          { label }
        </label>
      }
      <select
        { ...field }
        className={
          classNames(
            'SelectFieldNew__select'
          )
        }
        name={ field.name }
        id={ uniqueId }
        value={ field.value }
        onChange={ ( e ) => handleCallback( e ) }
        { ...( required && { 'required': required } ) }
        { ...( !enabled && { 'disabled': !enabled } ) }
      >
        { placeholderLabel && <option value=''>{ placeholderLabel }</option> }

        {
          options?.length && options.map( ( option, index ) => {
            return (
              <option
                key={ index }
                value={ option.value }
                disabled={ option.enabled ? !option.enabled : false }
              >
                { option.label }
              </option>
            );
          } )
        }
      </select>
      <div className={
        classNames(
          'SelectFieldNew__chevron'
        )
      }
      >
        <Icon name='CaretDown'
          aria-hidden={ true }
          size='s'
          disabled={ !enabled }
        />
      </div>

      {
        hasInlineError &&
        <InlineMessage
          message={ error }
          messageType={ 'error' }
        />
      }
      {
        hasErrorMessage &&
          <ErrorMessage
            name={ field.name }
            render={ msg => {
              return (
                <InlineMessage messageType='error'
                  message={ msg }
                />
              );
            } }
          />
      }

    </div>
  );
};

/**
 * Handles onChange for update quantity
 * @param {object} data arguments
 * @param {object} data.field Formik field helper
 * @param {object} data.options DXL options
 * @param {object} methods arguments
 * @param {function} methods.onChange Caller's onChange callback
 * @param {function} methods.triggerDatacaptureEvent Caller's datacapture when quantity got changes
 */
export const composeHandleCallback = ( data, methods ) => ( e ) => {
  const { field, options } = handleEmptyObjects( data );
  const { onChange, triggerDatacaptureEvent } = handleEmptyObjects( methods );

  field.onChange( e );
  onChange && onChange( e.target.value );

  if( !e.target.value ){
    return;
  }

  const selectedOption = options?.find( item => e.target.value === item.id );
  const selectedOptionData = selectedOption?.dataCaptureData || selectedOption?.action?.dataCaptureData;
  if( selectedOptionData ){
    triggerDatacaptureEvent( selectedOptionData );
  }

};

/**
 * Property type definitions
 * @typedef SelectFieldProps
 * @type {object}
 * @property {array} options - Sets the properties in an array which  is required field
 * @property {string} placeholderLabel - Sets the default option
 * @property {string} label - Sets the label for an element
 * @property {boolean} enabled - Sets the disabled variation for an element
 * @property {string} name - Sets the name for an element
 * @property {string} value - Sets the value for an element
 * @property {array} params - Sets the params for an element
 * @property {string} variant=default - sets the style of default or underlined
 */
export const propTypes = {
  /** Sets the properties in an array which  is required field */
  options: PropTypes.array.isRequired,
  /** Sets the default option */
  placeholderLabel: PropTypes.string,
  /** Sets the label for an element */
  label: PropTypes.string,
  /** Sets hidden for the label */
  hideLabel: PropTypes.bool,
  /** Sets the disabled variation for an element */
  enabled: PropTypes.bool,
  /** Sets the name for an element */
  name: PropTypes.string,
  /** Sets the value for an element */
  value: PropTypes.string,
  /** Sets the params for an element */
  params: PropTypes.array,
  /** Sets the variant classname for the select field */
  variant: PropTypes.string
};

SelectField.propTypes = propTypes;

export const defaultProps = {
  hideLabel: false,
  variant: 'default'
};

export default SelectField;