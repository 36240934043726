/**
 * It wraps main tag around all page modules. This was done so that the <main> landmark would only wrap main content and not header and footer landmark regions as it was previously.
 *
 * @module views/components/MainWrapper
 * @memberof -Common
 */
import './MainWrapper.scss';

import React from 'react';

import PropTypes from 'prop-types';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import ComponentIndexProvider from '@ulta/core/providers/ComponentIndexProvider/ComponentIndexProvider';
import { usePageDataContext } from '@ulta/core/providers/PageDataProvider/PageDataProvider';

import { useAppConfigContext } from '../../providers/AppConfigProvider/AppConfigProvider';
import { useStyleContext } from '../../providers/StyleContextProvider/StyleContextProvider';
import { handleEmptyObjects } from '../../utils/handleEmptyObjects/handleEmptyObjects';
/**
 * Represents a MainWrapper component
 *
 * @method
 * @param { MainWrapperProps} props - React properties passed from composition
 * @returns MainWrapper
 */
export const MainWrapper = React.forwardRef( ( props, _ ) => {
  const { modules } = props;
  const { data } = usePageDataContext();
  const { seoHiddenTitle } = data?.Page?.content?.web || {};
  const { isMobile } = useAppConfigContext();
  const { mainWrapperStyle } = useStyleContext();

  if( !modules ){
    return null;
  }

  return (
    <main id='MainWrapper'
      style={ mainWrapperStyle }
    >
      { seoHiddenTitle && <h1 className='MainWrapper__webAccessibleTitle'>{ seoHiddenTitle }</h1> }
      { modules.map( ( module, i ) => {
        return (
          <ComponentIndexProvider key={ `component-index-provider-${i}` }
            componentIndex={ i }
          >
            <AsyncComponent
              { ...module }
              allowDefer={ allowDefer( { index: i, isMobile, module } ) }
              key={ `main-wrapper-module-${i}` }
            />
          </ComponentIndexProvider>
        );
      } ) }
    </main>
  );
} );

/**
 * Method to determine if a module tree contains a module that should not be deferred
 *
 * @method
 * @param { object } module - object that contains a tree of modules to be added to the DOM
 * @returns boolean
 */
export const containsNonDeferrableModule = ( data ) => {
  const { module = {} } = handleEmptyObjects( data );
  let ret = false;

  // GamBanner needs to fire events on page load so it should be in the DOM
  if( module?.moduleName === 'GamBanner' ){
    ret = true;
  }

  if( !ret && module?.items?.length > 0 ){
    for ( let i = 0; i < module.items.length; i++ ){
      ret = ret || containsNonDeferrableModule( { module: module.items[i] } );
      if( ret ){
        break;
      }
    }
  }

  return ret;

};

/**
 * Method to determine if a component should be deferred or not based on position inside MainWrapper
 *
 * @method
 * @param { number, boolean, object } index, isMobile and module object to process
 * @returns boolean
 */
export const allowDefer = ( data ) => {
  const { index, isMobile, module } = handleEmptyObjects( data );

  // these values can come from Amplience in a header script
  if( global.allowComponentDeferral ){
    if( containsNonDeferrableModule( { module } ) ){
      return false;
    }
    if( isMobile ){
      return index > global.mobileComponentDeferralIndex;
    }
    else {
      return index > global.desktopComponentDeferralIndex;
    }
  }
  else {
    return false;
  }
};

/**
 * Property type definitions
 * @typedef MainWrapperProps
 * @type {object}
 * @property {string} example - refactor or delete
 */
export const propTypes =  {
  modules: PropTypes.array
};

MainWrapper.propTypes = propTypes;
MainWrapper.displayName = 'MainWrapper';

export default MainWrapper;
