/**
 * This is the AutoSuggestDesktop component, which will display the results of the suggested search terms and products.
 *
 * @module views/Organisms/AutoSuggestDesktop
 * @memberof -Common
 */
import './AutoSuggestDesktop.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import AsyncComponent from '@ulta/core/components/AsyncComponent/AsyncComponent';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { hasItems } from '@ulta/core/utils/array/array';

import { useSearchHeaderContext } from '@ulta/providers/SearchHeaderProvider/SearchHeaderProvider';

import RecentSearchList from '../RecentSearchList/RecentSearchList';
import SearchProductList from '../SearchProductList/SearchProductList';
import SuggestionList from '../SuggestionList/SuggestionList';

/**
 * Represents a AutoSuggestDesktop component
 *
 * @method
 * @param {AutoSuggestDesktopProps} props - React properties passed from composition
 * @returns AutoSuggestDesktop
 */
export const AutoSuggestDesktop = function(){
  const {
    suggestions,
    clearRecentLabel,
    recentLabel,
    recentSearches,
    maxTrendingToDisplay,
    handleAddRecentSearch,
    handleRemoveRecentSearch,
    handleClearAllRecentSearches,
    handleSaveSearchTerm,
    products,
    suggestionsLabel,
    trendingLabel,
    searchString,
    isKeyBoardUser,
    resultsCountText,
    topResultsLabel,
    popularProductsLabel,
    viewAllAction,
    dataCaptureHandler,
    componentKey,
    handleClearAllRecentlyViewed,
    recentlyViewedAction,
    recentlyViewedItems
  } = useSearchHeaderContext();
  const { typeaheadMinInputLength } = useAppConfigContext();
  const [productList, setProductList] = useState( products );
  const [viewAllResult, setViewAllResult] = useState( viewAllAction );
  const [topProductLabel, setTopProductLabel] = useState( `${topResultsLabel} "${searchString}"` );
  const [trendingProductLabel, setTrendingProductLabel] = useState( popularProductsLabel );
  const [style, setStyle] = useState( {} );
  const hasScroll = style !== null;
  const timer = useRef();
  const ref = useRef();
  const focusableTitleRef = useRef();
  /**
   * Keeps prouct list updated when new results come down from SearchHeader
   */
  useEffect( () => {
    setProductList( products );
    setViewAllResult( viewAllAction );
    setTopProductLabel( `${topResultsLabel} "${searchString}"` );
    setTrendingProductLabel( popularProductsLabel );
  }, [products] );

  /**
   * Handles resizing vertical height automatically and adding a scroll bar
   */
  const handleSetHeight = useCallback(
    composeHandleSetHeight( { timer }, { setStyle } ),
    []
  );

  useEffect( () => {
    const setHeight = () => handleSetHeight( ref.current );
    // We need on scroll and resize because scrolling triggers TopBar to go away
    global.addEventListener( 'scroll', setHeight );
    global.addEventListener( 'resize', setHeight );

    return () => {
      global.removeEventListener( 'scroll', setHeight );
      global.removeEventListener( 'resize', setHeight );
    };
  }, [ref.current] );

  useEffect( () => {
    handleSetHeight( ref.current );
  }, [suggestions, recentSearches, products] );

  /**
   * Handles the hover events for suggestion items
   */
  const mouseEnterHandler = useCallback(
    ( index ) => {
      setProductList( suggestions[index]?.topResults?.products );
      setViewAllResult( suggestions[index]?.topResults?.viewAllAction );
      setTopProductLabel( `${topResultsLabel} "${ suggestions[index]?.label }"` );
      setTrendingProductLabel( `${topResultsLabel} "${ suggestions[index]?.label }"` );
    },
    [suggestions]
  );

  /*
   * ADA - To set the Focus to topResultslabel
   */
  const onFocusTitle = useCallback( () => focusableTitleRef?.current?.focus() );

  const isRecentlyViewedVisible = recentlyViewedItems?.id && ( searchString?.length ?? 0 ) <= typeaheadMinInputLength;

  if( !hasItems( products ) || !hasItems( suggestions ) ){
    return null;
  }

  return (
    <div
      className={ classNames( 'AutoSuggestDesktop', { 'AutoSuggestDesktop--hasScroll': hasScroll } ) }
      style={ style }
      ref={ ref }
    >
      <div className='AutoSuggestDesktop__leftColumn'>
        { isRecentlyViewedVisible && (
          <AsyncComponent
            moduleName='RecentlyViewed'
            dataCaptureHandler={ dataCaptureHandler }
            searchString={ searchString }
            handleClearAllRecentlyViewed={ handleClearAllRecentlyViewed }
            recentlyViewedAction={ recentlyViewedAction }
          />
        ) }
        <RecentSearchList
          clearRecentLabel={ clearRecentLabel }
          recentLabel={ recentLabel }
          recentSearches={ recentSearches }
          handleAddRecentSearch={ handleAddRecentSearch }
          handleRemoveRecentSearch={ handleRemoveRecentSearch }
          handleClearAllRecentSearches={ handleClearAllRecentSearches }
          handleSaveSearchTerm={ handleSaveSearchTerm }
          searchString={ searchString }
          dataCaptureHandler={ dataCaptureHandler }
          componentKey={ componentKey }
        />
        <SuggestionList
          onMouseEnter={ mouseEnterHandler }
          suggestions={ suggestions }
          trendingLabel={ trendingLabel }
          suggestionsLabel={ suggestionsLabel }
          searchString={ searchString }
          isKeyBoardUser={ isKeyBoardUser }
          dataCaptureHandler={ dataCaptureHandler }
          onFocusTitle={ onFocusTitle }
          maxTrendingToDisplay={ maxTrendingToDisplay }
          handleAddRecentSearch={ handleAddRecentSearch }
          componentKey={ componentKey }
        />
      </div>
      <div className='AutoSuggestDesktop__rightColumn'>
        <SearchProductList
          topResultsLabel={ topProductLabel }
          resultsCountText={ resultsCountText }
          popularProductsLabel={ trendingProductLabel }
          products={ productList }
          searchString={ searchString }
          viewAllAction={ viewAllResult }
          dataCaptureHandler={ dataCaptureHandler }
          focusableTitleRef={ focusableTitleRef }
          handleAddRecentSearch={ handleAddRecentSearch }
        />
      </div>
    </div>
  );
};

/**
 *
 * @param {object} data args
 * @param {object} data.el AutoSuggest div
 * @param {object} timer timer ref
 * @param {object} methods methods
 * @param {function} methods.setStyle React state setter for style object
 */
export const composeHandleSetHeight = ( data, methods ) => ( el ) => {
  const { timer } = data || {};
  const { setStyle } = methods || {};

  if( !el || !setStyle ){
    return;
  }

  clearTimeout( timer.current );
  timer.current = setTimeout( () => {
    const { height, top } = el.getBoundingClientRect();
    const viewportHeight = global.innerHeight;

    if( top + height > viewportHeight ){
      setStyle( {
        maxHeight: `${viewportHeight - top}px`
      } );
      return;
    }

    setStyle( null );
  }, AUTOSUGGEST_DEBOUNCE_INTERVAL );
};

/**
 * @const AUTOSUGGEST_DEBOUNCE_INTERVAL - debounce interval for setting height
 */
export const AUTOSUGGEST_DEBOUNCE_INTERVAL = 100;

export default AutoSuggestDesktop;
